import { yupResolver } from '@hookform/resolvers/yup'
import { Laptop } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import * as yup from 'yup'

import { ActionsDropdown } from 'ui'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Steps from '../../../components/Steps'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import TabContent from '../../../components/ui/tabs'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  addEquipment,
  deleteEquipment,
  downloadAgreement,
  editAgreement,
  equipmentList,
  getAgreement,
  getContractorAgreement,
  returnEquipment,
  signEquipment,
  updateEquipment,
} from '../../../services/api'
import { mapCurrencyToOption } from '../../../utils/map-to-option'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import { JobTitleModal } from '../ContractDetail/SelectSignatoryDropdown'
import {
  customOption,
  customSingleValue,
} from '../components/Forms/select-components'
import TabCardHeader from '../components/tab/tab-card-header'
import TabEmpty from '../components/tab/tab-empty'
import { getStatusColor } from '../utils/helpers'
import './../ContractEditor/style.css'
import EquipmentEditor from './EquipmentEditor'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'

const Equipments = ({ loading, contractId, currency }) => {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    contract_id: contractId,
  })

  const autoFetchEquipmentList = !!contractId

  const {
    data,
    isLoading: loadingEquipmentList,
    startFetch: fetchEquipmentList,
    completed: doneFetchingEquipments,
    paginator,
  } = useFetch(
    {
      action: equipmentList,
      autoFetch: autoFetchEquipmentList,
      body: filters,
      onError: (err) => {
        toastr.error(err)
      },
    },
    [contractId, filters],
  )

  const loadingData =
    loading ||
    (autoFetchEquipmentList ? !doneFetchingEquipments : loadingEquipmentList)

  const [open, setOpen] = useState(false)
  const [showReturnModal, setShowReturnModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [equipmentToReturn, setEquipmentToReturn] = useState(null)
  const [itemToSign, setItemToSign] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [toRemove, setToRemove] = useState(null)
  const user = useSelector((state) => state.Account.user)
  const contract = useSelector((state) => state.Contract.details)

  const { hasAccess } = usePermissions()

  const returnEquipmentFunc = useFetch({
    action: returnEquipment,
    onComplete: (res) => {
      fetchEquipmentList()
      setShowReturnModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const deleteEquipmentFunc = useFetch({
    action: deleteEquipment,
    onComplete: (res) => {
      fetchEquipmentList()
      setShowConfirmationModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleReturn = () => {
    returnEquipmentFunc.startFetch({ equipment_id: equipmentToReturn?.id })
  }

  const downloadAgreementFile = (e) => {
    downloadAgreement(user?.token, e)
      .then((r) => {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${contract?.ref}-${e?.type}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        toastr.error(e)
      })
  }

  const isMobile = useResize()
  const isEmpty = data?.length === 0
  const showPagination = paginator?.total > paginator?.per_page

  const [openJobTitleModal, setOpenJobTitleModal] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const clientHasJobTitle = !!userProfile?.job_title
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const jobTitleRequired = isDeContract

  function toggle() {
    if (jobTitleRequired && !clientHasJobTitle) {
      setOpenJobTitleModal(true)
      return null
    }

    setOpen((open) => !open)
  }

  return (
    <Card>
      <TabCardHeader
        title='Equipments'
        extra={
          user?.type === userTypes.COMPANY && !isEmpty && !loadingData ? (
            <PermissionTooltip
              showing={!hasAccess(permissions.addEquipment)}
              area={PERMISSION_GROUP.EQUIPMENT.name}
              id='add-equipment-btn-tooltip'
            >
              <Button
                disabled={!hasAccess(permissions.addEquipment)}
                onClick={toggle}
              >
                Add New Equipment
              </Button>
            </PermissionTooltip>
          ) : null
        }
      />

      {loadingData ? (
        <Loader minHeight='30rem' />
      ) : isEmpty ? (
        <TabEmpty
          title='No equipment'
          subtitle='Equipments will be shown here'
          icon={<Laptop size={250} color='var(--primary)' weight='duotone' />}
        >
          {user?.type === userTypes.COMPANY ? (
            <PermissionTooltip
              showing={!hasAccess(permissions.addEquipment)}
              area={PERMISSION_GROUP.EXPENSES.name}
              id='add-expense-btn-tooltip'
            >
              <Button
                disabled={!hasAccess(permissions.addEquipment)}
                onClick={toggle}
              >
                Add New Equipment
              </Button>
            </PermissionTooltip>
          ) : null}
        </TabEmpty>
      ) : isMobile ? (
        data?.map((e, i) => {
          return (
            <EquipmentMobileCard
              e={e}
              key={`eq-${e?.id}`}
              handleSetItemToSign={(e) => setItemToSign(e)}
              handleOpen={() => setOpen(!open)}
              handleDownload={() => downloadAgreementFile(e)}
              handleReturn={() => {
                setEquipmentToReturn(e)
                setShowReturnModal(true)
              }}
              handleDelete={() => {
                setToRemove(e)
                setShowConfirmationModal(true)
              }}
              currency={currency}
            />
          )
        })
      ) : (
        <>
          <div className='table-with-dropdown'>
            <TableComp>
              <thead className='thead-light'>
                <tr>
                  <StyledTh>Type</StyledTh>
                  <StyledTh>Value</StyledTh>
                  <StyledTh>Serial number</StyledTh>
                  <StyledTh>Description</StyledTh>
                  <StyledTh>Status</StyledTh>
                  <StyledTh></StyledTh>
                </tr>
              </thead>
              <tbody>
                {data?.map((e, i) => {
                  let desc = e?.description.replace('<p>', '')
                  desc = desc.replace('</p>', '')
                  const formatter1 = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: e?.currency?.code || 'USD',
                  })

                  return (
                    <>
                      <tr key={`eq-${e?.id}`}>
                        <StyledTd>{e?.type}</StyledTd>
                        <StyledTd>
                          {e?.price ? formatter1.format(e?.price) : 'N/A'}
                        </StyledTd>
                        <StyledTd>{e?.serial_number || 'N/A'}</StyledTd>
                        <StyledTd>
                          <div
                            className='requirement-description'
                            dangerouslySetInnerHTML={{
                              __html: `${
                                desc?.length > 25
                                  ? desc?.substring(0, 25) + '...'
                                  : desc
                              }`,
                            }}
                          />
                        </StyledTd>
                        <td className='py-3 px-4'>
                          {user?.type === userTypes.CONTRACTOR &&
                          e?.contractor_signed === 0 ? (
                            <a
                              className='text-primary'
                              onClick={() => {
                                setItemToSign(e)
                                setOpen(true)
                              }}
                            >
                              Review & Sign
                            </a>
                          ) : (
                            <BadgeX
                              name={e.status?.name}
                              status={getStatusColor(
                                e?.status?.name?.includes('Pending')
                                  ? 'Pending'
                                  : e?.status?.name,
                              )}
                              textStatus={getStatusColor(
                                e?.status?.name?.includes('Pending')
                                  ? 'Pending'
                                  : e?.status?.name,
                              )}
                            />
                          )}
                        </td>
                        <td className='py-3 px-4 position-relative'>
                          <ActionDropDown
                            equipment={e}
                            handleDownload={() => downloadAgreementFile(e)}
                            handleReturn={() => {
                              setEquipmentToReturn(e)
                              setShowReturnModal(true)
                            }}
                            handleDelete={() => {
                              setToRemove(e)
                              setShowConfirmationModal(true)
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </TableComp>
          </div>

          {!showPagination ? null : (
            <div className='d-flex justify-content-end mt-3 px-4'>
              <Pagination
                activePage={filters.page}
                onChange={(page) => handleFiltersChange('page', page)}
                itemsCountPerPage={paginator?.per_page ?? 50}
                totalItemsCount={paginator?.total ?? 0}
              />
            </div>
          )}
        </>
      )}

      {!!open && (
        <AddEquipmentModal
          selectedItem={selectedItem}
          itemToSign={itemToSign}
          contractId={contractId}
          isOpen={open}
          toggle={() => {
            setItemToSign(null)
            setSelectedItem(null)
            setOpen(!open)
          }}
          onEquipmentAdded={fetchEquipmentList}
        />
      )}

      <ConfirmationModal
        onConfirm={() =>
          deleteEquipmentFunc.startFetch({ equipment_id: toRemove?.id })
        }
        toggle={() => setShowConfirmationModal(false)}
        isOpen={showConfirmationModal}
        title='Delete equipment'
        buttonColor='danger'
        caption='Delete'
        message='Are you sure you want to delete this equipment'
      />
      <ReturnEquipmentModal
        onConfirm={handleReturn}
        isOpen={showReturnModal}
        toggle={() => {
          setShowReturnModal(!showReturnModal)
        }}
        onEquipmentAdded={fetchEquipmentList}
      />

      <JobTitleModal
        open={openJobTitleModal}
        toggle={() => setOpenJobTitleModal((open) => !open)}
      />
    </Card>
  )
}

const ActionDropDown = ({
  handleReturn,
  handleDelete,
  handleDownload,
  equipment,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()

  const actionsOptions = []

  if (
    equipment?.status?.name.includes('Pending') &&
    user?.type === userTypes.COMPANY &&
    hasAccess(permissions.deleteEquipment)
  ) {
    actionsOptions[0] = {
      onClick: handleDelete,
      label: 'Delete',
    }
  }
  if (
    equipment?.status?.name === 'Signed' &&
    user?.type === userTypes.COMPANY
  ) {
    actionsOptions[1] = {
      onClick: handleReturn,
      label: 'Mark as returned',
    }
  }
  if (hasAccess(permissions.downloadEquipment)) {
    actionsOptions[2] = {
      onClick: handleDownload,
      label: 'Download',
    }
  }

  return <ActionsDropdown data={actionsOptions} />
}

const ReturnEquipmentModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className='modal-content'>
        <>
          <div className='modal-header p-3 p-md-4'>
            <h5 className='modal-title mt-0'></h5>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={toggle}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <div className='modal-body p-4'>
            <Col className='p-0 m-0'>
              <Row className='bg-soft-primary rounded text-primary border border-primary m-0 mb-3 p-3'>
                <p className='font-size-16 rp-font-bold'>Attention!</p>
                <p>
                  Please note that this action is irreversible. When you mark
                  equipment as returned, you claim to have received the
                  equipment back or allowed the user to keep it. This agreement
                  will be cancelled and no longer be valid
                </p>
              </Row>
              <p className='font-size-16 rp-font-bold text-dark'>
                Do you wish to continue?
              </p>
            </Col>
          </div>
          <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
            <Button
              text=' Cancel'
              style={{
                height: 40,
                width: window.innerWidth <= 754 ? '40%' : undefined,
              }}
              className='font-size-14 rp-border'
              outline
              color='white'
              onClick={toggle}
              type='button'
            />

            <Button
              text='Proceed'
              loading={false}
              disabled={false}
              style={{ width: window.innerWidth <= 754 ? '40%' : undefined }}
              onClick={onConfirm}
              type='button'
            />
          </StyledFooter>
        </>
      </div>
    </Modal>
  )
}

function getAddEquipmentFormId(step) {
  return `add-equipment-form-${step}`
}

export const AddEquipmentModal = ({
  contractId,
  isOpen,
  toggle,
  onEquipmentAdded,
  selectedItem,
  itemToSign,
}) => {
  const [phase, setPhase] = useState(itemToSign ? 2 : 0)
  const [formData, setFormData] = useState(null)
  const [editable, setEditable] = useState(null)
  const [textAgreement, setTextAgreement] = useState(null)
  const [fullName, setFullName] = useState(null)

  const { hasAccess } = usePermissions()
  const user = useSelector((state) => state?.Account?.user)
  const { equipment_types: equipmentTypes, currencies } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )

  const contract = useSelector((state) => state.Contract.details)
  const [currency, setCurrency] = useState(contract?.currency)

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))

  useEffect(() => {
    if (itemToSign) {
      setFormData({
        ...itemToSign,
        contract_id: contractId,
        currency_id: currency?.id,
      })
      getHtmlAgreement.startFetch({
        ...itemToSign,
        contract_id: contractId,
        currency_id: currency?.id,
      })
    }
  }, [itemToSign])

  const createEquipment = useFetch({
    action: user?.type === userTypes.COMPANY ? addEquipment : signEquipment,
    onComplete: () => {
      onEquipmentAdded()
      toggle()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getHtmlAgreement = useFetch({
    action:
      user?.type === userTypes.COMPANY ? getAgreement : getContractorAgreement,

    onComplete: () => {
      if (!itemToSign) {
        setPhase(phase + 1)
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getEditableAgreement = useFetch({
    action: editAgreement,
    onComplete: (res) => {
      setEditable(res?.part2)
      setPhase(phase + 1)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const updateEq = useFetch({
    action: updateEquipment,
    onComplete: () => {
      onEquipmentAdded()
      toggle()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const schema = yup.object().shape({
    contract_id: yup.number(),
    type: yup.string().required('Equipment type is a required field'),
    description: yup.string().required('Model is a required field'),
    price: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError('Invalid value'),
    currency_id: yup.number(),
    serial_number: yup.string(),
    file: yup.string(),
  })

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: selectedItem || {},
  })

  const onSubmit = (body) => {
    if (selectedItem?.id) {
      updateEq.startFetch({
        ...body,
        equipment_id: selectedItem?.id,
        currency_id: currency?.id,
      })
    } else {
      setFormData({
        ...body,
        contract_id: contractId,
        currency_id: currency?.id,
      })
      getEditableAgreement.startFetch({
        ...body,
        contract_id: contractId,
        currency_id: currency?.id,
      })
    }
  }

  const handleSignClick = (name) => {
    if (!name) {
      toastr.error('Please Enter your full name')
      return
    }
    const body =
      user?.type === userTypes.COMPANY
        ? { ...formData, signature_name: name }
        : { equipment_id: formData?.id, name }

    if (textAgreement) body.custom = textAgreement

    createEquipment.startFetch(body)
  }

  function handleCancelBack() {
    if (phase === 0) {
      toggle()
    } else {
      setPhase(phase - 1)
    }
  }

  const continueBtnProps =
    phase === 2
      ? {
          onClick: () => {
            handleSignClick(fullName)
          },
        }
      : phase === 1
        ? {
            onClick: () => {
              const body = { ...formData }
              if (textAgreement) body.custom = textAgreement

              getHtmlAgreement.startFetch(body)
            },
          }
        : phase === 0
          ? {
              type: 'submit',
              formId: getAddEquipmentFormId(phase),
            }
          : {}

  const btnLoading =
    getEditableAgreement.isLoading ||
    getHtmlAgreement.isLoading ||
    createEquipment.isLoading

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader
        close={<ModalCloseButton toggle={toggle} />}
        tag='div'
        cssModule={{ 'modal-title': 'flex-grow-1' }}
      >
        {!itemToSign && (
          <Steps activeTab={phase} data={['Add Equipment', 'Review', 'Sign']} />
        )}
      </ModalHeader>

      <ModalBody>
        <TabContent activeTab={phase} className='p-0'>
          <TabPane tabId={0}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              id={getAddEquipmentFormId(0)}
              className='container-fluid'
            >
              <Row>
                <Col md={5} sm={12}>
                  <FormGroup>
                    <ControlledSelect
                      label={
                        <>
                          Equipment type
                          <span
                            className='text-danger font-size-16 mx-1'
                            style={{ lineHeight: '14px' }}
                          >
                            *
                          </span>
                        </>
                      }
                      error={errors.type}
                      control={control}
                      name='type'
                      options={[
                        ...(equipmentTypes?.map((e) => ({
                          label: e?.name,
                          value: e?.name,
                        })) ?? []),
                        { label: 'Other', value: 'Other' },
                      ]}
                    />
                  </FormGroup>
                </Col>

                <Col md={7} sm={12}>
                  <FormGroup>
                    <Label>Value</Label>

                    <InputGroup>
                      <ControlledCurrencyInput
                        control={control}
                        name='price'
                        error={errors.price}
                        placeholder='Enter Value (Optional)'
                        autocomplete='off'
                        allowDecimals={true}
                        decimalsLimit={2}
                        decimalSeparator='.'
                        groupSeparator=','
                      />

                      <ControlledSelect
                        control={control}
                        name='currency_id'
                        error={errors.currency_id}
                        placeholder='Currency'
                        options={currencyOptions}
                        value={currency}
                        onChange={setCurrency}
                        className='input-group-text p-0 border-0'
                        classNamePrefix='RS-Addon'
                        leftRounded
                        customComponents={{
                          Option: customOption,
                          Value: customSingleValue,
                          SingleValue: customSingleValue,
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label>
                      Model
                      <span
                        className='text-danger font-size-16 mx-1'
                        style={{ lineHeight: '14px' }}
                      >
                        *
                      </span>
                    </Label>
                    <ControlledInput
                      control={control}
                      name='description'
                      error={errors.description}
                      placeholder='ex. Macbook Air 2020'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <Label>Serial number</Label>
                    <ControlledInput
                      control={control}
                      name='serial_number'
                      error={errors.serial_number}
                      placeholder='Enter serial number (Optional)'
                    />
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </TabPane>

          {phase === 1 && (
            <TabPane tabId={1}>
              <EquipmentEditor
                onEdit={() => {}}
                onChange={(s) => {
                  setTextAgreement(s)
                }}
                sections={[{ details: editable }]}
                agreement={getEditableAgreement.data?.part1}
                signature={getEditableAgreement.data?.part3}
              />
            </TabPane>
          )}

          <TabPane tabId={2}>
            <SignaturePart
              html={
                getHtmlAgreement.data
                  ? window.URL.createObjectURL(getHtmlAgreement.data)
                  : null
              }
              loadingHtml={getHtmlAgreement.isLoading}
              toggle={toggle}
            />
          </TabPane>
        </TabContent>
      </ModalBody>

      <ModalFooter className='flex-md-nowrap'>
        {phase !== 2 ? null : (
          <Input
            placeholder='Enter your full name'
            onChange={(e) => {
              setFullName(e.target.value)
            }}
            value={fullName}
          />
        )}

        {user?.type === userTypes.COMPANY && (
          <Button
            color='light'
            outline
            onClick={handleCancelBack}
            disabled={btnLoading}
          >
            {phase === 0 ? 'Cancel' : 'Back'}
          </Button>
        )}

        <Button
          loading={btnLoading}
          disabled={
            btnLoading ||
            !hasAccess(permissions.signEquipment) ||
            (selectedItem?.id && !hasAccess(permissions.updateEquipment))
          }
          {...continueBtnProps}
        >
          {phase === 2
            ? 'Agree & Sign'
            : phase === 1
              ? 'Next'
              : selectedItem?.id
                ? 'Update'
                : 'Continue'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const SignaturePart = ({ html, htmlLoading }) => {
  return (
    <>
      {htmlLoading ? (
        <Loader minHeight='60vh' />
      ) : !html ? null : (
        <iframe
          id='eqSignatureFrame'
          src={html}
          width='100%'
          className='bg-white'
          style={{
            overflow: 'auto',
            minHeight: 'calc(100vh - 240px)',
            border: 0,
          }}
        />
      )}
    </>
  )
}

const EquipmentMobileCard = ({
  e,
  handleOpen,
  handleSetItemToSign,
  handleDownload,
  handleReturn,
  handleDelete,
  currency,
}) => {
  const user = useSelector((state) => state.Account.user)
  let desc = e?.description.replace('<p>', '')
  desc = desc.replace('</p>', '')
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.code || 'USD',
  })
  return (
    <div
      className='mb-3 mx-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <div className='d-flex justify-content-between border-bottom p-3 m-0 p-0'>
        <div>
          {user?.type === userTypes.CONTRACTOR && e?.contractor_signed === 0 ? (
            <a
              className='text-primary'
              onClick={() => {
                handleSetItemToSign(e)
                handleOpen()
              }}
            >
              Review & Sign
            </a>
          ) : (
            <BadgeX
              name={e.status?.name}
              status={getStatusColor(
                e?.status?.name?.includes('Pending')
                  ? 'Pending'
                  : e?.status?.name,
              )}
              textStatus={getStatusColor(
                e?.status?.name?.includes('Pending')
                  ? 'Pending'
                  : e?.status?.name,
              )}
            />
          )}
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'> Type</p>
        <p className='text-dark font-size-14 mb-0'> {e?.type}</p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Price</p>
        <p className='text-dark font-size-14 mb-0'>
          {e?.price ? formatter.format(e?.price) : 'N/A'}
        </p>
      </div>
      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Serial number</p>
        <p className='text-dark font-size-14 mb-0'>
          {e?.serial_number || 'N/A'}
        </p>
      </div>
      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Description</p>
        <p className='text-dark font-size-14 mb-0'>
          <div
            className='requirement-description'
            dangerouslySetInnerHTML={{
              __html: `${
                desc?.length > 25 ? desc?.substring(0, 25) + '...' : desc
              }`,
            }}
          />
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center p-3'>
        <p className='text-dark font-size-14 mb-0'>Actions</p>
        <div>
          <ActionDropDown
            equipment={e}
            handleDownload={handleDownload}
            handleReturn={handleReturn}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  )
}

const StyledFooter = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 35vh;
  }
`

export default Equipments
