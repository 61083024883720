import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft } from '@phosphor-icons/react'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import PageHeading from '../../../../components/ui/page-heading'
import { useFetch } from '../../../../helpers/hooks'
import {
  deDeleteJurisdictionBankCountry,
  deDeleteJurisdictionCurrency,
  deGetJurisdiction,
  deUpdateJurisdiction,
  getAdminJurisdictionContributionPlans,
} from '../../../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import LabelContent from '../../../Contract/CreateContract/components/label-content'

export default function JurisdictionEdit() {
  const { id } = useParams()

  const {
    data: jurisdiction,
    isLoading,
    startFetch: getJurisdiction,
  } = useFetch({
    action: deGetJurisdiction,
    autoFetch: true,
    withAdminAccess: true,
    body: { id },
  })

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title
          action={
            <Link to='/admin/jurisdictions' className='text-text-80'>
              <ArrowLeft size={24} />
            </Link>
          }
        >
          Jurisdiction Edit
        </PageHeading.Title>
      </PageHeading>

      <Card style={{ minHeight: '30rem' }}>
        {isLoading ? (
          <Loader minHeight='30rem' />
        ) : (
          <CardBody className='py-4 px-0'>
            <EditJurisdictions
              jurisdiction={jurisdiction}
              onUpdate={() => getJurisdiction({ id })}
            />
          </CardBody>
        )}
      </Card>
    </div>
  )
}

export const jurisdictionDateFormat = 'yyyy-MM-dd'

export function getDefaultJurisdiction(jurisdiction) {
  const currencies = jurisdiction?.currencies.map((currency) => currency.id)
  const countryId = jurisdiction?.country?.id
  const bankCountries = jurisdiction?.bank_countries.map(
    (country) => country.id,
  )
  const contributionPlanIds = jurisdiction?.contribution_plans.map(
    (plan) => plan?.id,
  )

  const isInternalPayrollEnabled =
    jurisdiction?.is_internal_payroll_enabled === 1
  const isExternalPayrollEnabled =
    jurisdiction?.is_external_payroll_enabled === 1

  return {
    ...jurisdiction,
    bank_countries: bankCountries,
    contribution_plan_ids: contributionPlanIds,
    currencies,
    country_id: countryId,
    is_internal_payroll_enabled: isInternalPayrollEnabled,
    is_external_payroll_enabled: isExternalPayrollEnabled,
  }
}

export function getBoolean(bool) {
  return bool ? 1 : 0
}

export const jurisdictionSchema = yup.object().shape({
  name: yup.string().required(),
  city: yup.string().required(),
  country_id: yup.number().required('Country is required'),
  is_internal_payroll_enabled: yup
    .boolean()
    .test(
      'payment-method-1',
      'At least one payment method is required',
      (value, { parent }) => value || parent.is_external_payroll_enabled,
    ),
  is_external_payroll_enabled: yup
    .boolean()
    .test(
      'payment-method-2',
      'At least one payment method is required',
      (value, { parent }) => value || parent.is_internal_payroll_enabled,
    ),
  is_wps_enabled: yup.boolean().notRequired(),
  currencies: yup
    .array()
    .of(yup.number())
    .min(1, 'At least one currency is required')
    .required('At least one currency is required'),
  bank_countries: yup
    .array()
    .of(yup.number())
    .min(1, 'At least one bank country is required')
    .required('At least one bank country is required'),
})

function EditJurisdictions({ jurisdiction, onUpdate }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultJurisdiction(jurisdiction),
    resolver: yupResolver(jurisdictionSchema),
  })

  const { startFetch: updateJurisdiction, isLoading } = useFetch({
    action: deUpdateJurisdiction,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          typeof data?.message === 'string'
            ? data.message
            : 'Failed to update jurisdiction',
        )
      } else {
        onUpdate?.()
      }
    },
    onError: (err) => {
      toastr.error(
        typeof err === 'string' ? err : 'Failed to update jurisdiction',
      )
    },
  })

  function onSubmit(data) {
    const body = {
      ...data,
      is_wps_enabled: getBoolean(data.is_wps_enabled),
      is_external_payroll_enabled: getBoolean(data.is_external_payroll_enabled),
      is_internal_payroll_enabled: getBoolean(data.is_internal_payroll_enabled),
      jurisdiction_id: jurisdiction.id,
    }

    updateJurisdiction(body)
  }

  function handleDeleteError(err) {
    const message = typeof err === 'string' ? err : null
    toastr.error(
      message ?? 'Failed to delete item',
      message ? 'Failed to delete item' : 'Error',
    )
    onUpdate?.()
  }

  function handleDeleteComplete(data) {
    if (data?.success === false) {
      toastr.error(
        typeof data?.message === 'string'
          ? data.message
          : 'Failed to delete item',
      )
      onUpdate?.()
    } else {
      onUpdate?.()
      toastr.success('Item deleted successfully')
    }
  }

  const { startFetch: deleteCurrency } = useFetch({
    action: deDeleteJurisdictionCurrency,
    withAdminAccess: true,
    onComplete: handleDeleteComplete,
    onError: handleDeleteError,
  })
  const { startFetch: deleteBankCountry } = useFetch({
    action: deDeleteJurisdictionBankCountry,
    withAdminAccess: true,
    onComplete: handleDeleteComplete,
    onError: handleDeleteError,
  })

  function handleRemoveItem({ removedValue, name }) {
    const body = {
      jurisdiction_id: jurisdiction.id,
    }

    if (removedValue?.__isNew__ === true) {
      return
    }

    let deleteFunction = () => {}

    if (name === 'currencies') {
      deleteFunction = deleteCurrency
      body.currency_id = removedValue.value
    } else if (name === 'bank_countries') {
      deleteFunction = deleteBankCountry
      body.country_id = removedValue.value
    }

    deleteFunction(body)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <JurisdictionForm
          control={control}
          errors={errors}
          handleRemoveItem={handleRemoveItem}
        />

        <Container fluid className='mt-4'>
          <Row>
            <Col>
              <Button type='submit' loading={isLoading} disabled={isLoading}>
                Update jurisdiction
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  )
}

export function JurisdictionForm({ control, errors, handleRemoveItem }) {
  const { countries, currencies } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  function detectRemoveItem(value, { action, removedValue, name }) {
    const removeAction = action === 'remove-value'

    if (removeAction) {
      handleRemoveItem?.({ removedValue, name })
    }

    return value
  }

  const { data: plans } = useFetch({
    action: getAdminJurisdictionContributionPlans,
    withAdminAccess: true,
    autoFetch: true,
  })

  const { contribution_plan_ids: contributionPlanIds } = useWatch({
    control,
  })
  const contributionPlanPercentages = plans?.filter(
    (plan) =>
      contributionPlanIds?.includes(plan.id) &&
      plan?.configs?.contribution_percentages,
  )
  return (
    <Container fluid>
      <Row>
        <Col md={4} lg={3}>
          <ControlledInput
            control={control}
            label={<LabelContent required>Name</LabelContent>}
            name='name'
            containerClassName='mb-3'
            error={errors.name}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledInput
            control={control}
            label={<LabelContent required>City</LabelContent>}
            name='city'
            containerClassName='mb-3'
            error={errors.city}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledSelect
            control={control}
            label='Country'
            name='country_id'
            containerClassName='mb-3'
            error={errors.country_id}
            options={countries?.map((country) => mapCountryToOption(country))}
            required
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={
              <LabelContent required>Is internal payroll enabled</LabelContent>
            }
            name='is_internal_payroll_enabled'
            id='is_internal_payroll_enabled'
            containerClassName='mb-3 mt-4'
            error={errors.is_internal_payroll_enabled}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={
              <LabelContent required>Is external payroll enabled</LabelContent>
            }
            name='is_external_payroll_enabled'
            id='is_external_payroll_enabled'
            containerClassName='mb-3 mt-4'
            error={errors.is_external_payroll_enabled}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={<LabelContent>Is WPS enabled</LabelContent>}
            name='is_wps_enabled'
            id='is_wps_enabled'
            containerClassName='mb-3 mt-4'
            error={errors.is_wps_enabled}
          />
        </Col>
      </Row>

      <Row className='tw-gap-y-4'>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            name='currencies'
            label={<LabelContent required>Currencies</LabelContent>}
            options={currencies?.map((currency) =>
              mapCurrencyToOption(currency, 'id'),
            )}
            isMulti
            transform={{ output: detectRemoveItem }}
            containerClassName='mb-3'
            isClearable={false}
            error={errors.currencies}
          />
        </Col>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            name='bank_countries'
            label={<LabelContent required>Bank countries</LabelContent>}
            options={countries?.map((country) => mapCountryToOption(country))}
            isMulti
            transform={{ output: detectRemoveItem }}
            containerClassName='mb-3'
            isClearable={false}
            error={errors.bank_countries}
          />
        </Col>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            id='contribution_plan_ids'
            name='contribution_plan_ids'
            label='EOS contribution plan'
            options={plans?.map((plan) => ({
              label: plan.name,
              value: plan.id,
            }))}
            containerClassName='mb-3'
            isClearable
            isMulti
          />
          {contributionPlanPercentages?.map((_plan, i) => (
            <Alert
              key={i}
              color='info'
              className='tw-my-3 tw-items-center !tw-p-4'
              innerClassName='tw-text-black tw-text-xs'
            >
              {_plan?.name} {'>'} 5 year tenure contribution{' '}
              <span className='tw-px-1 tw-text-text-80'>
                Min. of{' '}
                {
                  _plan?.configs?.contribution_percentages
                    ?.five_years_and_more_tenure
                }
              </span>{' '}
              | {'<'} 5 year tenure contribution{' '}
              <span className='tw-px-1 tw-text-text-80'> Min. of </span>
              {
                _plan?.configs?.contribution_percentages
                  ?.less_than_five_years_tenure
              }
            </Alert>
          ))}
        </Col>
      </Row>
    </Container>
  )
}
