import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Badge, Card, Col, Container, Row, Table } from 'reactstrap'
import styled from 'styled-components'

import { ActionsDropdown } from 'ui'
import archiveIcon from '../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../assets/images/unarchive.svg'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import { StyledH6, StyledP } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import Pagination from '../../../components/ui/pagination'
import Shimmer from '../../../components/ui/shimmer'
import {
  CONTRACT_ACTIVITIES,
  CONTRACT_STATUS,
  userTypes,
} from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  archiveAdminContract,
  archiveContract,
  getContractDetail,
} from '../../../services/api'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ContractRef from '../../AdminPanel/components/ContractRef'
import welcomeImage from './../../../assets/images/welcome.svg'
import ClientCell from './client-cell'
import ContractStatus from './contract-status'
import UserCell from './user-cell'

export function getContractStatusColor(status, lastContractActivity = null) {
  switch (status?.id) {
    case CONTRACT_STATUS.ONGOING.value:
      return [
        CONTRACT_ACTIVITIES.TERMINATION_SCHEDULED,
        CONTRACT_ACTIVITIES.TERMINATION_REQUESTED,
        CONTRACT_ACTIVITIES.TERMINATION_REQUEST_APPROVED,
      ].includes(lastContractActivity)
        ? 'danger'
        : 'success'
    case CONTRACT_STATUS.ENDED.value:
    case CONTRACT_STATUS.CANCELLED.value:
    case CONTRACT_STATUS.TERMINATED.value:
      return 'danger'
    default:
      return 'warning'
  }
}

const ContractList = ({
  Orders,
  onQuery,
  isAdmin,
  withArchive,
  loading,
  archiveValue,
  onUpdate,
  withCustomFilter,
  history,
  paginator,
  page,
  setPage,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const isMobile = useResize()
  const [data, setData] = useState(Orders || [])
  const [query, setQuery] = useState(null)
  const defaultState = { label: 'All', value: -1 }
  const [state, setState] = useState(defaultState)
  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )

  useEffect(() => {
    setData(Orders)
  }, [Orders])

  const handleSearch = (query) => {
    setQuery(query)
    onQuery(query)
  }
  const handleFilter = (elm) => {
    setState(elm)
    if (elm?.value === -1) {
      clearFilter()
    } else {
      setData(Orders.filter((s) => s?.status?.id === elm.value))
    }
  }
  const clearFilter = () => {
    setData(Orders)
    setState(defaultState)
  }
  const getContractDetailUrl = (contract) => {
    if (
      !isAdmin &&
      (hasAccess(permissions.ViewContractsDetails) ||
        user?.type === userTypes.CONTRACTOR)
    ) {
      return `/contract/detail?id=${contract.ref}`
    } else if (isAdmin) {
      return `/admin/contract/detail?id=${contract.ref}`
    } else {
      return '#'
    }
  }

  return Orders || isAdmin ? (
    isMobile ? (
      <div>
        {withArchive && (user?.type === userTypes.COMPANY || isAdmin) && (
          <Col className='px-3 pb-3'>
            {loading ? (
              <Shimmer width='100%' height='42px' />
            ) : (
              <SearchBar
                query={query}
                className='mr-0 mr-md-2'
                onQueryChanged={handleSearch}
              />
            )}
          </Col>
        )}
        <Col
          className='p-3'
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 0px #DFE1E6',
            borderRadius: 4,
          }}
        >
          {loading ? (
            <div className='w-100' style={{ '--s-height': '216px' }}>
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            </div>
          ) : (
            data?.map((order, i) => (
              <ContractCard
                index={i}
                key={'_order_' + i}
                archiveValue={archiveValue}
                withArchive={withArchive}
                order={order}
                isAdmin={isAdmin}
                getContractDetailUrl={getContractDetailUrl}
                onUpdate={onUpdate}
              />
            ))
          )}
        </Col>
      </div>
    ) : loading ? (
      <>
        {withArchive && (user?.type !== userTypes.CONTRACTOR || isAdmin) && (
          <Container fluid>
            <Row className='pb-3 px-3' style={{ gap: '0.75rem' }}>
              <Col className='px-0'>
                <Shimmer width='100%' height='42px' />
              </Col>
              {withCustomFilter && user?.type === userTypes.COMPANY && (
                <Col md={4} xs={12} className='px-0'>
                  <Shimmer width='100%' height='42px' />
                </Col>
              )}
            </Row>
          </Container>
        )}
        <Card className='p-3'>
          <div className='w-100' style={{ '--s-height': '57px' }}>
            <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
            <Shimmer width='100%' height='var(--s-height)' />
          </div>
        </Card>
      </>
    ) : (
      <>
        <StyledTableCont>
          {withArchive && (user?.type !== userTypes.CONTRACTOR || isAdmin) && (
            <Container fluid>
              <Row className='pb-3 px-3' style={{ gap: '0.75rem' }}>
                <Col className='px-0'>
                  <SearchBar
                    query={query}
                    placeholder={
                      isAdmin
                        ? 'Search by name, email, or contract ID'
                        : undefined
                    }
                    onQueryChanged={handleSearch}
                    className='mr-md-2'
                    isClearable
                  />
                </Col>
                {withCustomFilter && user?.type === userTypes.COMPANY && (
                  <Col md={4} xs={12} className='px-0'>
                    <CustomSelect
                      options={
                        Array.isArray(status)
                          ? [
                              defaultState,
                              ...(status?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultState]
                      }
                      value={state}
                      onChange={handleFilter}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          )}
          <div>
            <Table
              responsive
              className='table-centered table-nowrap text-muted mb-0'
            >
              <thead>
                <tr
                  style={{
                    borderTop: 'hidden',
                    borderBottom: '1px solid #E7E8F2',
                  }}
                >
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14 px-4'
                  >
                    Contract ID
                  </th>
                  {isAdmin && (
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Created on
                    </th>
                  )}
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Role
                  </th>
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Type
                  </th>
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Compensation
                  </th>
                  {!!isAdmin && (
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Company
                    </th>
                  )}
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    {!!isAdmin || user?.type === 'client' ? 'Name' : 'Client'}
                  </th>

                  {!!isAdmin && (
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    >
                      Client
                    </th>
                  )}
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14 px-1'
                  >
                    Status
                  </th>
                  {withArchive && hasAccess(permissions.archiveContracts) && (
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    />
                  )}
                </tr>
              </thead>
              <tbody>
                {data?.map((order) => {
                  return (
                    <ContractLine
                      key={`contract-${order?.ref}`}
                      order={order}
                      isAdmin={isAdmin}
                      archiveValue={archiveValue}
                      withArchive={withArchive}
                      onUpdate={onUpdate}
                    />
                  )
                })}
              </tbody>
            </Table>
          </div>

          {!paginator ? null : (
            <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
              <Pagination
                innerClass='pagination mb-0'
                activePage={page}
                onChange={setPage}
                itemsCountPerPage={paginator?.per_page ?? 50}
                totalItemsCount={paginator?.total ?? 0}
              />
            </div>
          )}
        </StyledTableCont>
      </>
    )
  ) : !loading ? (
    <div>
      <NoContent
        image={welcomeImage}
        subtitle={
          user?.type === userTypes.COMPANY
            ? undefined
            : 'Manage contracts, invoices and get paid in +126 currencies'
        }
        title=''
        withAction={hasAccess(permissions.CreateContracts)}
        actionTitle='New Contract'
        onAction={() => {
          history.push('/contract/create')
        }}
      />
    </div>
  ) : null
}

const ContractCard = ({
  order,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  isAdmin,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const secondPart =
    user?.type === userTypes.COMPANY ? order?.contractor : order?.client

  const name =
    secondPart?.first_name || secondPart?.first_name
      ? `${secondPart?.first_name || ''} ${secondPart?.last_name || ''}`
      : secondPart?.email

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })
  return (
    <div
      className='py-0 mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={8} className='p-0 m-0'>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-dark font-size-14 mb-0'
          >
            {order.name}
          </p>
          <Link
            style={{ textDecoration: 'none' }}
            className='text-primary border-bottom border-secondary font-size-12'
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        </Col>
        <Col
          xs={4}
          className='align-items-center justify-content-end d-flex p-0 m-0'
        >
          <Badge
            className={`font-size-14 rounded bg-soft-${getContractStatusColor(
              order?.status,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min='12px'
              max='14px'
              className={`mb-0 text-${getContractStatusColor(order?.status)}`}
            >
              {order?.status?.name.includes('Pending')
                ? 'Pending'
                : order?.status?.name}
            </StyledP>
          </Badge>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Created on
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Compensation
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.total}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Type
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.type}
          </h6>
        </div>

        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contractor
          </h6>
          {secondPart
            ? !secondPart?.photo
            : !isAdmin && (
                <div className='text-dark font-size-14'>
                  <Link
                    to={`/contract/detail?id=${order.ref}`}
                    className='rp-font-bold text-primary font-size-14'
                  >
                    Invite
                  </Link>
                </div>
              )}
          {!name ? null : (
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              {name}
            </h6>
          )}
        </div>
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              Company
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.company}
            </StyledH6>
          </div>
        )}
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              Client
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.client?.first_name || order?.client?.first_name
                ? `${order?.client?.first_name || ''} ${
                    order?.client?.last_name || ''
                  }`
                : order?.client?.email}
            </StyledH6>
          </div>
        )}
      </div>
      {withArchive &&
        hasAccess(permissions.archiveContracts) &&
        (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) && (
          <div className='px-3 pb-3 w-100'>
            {archive.isLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
              </div>
            ) : (
              <Button
                type='button'
                text={archiveValue === 0 ? 'Un-archive' : 'Archive'}
                icon={
                  archiveValue === 0 ? (
                    <img
                      src={unArchiveIcon}
                      alt='archive icon'
                      style={{ width: 16, marginRight: 8 }}
                    />
                  ) : (
                    <img
                      src={archiveIcon}
                      alt='archive icon'
                      style={{ width: 16, marginRight: 8 }}
                    />
                  )
                }
                style={{ width: '100%', gap: 0 }}
                color='white'
                onClick={() => {
                  archive.startFetch({
                    contract_id: order.id,
                    status: archiveValue,
                  })
                }}
                className='rp-border'
              />
            )}
          </div>
        )}
    </div>
  )
}

const ContractLine = ({
  order,
  isAdmin,
  withArchive,
  archiveValue,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const history = useHistory()

  const toDuplicate = useFetch({
    action: getContractDetail,
    onComplete: (data) => {
      dispatch(setContractToDuplicate(data))
      history.push('/contract/create')
    },
  })

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })

  const total = order?.total?.split('/') ?? []
  const canArchive =
    (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) &&
    withArchive &&
    hasAccess(permissions.archiveContracts)

  const canDuplicate =
    user?.type !== userTypes.CONTRACTOR &&
    order?.type !== 'Full Time' &&
    !isAdmin &&
    hasAccess(permissions.archiveContracts)

  const actionsOptions = []

  if (canArchive) {
    actionsOptions[0] = {
      label: archiveValue === 0 ? 'Unarchive' : 'Archive',
      onClick: () => {
        archive.startFetch({
          contract_id: order.id,
          status: archiveValue,
          contract: order,
        })
      },
    }
  }

  if (canDuplicate) {
    actionsOptions[1] = {
      label: 'Duplicate',
      onClick: () => {
        toDuplicate.startFetch({ id: order?.ref })
      },
    }
  }

  return (
    <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
      <td className='p-4'>
        <ContractRef
          contractId={order.ref}
          isAdmin={isAdmin}
          target={isAdmin ? '_blank' : null}
        />
      </td>
      {isAdmin && (
        <td className='text-dark font-size-14' style={{ fontWeight: '400' }}>
          {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
        </td>
      )}
      <td
        className='text-dark font-size-14 text-truncate'
        style={{ fontWeight: '400', maxWidth: '24ch' }}
        title={order?.name || ''}
        translate='no'
      >
        {order?.name || ''}
      </td>
      <td className='text-dark font-size-14' style={{ fontWeight: '400' }}>
        {order.type}
      </td>
      <td>
        <StyledH6
          style={{ fontWeight: '400' }}
          className='text-dark font-size-14'
        >
          {total[0]} <span className='text-secondary'>/{total[1]}</span>
        </StyledH6>
      </td>
      {!!isAdmin && (
        <td className='text-dark font-size-14' style={{ fontWeight: '400' }}>
          {order.company}
        </td>
      )}
      <td>
        <UserCell isAdmin={isAdmin} item={order} onUpdate={onUpdate} />
      </td>
      {!isAdmin ? null : (
        <td>
          <ClientCell isAdmin item={order} onUpdate={onUpdate} />
        </td>
      )}
      <td className='px-1'>
        <ContractStatus
          contract={order}
          badgeStatus={getContractStatusColor(order?.status)}
        />
      </td>
      <td className='position-relative'>
        {actionsOptions?.length > 0 && (
          <Row style={{ minWidth: '100px' }} className='justify-content-center'>
            {archive.isLoading || toDuplicate.isLoading ? (
              <i className='bx bx-loader bx-spin text-dark font-size-24' />
            ) : (
              <ActionsDropdown data={actionsOptions} />
            )}
          </Row>
        )}
      </td>
    </tr>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export const StyledIcon = styled.span`
  --size: 16px;
  width: var(--size);
  height: var(--size);
  display: flex;
  margin-right: ${(props) => (props.noMargin ? '0' : '10px')};
  background: ${(props) => `url(${props.icon}) no-repeat`};

  &:hover {
    background: ${(props) => `url(${props.hoverIcon}) no-repeat 0 0`};
  }

  p {
    margin-left: 25px;

    &:hover {
      color: #3250e7;
    }
  }
`

export default withRouter(ContractList)
