import { yupResolver } from '@hookform/resolvers/yup'
import {
  CheckCircle,
  IdentificationCard,
  LinkBreak,
  ListChecks,
  PaperPlaneTilt,
  Warning,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import slackImage from '../../assets/images/brands/slack.svg'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import ControlledInput from '../../components/ControlledInput'
import Toggle from '../../components/Forms/Toggle/Toggle'
import Alert from '../../components/ui/alert'
import { NewBadge } from '../../components/ui/badge-new'
import Shimmer from '../../components/ui/shimmer'
import FEATURE_FLAGS from '../../config/feature-flags'
import { useFetch } from '../../helpers/hooks'
import {
  disconnectSlackAuth,
  getClientInfo,
  getNotificationsSettings,
  getSlackConnectionUrl,
  optInNotificationStatus,
  optOutNotificationStatus,
  updateClientInfo,
  updateSlackNotificationConfig,
} from '../../services/api'
import { updateUserProfileInfo } from '../../store/profile/actions'
import { emailRegex } from '../remotepass-refers/components/sharing-section'

const errorMessage = 'The notification email must be a valid email'

const schema = yup.object().shape({
  notification_email: yup
    .string()
    .email(errorMessage)
    .required()
    .matches(emailRegex, errorMessage),
})

const Notifications = () => {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const [showInput, setShowInput] = useState(!!user?.notification_email)
  const [slackNotificationEnabled, setSlackNotificationEnabled] =
    useState(false)
  const [configOptions, setConfigOptions] = useState({})
  const [showDisconnectSlackModal, setShowDisconnectSlackModal] =
    useState(false)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues: { notification_email: user?.notification_email },
  })
  const {
    data: notificationsSettings,
    startFetch: getNotificationsSettingsList,
    isLoading: notificationsLoading,
  } = useFetch({
    action: getNotificationsSettings,
    autoFetch: true,
  })
  const { startFetch: disableTopic, isLoading: disablingTopic } = useFetch({
    action: optOutNotificationStatus,
    onComplete: () => {
      getNotificationsSettingsList()
      toastr.success('Updated successfully')
    },
  })
  const { startFetch: enableTopic, isLoading: enablingTopic } = useFetch({
    action: optInNotificationStatus,
    onComplete: () => {
      getNotificationsSettingsList()
      toastr.success('Updated successfully')
    },
  })
  const update = useFetch({
    action: updateClientInfo,
    onError: toastr.error,
    onComplete: (data) => {
      dispatch(updateUserProfileInfo(data))
      toastr.success('Updated successfully')
    },
  })
  const clientInfo = useFetch({
    action: getClientInfo,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.slack_config) {
        setSlackNotificationEnabled(true)
        setConfigOptions({
          is_requests_approval_notification_enabled:
            !!data?.slack_config?.is_requests_approval_notification_enabled,
          is_daily_recap_notification_enabled:
            !!data?.slack_config?.is_daily_recap_notification_enabled,
          is_contract_updates_notification_enabled:
            !!data?.slack_config?.is_contract_updates_notification_enabled,
        })
      } else {
        setSlackNotificationEnabled(false)
      }
    },
  })
  const { startFetch: updateSlackConfig, isLoading: isSlackConfigUpdating } =
    useFetch({
      action: updateSlackNotificationConfig,
      onError: toastr.error,
      onComplete: (_, body) => {
        setConfigOptions(body)
        toastr.success('Updated Slack notification preference successfully')
      },
    })
  const { startFetch: fetchSlackConnectionUrl } = useFetch({
    action: getSlackConnectionUrl,
    autoFetch: false,
    onComplete: (slackConnectionUrl) => {
      if (slackConnectionUrl) {
        const openWindow = window.open(
          slackConnectionUrl,
          'SlackAuthWindow',
          'popup=true,left=400,top=100,width=620,height=620',
        )
        if (!openWindow) {
          toastr.info(
            'Window not opened, might be due to built-in popup blockers',
          )
        } else {
          openWindow.opener.addEventListener('focus', () => {
            clientInfo.startFetch()
          })
        }
      }
    },
  })
  const updateNotificationStatus = (v, topic) => {
    if (!v.target.checked) {
      disableTopic({ topicId: topic?.id })
    } else {
      enableTopic({ topicId: topic?.id })
    }
  }
  const onSubmit = (v) => {
    update.startFetch(v)
  }
  const isSlackSettingsChecked =
    slackNotificationEnabled &&
    (configOptions?.is_contract_updates_notification_enabled ||
      configOptions?.is_daily_recap_notification_enabled ||
      configOptions?.is_requests_approval_notification_enabled)
  return (
    <Col className='mb-3 py-4' style={{ minHeight: '40vh' }}>
      <Label className='rp-font-semibold font-size-20'>Email Preferences</Label>
      <p className='text-muted font-size-14'>
        You will receive emails on this email{' '}
        <strong className='text-dark'>{user?.email}</strong>
      </p>
      <div className='border rounded p-3'>
        <Row className='p-0 m-0 align-items-center justify-content-between'>
          <div>
            <p className='text-dark font-size-14 rp-font-semibold mb-0'>
              Add another email
            </p>
            <p className='text-muted font-size-14 mb-0'>
              Send notifications to this email
            </p>
          </div>
          <Toggle
            check={!!user?.notification_email || showInput}
            change={(v) => {
              if (!v.target.checked && !!user?.notification_email)
                update.startFetch({ notification_email: '' })
              setShowInput(v.target.checked)
            }}
          />
        </Row>

        {!showInput ? null : (
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className='d-flex gap-12 align-items-start mt-2 px-0'
          >
            <FormGroup className='flex-grow-1 mb-0'>
              <ControlledInput
                name='notification_email'
                control={control}
                error={errors?.notification_email}
                placeholder='Email'
              />
            </FormGroup>

            <button className='btn btn-primary' type='submit'>
              {update.isLoading && <i className='bx bx-loader bx-spin mx-2' />}
              Save
            </button>
          </Form>
        )}
      </div>

      <Label tag='h2' className='rp-font-semibold font-size-20 mt-4'>
        Notifications Settings
      </Label>

      {notificationsLoading ? (
        <div style={{ '--s-height': '40px' }}>
          <Shimmer width='100%' height='20px' className='mb-2.5' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2.5' />
        </div>
      ) : (
        <>
          <p className='text-muted font-size-14'>
            Choose the email notifications you want to receive
          </p>
          {notificationsSettings?.map((sec) => (
            <SettingsSection
              key={sec?.id}
              title={sec?.name}
              checked={['OPTED_IN', 'REQUIRED'].includes(sec?.status)}
              disabled={
                sec?.status === 'REQUIRED' ||
                enablingTopic ||
                disablingTopic ||
                notificationsLoading
              }
              onchange={(v) => updateNotificationStatus(v, sec)}
            />
          ))}
        </>
      )}
      <Alert
        tag='div'
        color='info'
        className='mt-2'
        innerClassName='tw-text-black'
      >
        To opt out of receiving marketing emails, click the “unsubscribe” link
        located in the footer of any such email.
      </Alert>

      {FEATURE_FLAGS.SLACK_NOTIFICATION && (
        <>
          <Label
            tag='h2'
            className='rp-font-semibold font-size-20 mt-4 tw-flex tw-gap-1'
          >
            Slack notifications <NewBadge className='!tw-leading-loose' />
          </Label>
          <p className='text-muted font-size-14'>
            Receive notifications on items to review and handle time off,
            expenses and timesheets approvals directly in Slack
          </p>

          <SettingsSection
            key='slack-integration'
            title='Slack'
            description='Enable or disable Slack notifications'
            subSettingsText={
              slackNotificationEnabled ? (
                <>
                  <CheckCircle
                    className='tw-mr-2'
                    color='var(--green)'
                    weight='fill'
                    size={16}
                  />
                  Active Connection. Created by you on{' '}
                  {format(
                    new Date(clientInfo.data?.slack_config?.created_at),
                    'MMM d, yyyy',
                  )}
                </>
              ) : null
            }
            subSettingsAction={
              slackNotificationEnabled ? (
                <Button
                  size='sm'
                  color='var(--primary)'
                  outline
                  onClick={() => {
                    setShowDisconnectSlackModal(true)
                  }}
                >
                  <LinkBreak
                    className='tw-inline-block'
                    color='var(--red-100)'
                    size={16}
                  />{' '}
                  <span className='tw-text-systemRed'>Remove Connection</span>
                </Button>
              ) : null
            }
            prefixImageSrc={slackImage}
            checked={isSlackSettingsChecked}
            disabled={isSlackConfigUpdating}
            onchange={(v) => {
              if (slackNotificationEnabled) {
                if (v.target.checked) {
                  updateSlackConfig({
                    is_requests_approval_notification_enabled: true,
                    is_contract_updates_notification_enabled: true,
                    is_daily_recap_notification_enabled: true,
                  })
                } else {
                  updateSlackConfig({
                    is_requests_approval_notification_enabled: false,
                    is_contract_updates_notification_enabled: false,
                    is_daily_recap_notification_enabled: false,
                  })
                }
              } else {
                fetchSlackConnectionUrl()
              }
            }}
          />

          {slackNotificationEnabled && (
            <>
              <SettingsSection
                title='Approval Requests'
                description='Stay informed and approve requests for time off, expenses, and timesheets directly from Slack'
                checked={
                  !!configOptions?.is_requests_approval_notification_enabled
                }
                disabled={!slackNotificationEnabled || isSlackConfigUpdating}
                onchange={(v) => {
                  updateSlackConfig({
                    ...configOptions,
                    is_requests_approval_notification_enabled: v.target.checked,
                  })
                }}
                prefixIcon={<PaperPlaneTilt size={24} />}
              />
              <SettingsSection
                title='Contract Updates'
                description='Get alerts when your signature is requested or a contract is activated'
                checked={
                  !!configOptions?.is_contract_updates_notification_enabled
                }
                disabled={!slackNotificationEnabled || isSlackConfigUpdating}
                onchange={(v) => {
                  updateSlackConfig({
                    ...configOptions,
                    is_contract_updates_notification_enabled: v.target.checked,
                  })
                }}
                prefixIcon={<IdentificationCard size={24} />}
              />
              <SettingsSection
                title='Daily Recap'
                description='Receive a daily summary of all pending items requiring your review'
                checked={!!configOptions?.is_daily_recap_notification_enabled}
                disabled={!slackNotificationEnabled || isSlackConfigUpdating}
                onchange={(v) => {
                  updateSlackConfig({
                    ...configOptions,
                    is_daily_recap_notification_enabled: v.target.checked,
                  })
                }}
                prefixIcon={<ListChecks size={24} />}
              />
            </>
          )}
        </>
      )}

      {showDisconnectSlackModal && (
        <DisconnectSlackModal
          isOpen={showDisconnectSlackModal}
          toggle={() => setShowDisconnectSlackModal((open) => !open)}
          onUpdate={() => setSlackNotificationEnabled(false)}
        />
      )}
    </Col>
  )
}

export default Notifications

function SettingsSection(props) {
  const {
    checked,
    onchange,
    title,
    description,
    key,
    disabled,
    subSettingsText,
    subSettingsAction,
    prefixImageSrc,
    prefixIcon,
  } = props
  return (
    <div className='mb-3 border rounded tw-flex tw-flex-col'>
      <div
        key={key}
        className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-p-4'
      >
        <div className='tw-flex tw-items-center tw-gap-4'>
          {prefixImageSrc ? (
            <img
              className='tw-bg-surface-20 tw-p-2'
              src={prefixImageSrc}
              alt=''
            />
          ) : prefixIcon ? (
            <div className='tw-bg-surface-20 tw-p-2'>{prefixIcon}</div>
          ) : null}
          <div>
            <p className='text-dark font-size-14 rp-font-semibold mb-0'>
              {title}
            </p>
            {description ? (
              <p className='text-muted font-size-14 mb-0'>{description}</p>
            ) : null}
          </div>
        </div>

        <Toggle
          check={checked}
          change={onchange}
          disabled={disabled}
          marginRight={null}
        />
      </div>
      {subSettingsText && (
        <div className='border-top p-3 tw-flex tw-items-center tw-justify-between tw-bg-surface-10 tw-text-xs tw-text-text-80'>
          <div className='tw-flex'>{subSettingsText}</div>
          {subSettingsAction && <>{subSettingsAction}</>}
        </div>
      )}
    </div>
  )
}

function DisconnectSlackModal({ isOpen, toggle, onUpdate }) {
  const { startFetch: disconnectSlack, isLoading: isUpdating } = useFetch({
    action: disconnectSlackAuth,
    onError: toastr.error,
    onComplete: () => {
      toastr.success('Slack disconnected successfully')
      onUpdate?.()
      toggle()
    },
  })
  // @todo replace with <ConfirmationModal /> in apps/user/src/pages/time-off/time-off-details-menu.jsx:231
  return (
    <Modal
      contentClassName='!tw-rounded-lg'
      centered
      isOpen={isOpen}
      toggle={() => toggle()}
    >
      <ModalBody>
        <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
          <Warning size={24} color='var(--red-100)' />
          <ModalCloseButton toggle={toggle} />
        </div>
        <h5 className='tw-font-semibold'>
          Are you sure you want to remove this connection?
        </h5>
        <span className='text-text-80'>
          You will no longer get notifications in your Slack workspace
        </span>
      </ModalBody>

      <ModalFooter>
        <Button outline color='light' type='button' onClick={() => toggle()}>
          No, Close
        </Button>
        <Button
          onClick={() => {
            disconnectSlack()
          }}
          type='button'
          className='!tw-border-systemRed !tw-bg-systemRed'
          disabled={isUpdating}
        >
          Yes, Remove
        </Button>
      </ModalFooter>
    </Modal>
  )
}
