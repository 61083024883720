import { Money } from '@phosphor-icons/react'
import { format, isPast } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Card, NavItem } from 'reactstrap'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import Toggle from '../../components/Forms/Toggle/Toggle'
import Head from '../../components/head'
import Button from '../../components/ui/button'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { useFetch } from '../../helpers/hooks'
import { getUnpaidList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getFullName } from '../../utils/get-full-name'
import ContractRef from '../AdminPanel/components/ContractRef'
import { PageNav } from '../../components/page-nav'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { getPaymentIds } from '../payInvoices'
import { AED_CODE } from './all-due-payments'
import { AllPaymentActions } from './payment-actions'

function getPaymentId(item) {
  return item?.payments?.[0]?.id
}

const tabsData = [{ key: '', label: 'All payments' }]

function updateToPayIds({ selectedElements, dispatch }) {
  const toPayIds = getPaymentIds(selectedElements)
  dispatch(updateToPayList(toPayIds))
}
export function DuePaymentsPage() {
  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState([])

  const {
    data: unpaidList,
    isLoading: unpaidListLoading,
    completed: unpaidListCompleted,
    startFetch: _getUnpaidList,
  } = useFetch({
    action: getUnpaidList,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to fetch payments')
      }
    },
  })

  function handleToggleItem(item) {
    const paymentId = getPaymentId(item)

    setSelectedItems((prev) => {
      let newIds = [...prev]

      if (prev.includes(paymentId)) {
        newIds = prev.filter((id) => id !== paymentId)
      } else {
        newIds.push(paymentId)
      }

      // Update selected items in redux
      const selectedElements = unpaidList.filter((item) =>
        newIds.includes(getPaymentId(item)),
      )
      updateToPayIds({ selectedElements, dispatch })

      return newIds
    })
  }

  const isAllSelected =
    selectedItems?.length === unpaidList?.length && selectedItems?.length > 0

  return (
    <div className='page-content'>
      <Head title='Payments due' />

      <PaymentsDueList
        data={unpaidList}
        loading={unpaidListLoading}
        selectedItems={selectedItems}
        onToggleItem={handleToggleItem}
        dataCompleted={unpaidListCompleted}
        isAllSelected={isAllSelected}
        toggleSelectAll={() => {
          if (isAllSelected) {
            setSelectedItems([])
            updateToPayIds({ selectedElements: [], dispatch })
          } else {
            setSelectedItems(unpaidList.map(getPaymentId))
            updateToPayIds({ selectedElements: unpaidList, dispatch })
          }
        }}
        refetch={_getUnpaidList}
      />
    </div>
  )
}

function PaymentsDuePageNav() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const activeTab = params.get('tab') ?? ''

  return (
    <PageNav className='rp-shadow-2 !tw-mb-4 tw-rounded tw-bg-white'>
      {tabsData?.map((nav) => {
        return (
          <NavItem key={nav.key}>
            <PageNav.Link
              isActive={activeTab === nav.key}
              tag={Link}
              to={nav.key ? `/payment?tab=${nav.key}` : '/payment'}
            >
              {nav.label}
            </PageNav.Link>
          </NavItem>
        )
      })}
    </PageNav>
  )
}

function PaymentsDueList({
  data,
  loading,
  dataCompleted,
  selectedItems,
  onToggleItem,
  toggleSelectAll,
  isAllSelected,
  refetch,
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const profile = useSelector((state) => state.userProfile.userProfile)
  const currency = profile?.company?.currency || profile?.currency

  const columns = [
    {
      Header: 'Contract ref',
      accessor: 'contract_ref',
      Cell: ({ cellData: contractRef }) => {
        return <ContractRef contractId={contractRef} />
      },
    },
    {
      Header: 'Name',
      accessor: 'contractor_name',
      Cell: ({ rowData }) => {
        const firstPaymentContractor = rowData.payments?.[0]?.contractor
        return (
          <div className='tw-flex tw-items-center tw-gap-3'>
            <Avatar
              photo={firstPaymentContractor?.photo}
              name={firstPaymentContractor?.first_name}
              flag={firstPaymentContractor?.flag}
            />
            <div>{getFullName(firstPaymentContractor)}</div>
          </div>
        )
      },
    },
    { Header: 'Job title', accessor: 'contract_name' },
    {
      Header: 'Total',
      accessor: 'total_amount',
      Cell: ({ cellData, rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency?.code)
        return formatter.format(cellData)
      },
    },
    {
      Header: '',
      accessor: 'contract_ref',
      Cell: ({ cellData: contractRef, rowData }) => {
        const paymentId = getPaymentId(rowData)
        return (
          <Toggle
            id={`item-${contractRef}`}
            check={selectedItems.includes(paymentId)}
            change={() => {
              onToggleItem(rowData)
            }}
            marginRight=''
            className='tw-cursor-pointer'
          />
        )
      },
    },
  ]

  const isEmpty = (data?.length <= 0 || !data) && dataCompleted

  const totalToPay = data
    ?.filter((item) => selectedItems.includes(getPaymentId(item)))
    ?.reduce((prev, current) => {
      return prev + current?.total_trans_amount
    }, 0)

  const formatter = getCurrencyFormatter(currency.code)
  const formattedTotal = formatter.format(totalToPay)

  const aedPayments = data?.filter((e) => e?.currency?.code === AED_CODE)

  const showAedPayments =
    currency?.code === AED_CODE &&
    aedPayments?.length > 0 &&
    aedPayments?.length < data?.length

  return (
    <div>
      <PageHeading>
        <PageHeading.Title>Payments due</PageHeading.Title>

        {loading || isEmpty ? null : (
          <AllPaymentActions
            onPayAedPayments={() => {
              updateToPayIds({ selectedElements: aedPayments, dispatch })

              history.push('/pay-invoices')
            }}
            formattedTotalToPay={formattedTotal}
            rawTotalToPay={totalToPay}
            showAedPayments={showAedPayments}
          />
        )}
      </PageHeading>

      <PaymentsDuePageNav />

      <Card className='rp-shadow-2 tw-min-h-[30rem] tw-p-6'>
        {loading || !dataCompleted ? (
          <Loader minHeight='30rem' />
        ) : isEmpty ? (
          <TabEmpty
            icon={<Money size={250} color='var(--primary)' />}
            title='No payments due'
            subtitle='Payments due will be shown here'
          />
        ) : (
          <>
            <div className='tw-flex tw-justify-end'>
              <Button
                onClick={toggleSelectAll}
                type='button'
                size='sm'
                color='light'
                outline
              >
                {isAllSelected ? 'Deselect all' : 'Select all'}
              </Button>
            </div>

            <DataTable
              isRowExpandable
              striped
              fontClassName='tw-text-text-100'
              columns={columns}
              data={data}
              expandable={{
                expandedRowRender: (record) => {
                  return record?.payments?.map((payment) => {
                    const formatter = getCurrencyFormatter(
                      payment.currency?.code,
                    )

                    const isInPast = isPast(new Date(payment?.due_date))

                    return payment.works.map((work) => {
                      return (
                        <tr
                          key={work?.work_id}
                          className='!tw-bg-primary-10/90'
                        >
                          <td className='tw-relative !tw-p-0'>
                            <div className='tw-absolute tw-inset-0 tw-h-full tw-border-l-2 tw-border-primary-100'></div>
                          </td>
                          <td colSpan='3'>
                            <p className='tw-mb-1 tw-font-bold'>{work?.name}</p>
                            <p className='tw-mb-0'>{work?.details}</p>
                          </td>
                          <td colSpan='2'>
                            {formatter.format(work?.amount)}
                            {isInPast ? (
                              <div
                                className={cn(
                                  'tw-mt-1',
                                  isInPast && 'tw-text-systemRed-100',
                                )}
                              >
                                Due date:{' '}
                                {format(
                                  new Date(payment?.due_date),
                                  'dd-MM-yyyy',
                                )}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      )
                    })
                  })
                },
                rowExpandable: (record) => record.payments.length > 0,
              }}
            />
          </>
        )}
      </Card>
    </div>
  )
}
