import cx from 'classnames'
import React from 'react'

export function CheckItem({
  label,
  name,
  value,
  rounded,
  checked,
  disabled,
  className,
  labelClassName,
  onChange = () => {},
}) {
  // we derive the name from the label if it's not provided
  const formattedLabel =
    typeof label !== 'string' ? null : (label ?? '').replace(/\s/g, '-')
  const theName = [name, formattedLabel].filter(Boolean).join('-')

  return (
    <label
      className={cx(
        'font-size-14 m-0 d-flex align-items-center rp-checkbox-label',
        className,
      )}
      style={{
        gap: '0.625rem',
        color: '#4A4E5B',
        cursor: disabled ? 'not-allowed' : undefined,
        fontWeight: 400,
      }}
    >
      <input
        type='checkbox'
        className='flex-shrink-0 rp-checkbox-input'
        name={theName}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        style={{ ...(rounded && { borderRadius: '50%' }) }}
      />
      <span
        className={labelClassName}
        style={{ lineHeight: 1, opacity: disabled ? 0.3 : undefined }}
      >
        {label}
      </span>
    </label>
  )
}
