import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import IconButton from '../../../../components/ui/icon-button'
import { useResizeForSideBar } from '../../../../helpers/hooks'
import SidebarContent from './SidebarContent'

const Sidebar = ({ routePermissions, isMenuCollapsed, toggleMenuCollapse }) => {
  const isMobile = useResizeForSideBar()

  return (
    <div
      className='vertical-menu d-none d-lg-block'
      style={{
        transitionProperty: 'width, transform',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'ease',
      }}
    >
      <MenuToggleButton
        toggleMenuCollapse={toggleMenuCollapse}
        isMenuCollapsed={isMenuCollapsed}
      />

      <div data-simplebar className='h-100'>
        <SimpleBar style={{ maxHeight: '100%' }}>
          {!isMobile && <SidebarContent routePermissions={routePermissions} />}
        </SimpleBar>
      </div>
    </div>
  )
}

function MenuToggleButton({ toggleMenuCollapse, isMenuCollapsed }) {
  return (
    <IconButton
      circle
      size={32}
      icon={
        isMenuCollapsed ? (
          <CaretRight weight='bold' size={18} />
        ) : (
          <CaretLeft weight='bold' size={18} />
        )
      }
      onClick={toggleMenuCollapse}
      color='light'
      outline
      className='d-flex justify-content-center align-items-center border border-surface-50 position-absolute shadow-sm hover:bg-surface-30'
      style={{
        right: 32 / -2,
        top: '0.25rem',
        zIndex: 10,
        backgroundColor: '#f4f5f7',
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return { layout: state.Layout }
}

export default connect(
  mapStateToProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
