import {
  Asterisk,
  Briefcase,
  CaretRight,
  Check,
  Coins,
  HourglassHigh,
  HourglassMedium,
  IdentificationCard,
  ListChecks,
  Money,
  Paperclip,
  Receipt,
  UserCircle,
} from '@phosphor-icons/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import toastr from 'toastr'

import { Avatar, Pill, cn } from 'ui'
import { useHasChanged } from '.'
import Button from '../../components/ui/button'
import Shimmer from '../../components/ui/shimmer'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getClientToDoList } from '../../services/api'
import { getErrorMessage } from '../../utils/get-errors'
import { LoadingItems } from '../Contract/ContractPage/payment-approvals'
import {
  CenterOnboardingItem,
  ONBOARDING_TYPES,
} from './center-onboarding-Item'
import Accordion from '../../components/Accordion'

export function tagSession(user) {
  if (window.smartlook) {
    window.smartlook('identify', user?.id, {
      name: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
    })
  }
}

export const TODO_TYPE = {
  contracts: 'pending_contracts',
  payments: 'pending_payroll_approval',
  expenses: 'pending_expenses',
  timeOff: 'pending_time_off',
  documents: 'pending_document_requests',
  works: 'pending_works',
  invoices: 'pending_invoices',
}

export function getReviewTypeIcon(
  type,
  { size = 20, weight = 'regular' } = {},
) {
  switch (type) {
    case TODO_TYPE.contracts: {
      return <IdentificationCard size={size} weight={weight} />
    }
    case TODO_TYPE.payments: {
      return <Money size={size} weight={weight} />
    }
    case TODO_TYPE.expenses: {
      return <Coins size={size} weight={weight} />
    }
    case TODO_TYPE.timeOff: {
      return <HourglassHigh size={size} weight={weight} />
    }
    case TODO_TYPE.documents: {
      return <Paperclip size={size} weight={weight} />
    }
    case TODO_TYPE.works: {
      return <Briefcase size={size} weight={weight} />
    }
    case TODO_TYPE.invoices: {
      return <Receipt size={size} weight={weight} />
    }
    default: {
      return <Asterisk size={size} weight={weight} />
    }
  }
}

function isNumber(number) {
  return typeof number === 'number' && !isNaN(number)
}

export function CenterOverviewClient() {
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)

  const { hasAccess } = usePermissions()
  const hasTodoPermission = hasAccess(permissions.ViewContracts)

  const {
    data: todoList,
    isLoading,
    error,
  } = useFetch(
    {
      action: getClientToDoList,
      initResult: { items: [] },
      autoFetch: hasTodoPermission,
      onError: (err) => toastr.error(getErrorMessage(err)),
      onComplete: () => {
        tagSession(user)
      },
    },
    [hasCompanyChanged, hasTodoPermission],
  )

  const itemToReview = todoList?.items?.map(({ type, count, title }) => ({
    label: title,
    count,
    icon: getReviewTypeIcon(type),
    to: '/review-center?tab=' + type,
  }))

  const onboardingItems = todoList?.onboarding
  const totalCount = itemToReview.reduce((acc, { count }) => {
    if (isNumber(count)) {
      return acc + count
    }
    return acc + 1
  }, 0)

  const isTodoListEmpty = itemToReview?.length <= 0 || !itemToReview
  const isOnboardingEmpty = onboardingItems?.length <= 0 || !onboardingItems

  const isDone = isTodoListEmpty && isOnboardingEmpty && !error
  const [vacationPolicyTodo] =
    onboardingItems?.filter(
      (onboardingItem) =>
        onboardingItem.type === ONBOARDING_TYPES.VACATION_POLICY.key,
    ) ?? []

  if (error) {
    return null
  }

  return (
    <div>
      {isLoading ? (
        <CardWrapper className='tw-p-6'>
          <div className='tw-flex tw-justify-between'>
            <Shimmer className='!tw-w-44' />
            <Shimmer className='!tw-w-20' />
          </div>

          <hr className='-tw-mx-6 tw-my-6' />

          <LoadingItems
            length={4}
            className='tw-flex tw-gap-2'
            itemHeight={null}
            item={() => {
              return <Shimmer className='!tw-h-11 !tw-w-40 tw-rounded-full' />
            }}
          />
        </CardWrapper>
      ) : isDone ? (
        <CardWrapper className='tw-flex tw-gap-4 tw-p-6'>
          <div className='tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-systemGreen-10 tw-p-2 tw-text-systemGreen-100'>
            <Check size={20} />
          </div>

          <div>
            <p className='tw-mb-0 tw-text-base'>You are all set!</p>
            <p className='tw-mb-0 tw-text-base tw-text-text-80'>
              When you have items to review, we will show them here for you
            </p>
          </div>
        </CardWrapper>
      ) : (
        <>
          {isOnboardingEmpty ? null : (
            <CardWrapper>
              {onboardingItems
                .filter(
                  (onboardingItem) =>
                    onboardingItem.type !==
                    ONBOARDING_TYPES.VACATION_POLICY.key,
                )
                .map((item) => {
                  return <CenterOnboardingItem key={item.type} {...item} />
                })}
              {vacationPolicyTodo?.contracts?.length > 0 && (
                <Accordion
                  contentWrapperClassName='tw-bg-surface-10'
                  className='tw-border-y tw-border-y-surface-30'
                  label={
                    <span className='tw-flex tw-items-center'>
                      <HourglassMedium
                        size={40}
                        className='tw-rounded-full tw-bg-systemGold tw-p-2 tw-text-white'
                        weight='fill'
                      />
                      <span className='tw-mx-4 tw-flex-1 tw-text-left tw-text-base tw-font-semibold tw-text-black'>
                        {vacationPolicyTodo.title}
                      </span>
                    </span>
                  }
                  value={
                    <div className='tw-grid tw-grid-cols-[auto_auto] tw-gap-4 tw-overflow-x-scroll md:tw-flex md:tw-flex-wrap'>
                      {vacationPolicyTodo.contracts.map((contract) => (
                        <PolicyTodoItem
                          key={contract.contract_id}
                          contractId={contract.contract_id}
                          contractType={contract.contract_type}
                          flag={contract.contractor?.contractor_flag}
                          name={contract.contractor?.contractor_name}
                          photo={contract.contractor?.contractor_photo}
                        />
                      ))}
                    </div>
                  }
                />
              )}
            </CardWrapper>
          )}

          {isTodoListEmpty ? null : (
            <CardWrapper className={!isOnboardingEmpty && 'tw-mt-4'}>
              <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-p-6'>
                <ListChecks size={20} weight='duotone' />

                <h4 className='tw-mb-0 tw-text-base'>
                  {totalCount} Item{totalCount === 1 ? '' : 's'} to review
                </h4>

                <div className='tw-ml-auto'>
                  <Button
                    iconRight={<CaretRight size={20} />}
                    tag={Link}
                    to='/review-center'
                    color='link'
                    className='-tw-my-0.5 !tw-px-0 !tw-text-sm'
                  >
                    See all
                  </Button>
                </div>
              </div>

              <hr className='tw-m-0 tw-border-b tw-border-surface-30' />

              <div className='tw-flex tw-gap-2 tw-overflow-auto tw-p-6'>
                {itemToReview.map(({ label, count, icon, to }, index) => {
                  return (
                    <Pill
                      key={index}
                      rightItem={count}
                      leftItem={icon}
                      to={to}
                      tag={Link}
                    >
                      {label}
                    </Pill>
                  )
                })}
              </div>
            </CardWrapper>
          )}
        </>
      )}
    </div>
  )
}

function CardWrapper({ children, className }) {
  return (
    <div
      className={cn(
        'tw-mb-4 tw-overflow-hidden tw-rounded tw-bg-white',
        className,
      )}
    >
      {children}
    </div>
  )
}

function PolicyTodoItem({ contractId, name, photo, flag, contractType }) {
  const nameIsAvailable = name && name !== ' '
  return (
    <Pill
      tag={Link}
      to={`contract/detail?id=${contractId}&tab=timeOff`}
      className='tw-w-fit'
      leftItem={
        nameIsAvailable ? (
          <Avatar name={name} size='md' flag={flag} photo={photo} />
        ) : (
          <span className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-30 tw-p-2'>
            <UserCircle size={16} className='tw-fill-primary' />
          </span>
        )
      }
      rightItem={<CaretRight className='tw-ml-[6px] tw-fill-secondary-80' />}
    >
      <span className='tw-flex tw-flex-col'>
        <span className='tw-text-[10px] tw-font-semibold'>
          {nameIsAvailable ? name : contractType}
        </span>
        <span className='tw-text-[10px] tw-font-medium tw-text-text-60'>
          {contractId}
        </span>
      </span>
    </Pill>
  )
}
