import { CaretDown, DownloadSimple, Eye } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import toastr from 'toastr'

import { format } from 'date-fns'
import { cn } from 'ui'
import emptyMilestoneImage from '../../../assets/images/milestones-empty.png'
import noInvoicesImage from '../../../assets/images/noinvoices.svg'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import TableComp from '../../../components/Table/TableComp'
import TableHeader from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  checkExportStatus,
  downloadContractAllInvoices,
  downloadInvoice,
  downloadWork,
  getContractInvoices,
  getContractInvoicesMonths,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { AddMilestoneModal } from '../components/add-milestone-modal'
import { Timeline } from './item-timeline'

function getMonthDate(date) {
  const myDate = new Date(date)
  const month = myDate.getMonth() + 1
  const year = myDate.getFullYear()

  return `${year}-${String(month).padStart(2, '0')}`
}
export function Invoices({
  contract,
  updateContract,
  setShowConfirmationModal,
  setEndedModal,
}) {
  const [addMilestone, setAddMilestone] = useState(false)
  const [search, setSearch] = useState('')
  const [expanded, setExpanded] = useState(-1)
  const thisMonth = getMonthDate(new Date())

  const [pageFilters, setPageFilters] = useState({
    month: thisMonth,
    search_key: '',
  })
  const userIsPartner = useSelector(
    (state) => state.userProfile?.userProfile?.is_partner,
  )
  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const { data: contractInvoices, isLoading: dataLoading } = useFetch(
    {
      action: getContractInvoices,
      // eslint-disable-next-line no-console
      onError: console.log,
      autoFetch: true,
      body: {
        ...pageFilters,
        id: contract?.id,
        month: pageFilters?.month?.value,
      },
    },
    [pageFilters],
  )
  const months = useFetch({
    action: getContractInvoicesMonths,
    autoFetch: true,
    body: { id: contract?.id },
    onComplete: (data) => {
      if (data?.length > 0) {
        setPageFilters((prev) => ({
          ...prev,
          month: data?.[0],
        }))
      }
    },
  })
  const hasViewInvoices = hasAccess(permissions.ViewInvoices)
  function handleSearch(query) {
    setSearch(query)
    setPageFilters((filters) => ({ ...filters, search_key: query }))
  }
  if (!hasViewInvoices) {
    return (
      <Card className='rp-shadow-2 tw-p-4'>
        <PrimaryAlert>
          You do not have permission to view {PERMISSION_GROUP.INVOICES.name}.
          Please contact your administrator.
        </PrimaryAlert>
      </Card>
    )
  }

  return (
    <>
      {!addMilestone ? null : (
        <AddMilestoneModal
          contract={contract}
          isOpen={addMilestone}
          toggle={() => setAddMilestone((open) => !open)}
          onMilestoneAdded={updateContract}
        />
      )}

      {contract?.milestones?.length <= 0 ? (
        <NoContent
          customBottom={
            user?.type === userTypes.CONTRACTOR ||
            (hasAccess(permissions.CreateMilestones) &&
              contract?.can_approve &&
              contract?.type !== CONTRACT_TYPES.FULL_TIME) ||
            [5, 6, 7].includes(contract?.status?.id) ? (
              <AddMilestoneDropdown
                contract={contract}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
                setAddMilestone={setAddMilestone}
              />
            ) : null
          }
          headline={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'No Invoice added'
              : 'No Milestone added'
          }
          subtitle='All work submissions will be shown here and will be subject to approval by the client.'
          image={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? noInvoicesImage
              : emptyMilestoneImage
          }
          actionTitle={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'Add Invoice'
              : 'Add Milestone'
          }
          onAction={() => {
            setAddMilestone(true)
          }}
          advanced
        />
      ) : (
        <Card className='mb-0 rp-shadow-2'>
          <TableHeader
            title={
              contract?.type === CONTRACT_TYPES.FULL_TIME || userIsPartner
                ? 'Invoices'
                : CONTRACT_TYPES.MILESTONES
            }
            leftSide={
              user?.type === userTypes.CONTRACTOR ||
              contract?.type !== CONTRACT_TYPES.FULL_TIME ||
              [5, 6, 7].includes(contract?.status?.id) ? (
                userIsPartner ? (
                  <Button onClick={() => setAddMilestone(true)}>
                    Add Invoice
                  </Button>
                ) : (
                  <AddMilestoneDropdown
                    contract={contract}
                    setShowConfirmationModal={setShowConfirmationModal}
                    setEndedModal={setEndedModal}
                    setAddMilestone={setAddMilestone}
                  />
                )
              ) : null
            }
          />

          <div className='d-md-none'>
            {contractInvoices?.map((invoice, key) => {
              return (
                <MilestoneMobileCard
                  key={'_invoice_' + key}
                  invoice={invoice}
                />
              )
            })}
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3 tw-px-5 tw-pb-4 max-md:tw-flex-wrap sm:tw-flex-row'>
            {dataLoading ? (
              <>
                <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-3 sm:tw-min-w-96'>
                  <Shimmer
                    width='100%'
                    height='42px'
                    className='tw-col-span-12 sm:tw-col-span-5'
                  />
                  <Shimmer
                    width='100%'
                    height='42px'
                    className='tw-col-span-12 sm:tw-col-span-7'
                  />
                </div>
                <Shimmer
                  width='100%'
                  height='42px'
                  className='tw-col-span-12 tw-max-w-48'
                />
              </>
            ) : (
              <>
                <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-y-3 sm:tw-min-w-96'>
                  <CustomSelect
                    options={months?.data}
                    value={pageFilters.month}
                    onChange={(newDate) => {
                      setPageFilters((prev) => ({
                        ...prev,
                        month: getMonthDate(newDate),
                      }))
                    }}
                    withSearch
                    wrapperClassName='tw-col-span-12 sm:tw-col-span-5 lg:tw-col-span-4 [&_.RS-Control\_\_control]:sm:tw-rounded-r-none [&_.RS-Control\_\_control]:tw-border-r-0 [&_.RS-Control\_\_control]:tw-h-[42px]'
                  />

                  <SearchBar
                    roundedLeftNone
                    onQueryChanged={(query) => handleSearch(query)}
                    query={search}
                    className='tw-col-span-12 sm:tw-col-span-7 lg:tw-col-span-8'
                  />
                </div>

                <DownloadInvoicesButton
                  invoices={contractInvoices}
                  filters={pageFilters}
                  contract={contract}
                  icon={<DownloadSimple />}
                />
              </>
            )}
          </div>

          <div className='table-with-dropdown d-none d-md-block mb-1'>
            <TableComp className='table-centered'>
              <thead className='tw-text-black'>
                <tr>
                  <th />
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Invoice
                  </th>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Total
                  </th>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Created on
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {contractInvoices?.map((invoice, key) => {
                  return (
                    <InvoiceCard
                      key={'_invoice_' + key}
                      invoice={invoice}
                      expanded={expanded === key}
                      onExpand={() => {
                        if (expanded === key) {
                          setExpanded(-1)
                        } else {
                          setExpanded(key)
                        }
                      }}
                    />
                  )
                })}
              </tbody>
            </TableComp>
          </div>
        </Card>
      )}
    </>
  )
}

function AddMilestoneDropdown({
  contract,
  setShowConfirmationModal,
  setEndedModal,
  setAddMilestone,
}) {
  const [menu, setMenu] = useState(false)

  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const history = useHistory()

  return (
    <PermissionTooltip
      area={PERMISSION_GROUP.MILESTONES.name}
      showing={!hasAccess(permissions.CreateMilestones)}
      id='add-milestone-tooltip'
    >
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (contract.status.id === CONTRACT_STATUS.TERMINATED.value) {
            setShowConfirmationModal(true)
          } else if (contract.status.id === CONTRACT_STATUS.ENDED.value) {
            setEndedModal(true)
          } else {
            if (contract?.type === CONTRACT_TYPES.FULL_TIME) {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            } else if (user?.type === userTypes.CONTRACTOR) {
              setMenu(!menu)
            } else {
              setAddMilestone(true)
            }
          }
        }}
      >
        <DropdownToggle
          tag={Button}
          block
          disabled={
            !(
              user?.type === userTypes.CONTRACTOR ||
              hasAccess(permissions.CreateMilestones) ||
              contract?.can_approve
            ) || [5, 6, 7].includes(contract?.status?.id)
          }
        >
          {contract?.type === CONTRACT_TYPES.FULL_TIME
            ? 'Add Invoice'
            : 'Add milestone'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setAddMilestone(true)}>
            Add it Manually
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            }}
          >
            Upload Invoice
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </PermissionTooltip>
  )
}

function MilestoneMobileCard({ invoice, key }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)

  return (
    <div
      key={key}
      className='tw-mx-4 tw-mb-4 tw-rounded tw-border tw-border-surface-30 tw-bg-white'
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-p-4'>
        <MilestoneSideMenu invoice={invoice} />
      </div>

      <div className='tw-flex tw-flex-col tw-gap-5 tw-py-4 tw-text-sm'>
        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Invoice ref</span>

          {invoice?.invoice_ref}
        </div>

        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Total</span>

          <span className='tw-font-semibold tw-text-black'>
            {formatter.format(invoice?.total)}
          </span>
        </div>
      </div>
    </div>
  )
}

export function MilestoneSideMenu({ invoice }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function toggle() {
    setIsMenuOpen((isOpen) => !isOpen)
  }

  return (
    <>
      <Button
        color='link'
        onClick={toggle}
        type='button'
        className='!tw-px-0 !tw-py-1'
        icon={<Eye size={20} className='tw-text-primary' />}
      >
        Details
      </Button>

      <SideMenu
        onClose={toggle}
        isOpen={isMenuOpen}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr]'
      >
        <SideMenuHeader toggle={toggle} className='!tw-items-start'>
          <div>
            <p className='font-size-20 tw-mb-0 tw-font-semibold'>
              Invoice details
            </p>
            <p className='font-size-14 tw-mb-0 tw-text-text-60'>
              {invoice?.invoice_ref}
            </p>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <h4 className='tw-font-semibold'>Summary</h4>
          <WorkAttributes invoice={invoice} />
          <Timeline flowTimeline={invoice?.timeline} />
        </SideMenuBody>
        <SideMenuFooter>
          <DownloadAttachment item={invoice} />
        </SideMenuFooter>
      </SideMenu>
    </>
  )
}

export function DownloadAttachment({ item }) {
  const attachmentName = `attachment-${item?.id}.pdf`
  const user = useSelector((state) => state.Account?.user)

  const downloadLine = useFetch({
    action: downloadWork,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      openFileV2(url, {
        isDataUrl: true,
        download: true,
        name: attachmentName,
      })
    },
  })

  function handleDownloadInvoice() {
    window.analytics.track('Clicked download invoice', {
      invoice_id: item?.invoice_ref,
    })

    downloadInvoice(item.invoice_ref, user?.token, item.token).then((r) => {
      if (r.data?.data?.error || r.data?.message) {
        toastr.error(
          r.data?.data?.error || r.data?.message || 'An error occurred',
        )
        return
      }
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${item?.invoice_ref}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <Button
      onClick={handleDownloadInvoice}
      disabled={downloadLine.isLoading}
      loading={downloadLine.isLoading}
      className='btn-block'
      icon={<DownloadSimple />}
    >
      Download
    </Button>
  )
}
function InvoiceCard({ invoice, onExpand, expanded }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)

  return (
    <>
      <tr>
        <td>
          {onExpand ? (
            <button
              className={cn(
                'tw-relative tw-flex tw-items-center tw-justify-center',
                { expanded },
              )}
              onClick={onExpand}
              style={{
                '--size': '20px',
                width: 'var(--size)',
                height: 'var(--size)',
              }}
            >
              <CaretDown size={16} />
            </button>
          ) : null}
        </td>
        <td className='!tw-px-6'>{invoice?.invoice_ref}</td>
        <td className='!tw-px-6 tw-text-sm tw-text-black'>
          {formatter.format(invoice?.total)}
        </td>
        <td className='!tw-px-6'>
          {format(invoice?.invoice_date * 1000, 'MM/dd/yyyy')}
        </td>

        <td className='!tw-px-6'>
          <MilestoneSideMenu invoice={invoice} />
        </td>
      </tr>
      {expanded &&
        React.Children.toArray(
          invoice?.works?.map((work, index) => (
            <tr className='tw-bg-primary-10' key={`work-${index}`}>
              <td />
              <td className='!tw-px-6'>
                <div>
                  <p className='font-size-14 tw-text-text-100'>{work?.name}</p>
                  <p className='font-size-14 mb-0 tw-text-text-80'>
                    {work?.details}
                  </p>
                </div>
              </td>
              <td className='font-size-14 !tw-px-6 tw-text-text-80' colSpan={3}>
                {formatter.format(work?.amount)}
              </td>
            </tr>
          )),
        )}
    </>
  )
}
function WorkAttributes({ invoice, className }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)
  return (
    <div
      className={cn(
        'tw-mt-2 tw-flex tw-flex-col tw-gap-1 empty:tw-hidden',
        className,
      )}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
        <div>
          <p className='font-size-14 mb-0 tw-font-semibold tw-text-text-100'>
            Total
          </p>
        </div>
        <div className='font-size-14 !tw-px-6 tw-text-text-80'>
          {formatter.format(invoice?.total)}
        </div>
      </div>
      {invoice?.works?.map((attribute, key) => (
        <div
          key={'_attribute_' + key}
          className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'
        >
          <div>
            <p className='font-size-14 mb-0 tw-font-semibold tw-text-text-100'>
              {attribute?.name}
            </p>
            <p className='font-size-14 mb-0 mb-0 tw-text-text-80'>
              {attribute?.details}
            </p>
          </div>
          <div className='font-size-14 !tw-px-6 tw-text-text-80'>
            {formatter.format(attribute?.amount)}
          </div>
        </div>
      ))}
    </div>
  )
}

const DownloadInvoicesButton = ({
  invoices,
  filters,
  contract,
  icon,
  className,
}) => {
  const [loading, setLoading] = useState(false)

  const ExportStatuses = {
    FAILED: 'failed',
    READY: 'ready',
    PENDING: 'pending',
  }

  const checkDownloadStatus = useFetch(
    {
      action: checkExportStatus,
      autoFetch: false,
      onComplete: (data) => {
        if (data?.status === ExportStatuses.FAILED) {
          toastr.error('Failed to export')
          setLoading(false)
        } else if (data?.status === ExportStatuses.READY) {
          const link = document.createElement('a')
          link.href = data?.url
          link.setAttribute(
            'download',
            sendExportRequest.data?.path || 'AllInvoices.zip',
          )
          document.body.appendChild(link)
          link.click()

          setLoading(false)
        }
      },
      onError: (err) => {
        setLoading(false)
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  const sendExportRequest = useFetch(
    {
      action: downloadContractAllInvoices,
      autoFetch: false,
      body: {
        month: filters?.month?.value,
        search_key: filters?.search_key,
        id: contract?.id,
      },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (sendExportRequest.data?.id) {
        checkDownloadStatus.startFetch({
          exportId: sendExportRequest.data?.id,
        })
      }
    }, 5000)

    if (checkDownloadStatus.data?.url) {
      clearInterval(timeIntervalId)
    }

    return () => clearInterval(timeIntervalId)
  }, [sendExportRequest.data, checkDownloadStatus.data])

  const startDownloadProcess = () => {
    setLoading(true)
    sendExportRequest.startFetch()
  }

  return invoices?.length === 0 ? null : (
    <Button
      onClick={() => startDownloadProcess()}
      disabled={loading}
      loading={loading}
      icon={icon ?? null}
      className={className}
    >
      Download invoices
    </Button>
  )
}
