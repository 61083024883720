import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { useFetch, useResize } from '../../../helpers/hooks'
import FormGroup from 'reactstrap/lib/FormGroup'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useSelector } from 'react-redux'
import trach from '../../../assets/images/Trash.svg'
import { addAttribute, updateAttribute } from '../../../services/api'
import toastr from 'toastr'
import { getIcon } from '../CustomFields'
import Toggle from '../../../components/Forms/Toggle/Toggle'

const CustomFieldModal = ({ isOpen, toggle, onAdded, toUpdate }) => {
  const types = useSelector(
    (state) => state?.Layout?.staticData?.attribute_types,
  )
  const isMobile = useResize()

  const [type, setType] = useState(null)
  const [options, setOptions] = useState(['', ''])
  const [name, setName] = useState('')
  const [required, setRequired] = useState(false)

  useEffect(() => {
    if (toUpdate) {
      const defaultType = types?.find((e) => e.code === toUpdate?.type)
      if (defaultType) {
        setType({
          label: defaultType?.name,
          value: defaultType.id,
          icon: (
            <i
              className={`mdi ${getIcon(defaultType.code)} font-size-15 mr-2`}
            />
          ),
        })
      }
      setOptions(toUpdate?.options?.map((e) => e.name))
      setName(toUpdate?.name)
      setRequired(!!toUpdate?.required)
    }
  }, [toUpdate])

  const addOption = () => {
    setOptions([...options, ''])
  }
  const handleOptionChange = (e, i) => {
    const newOptions = [...options]
    newOptions[i] = e.target.value
    setOptions(newOptions)
  }
  const add = useFetch({
    action: toUpdate ? updateAttribute : addAttribute,
    onComplete: onAdded,
  })
  const submit = () => {
    if (!name || !type) {
      toastr.error('Please fill al fields')
      return
    }
    if (type?.value === 3 && (!options[0] || !options[1])) {
      toastr.error('You have to enter at least 2 options')
      return
    }
    const body = {
      name,
      required,
      type_id: type?.value,
    }
    if (type?.value === 3) {
      body.options = options
    }
    if (toUpdate) {
      body.attribute_id = toUpdate.id
    }
    add.startFetch(body)
    toggle()
  }
  return (
    <Modal
      scrollable
      centered
      isOpen={isOpen}
      toggle={toggle}
      role='dialog'
      className='exampleModal'
      tabindex='-1'
      unmountOnClose
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Add Custom Field</ModalHeader>
        <ModalBody>
          <Col className='p-3'>
            <FormGroup>
              <label>
                Type<span className='text-danger font-size-16 mx-1'>*</span>
              </label>
              <CustomSelect
                options={types?.map((t) => ({
                  label: t.name,
                  value: t.id,
                  icon: (
                    <i className={`mdi ${getIcon(t.code)} font-size-15 mr-2`} />
                  ),
                }))}
                onChange={setType}
                value={type}
              />
            </FormGroup>
            <FormGroup>
              <label>Name</label>
              <Input
                disabled={toUpdate?.used}
                className='form-control'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <Col>
              <Row>
                <Toggle
                  id='required'
                  check={required}
                  change={() => {
                    setRequired(!required)
                  }}
                />
                <label>Required</label>
              </Row>
            </Col>
            {type?.value === 3 && (
              <>
                {options?.map((o, i) => (
                  <Col key={`opt-${i}`}>
                    <Row className='align-items-center'>
                      <Col className='p-0 m-0'>
                        <FormGroup>
                          <label>Option: {i + 1}</label>
                          <Input
                            className='form-control'
                            name={`option+${i}`}
                            value={o}
                            onChange={(e) => handleOptionChange(e, i)}
                          />
                        </FormGroup>
                      </Col>
                      {i > 1 && (
                        <a
                          onClick={() => {
                            const newOptions = [...options]
                            newOptions.splice(i, 1)
                            setOptions(newOptions)
                          }}
                        >
                          <img
                            src={trach}
                            style={{ width: 24, marginLeft: 16 }}
                            alt=''
                          />
                        </a>
                      )}
                    </Row>
                  </Col>
                ))}
                <button className='btn btn-outline-primary' onClick={addOption}>
                  <i className='bx bx-plus' /> Add options
                </button>
              </>
            )}
          </Col>
        </ModalBody>
        <ModalFooter className={isMobile ? 'fixed-bottom' : ''}>
          <Button outline type='button' color='light' onClick={toggle}>
            Cancel
          </Button>
          <Button type='button' color='primary' onClick={submit}>
            Save
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default CustomFieldModal
