// import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig, getFormData } from './api'

/**
 * @param {*} data - { type: 'fixed' | 'payg' | 'milestone' }
 */
export function getContractImportTemplate(token, data, extra) {
  const type = data?.type

  return api.get(`contract/import/${type}/template`, getConfig(token, extra))
}

/**
 * @param {*} data - { template: File, contract_type: 'fixed' | 'payg' | 'milestone' }
 */
export function validateContractImportTemplate(token, data) {
  const { progressFuncs, ...restOfData } = data
  const formData = getFormData(restOfData)

  return api.post(
    `contract/import/validate`,
    formData,
    getConfig(
      token,
      { ...progressFuncs },
      { 'Content-Type': 'multipart/form-data' },
    ),
  )
}

export function processContractImport(token, data) {
  return api.post('contract/import/process', data, getConfig(token))
}

/**
 * @param {*} data - { task_id: number }
 */
export function sendContractImportInvites(token, data) {
  return api.get(`contract/import/${data?.task_id}/invite`, getConfig(token))
}
