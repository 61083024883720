import { useCallback, useState } from 'react'
import toastr from 'toastr'

import { useFetch } from '../../helpers/hooks'
import {
  approveTimeOff,
  cancelTimeOff,
  deleteTimeOff,
  rejectTimeOff,
} from '../../services/api'
import { track } from '../../utils/analytics'
import { TIMEOFF_EVENTS } from '../new-time-off-policy/events'

const defaultIds = {
  approvingId: null,
  decliningId: null,
  deletingId: null,
  cancelingId: null,
}

export function useTimeOffActions({ refreshTimeOff }) {
  const [actionIds, setActionsIds] = useState(defaultIds)

  function handleError({ type, message }) {
    let _message = ''

    switch (type) {
      case 'approve':
        _message = 'Error while approving time off'
        break
      case 'decline':
        _message = 'Error while declining time off'
        break
      case 'delete':
        _message = 'Error while deleting time off'
        break
      case 'cancel':
        _message = 'Error while canceling time off'
        break
      default:
        _message = 'Error while performing action'
        break
    }

    setActionsIds((prevIds) => ({ ...prevIds, ...defaultIds }))

    toastr.error(message || _message)
  }

  const { startFetch: cancel, isLoading: cancelingTimeOff } = useFetch({
    action: cancelTimeOff,
    onComplete: () => {
      setActionsIds((ids) => ({ ...ids, cancelingId: null }))
      refreshTimeOff()
    },
    onError: (error) => {
      handleError({ type: 'cancel', message: error })
    },
  })
  const handleCancelTimeOff = useCallback(
    (item) => {
      cancel({ time_off_id: item?.id })
      setActionsIds((ids) => ({ ...ids, cancelingId: item?.id }))
    },
    [cancel],
  )

  const { startFetch: removeTimeOff, isLoading: deletingTimeOff } = useFetch({
    action: deleteTimeOff,
    onComplete: () => {
      setActionsIds((ids) => ({ ...ids, deletingId: null }))
      refreshTimeOff()
    },
    onError: (error) => {
      handleError({ type: 'delete', message: error })
    },
  })
  const handleDeleteTimeOff = useCallback(
    (item) => {
      removeTimeOff({ time_off_id: item?.id })
      setActionsIds((ids) => ({ ...ids, deletingId: item?.id }))
    },
    [removeTimeOff],
  )

  const { startFetch: approve, isLoading: approvingTimeOff } = useFetch({
    action: approveTimeOff,
    onComplete: (_, body) => {
      setActionsIds((ids) => ({ ...ids, approvingId: null }))
      refreshTimeOff()
      track(TIMEOFF_EVENTS.APPROVE, { deducted: !!body.cycles })
    },
    onError: (error) => {
      handleError({ type: 'approve', message: error })
    },
  })
  const handleApproveTimeOff = useCallback(
    (item) => {
      const body = { time_off_id: item?.id }
      if (item?.cycles) {
        body.cycles = item.cycles
      }
      approve(body)

      setActionsIds((ids) => ({ ...ids, approvingId: item?.id }))
    },
    [approve],
  )

  const { startFetch: decline, isLoading: decliningTimeOff } = useFetch({
    action: rejectTimeOff,
    onComplete: () => {
      setActionsIds((ids) => ({ ...ids, decliningId: null }))
      refreshTimeOff()
    },
    onError: (error) => {
      handleError({ type: 'decline', message: error })
    },
  })
  const handleDeclineTimeOff = useCallback(
    (item) => {
      decline({ time_off_id: item?.id })
      setActionsIds((ids) => ({ ...ids, decliningId: item?.id }))
    },
    [decline],
  )

  return {
    handleApproveTimeOff,
    approvingTimeOff,
    handleDeclineTimeOff,
    decliningTimeOff,
    handleDeleteTimeOff,
    deletingTimeOff,
    handleCancelTimeOff,
    cancelingTimeOff,
    actionIds,
  }
}
