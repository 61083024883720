import { yupResolver } from '@hookform/resolvers/yup'
import { CalendarCheck } from '@phosphor-icons/react'
import {
  getDay,
  isPast,
  isSameDay,
  isSameYear,
  isWeekend,
  parse,
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import ControlledCurrencyInput from '../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledSelect from '../../components/ControlledSelect'
import { getInputErrorMessage } from '../../components/Forms/get-input-error-message'
import ControlledCheckbox from '../../components/controlled-checkbox'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PrimaryAlert } from '../../components/ui/alert'
import Button from '../../components/ui/button'
import CardButtons from '../../components/ui/card-button/card-buttons'
import InputFeedback from '../../components/ui/input-feedback'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import {
  CONTRACT_TYPES,
  MILESTONES,
  PAY_AS_YOU_GO,
} from '../../core/config/contract-types'
import {
  BE_CONTRACT_CATEGORY,
  CONTRACT_STATUS,
  userTypes,
} from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  addTimeOff,
  getContractList,
  getCycles,
  getFullTimeContractDetails,
  getTimeOffDays,
} from '../../services/api'
import { getContractTimeOff } from '../../services/api-time-off-policies'
import {
  DATE_PICKER_FORMAT_2,
  datePickerDateFormat,
  rpFormatDate,
} from '../../utils/formatters/date-picker-date-format'
import isNill from '../../utils/is-nill'
import { PERMISSION_GROUP } from '../CompanySetting/manage-role'
import LabelContent from '../Contract/CreateContract/components/label-content'
import { getExcludedDates } from './helpers'
import { track } from '../../utils/analytics'
import { TIMEOFF_EVENTS } from '../new-time-off-policy/events'

function parseDate(date) {
  return date ? parse(date, 'yyyy-MM-dd', new Date()) : null
}

function isImpossibleCase({ from, to, isHalfStartDate, isHalfEndDate }) {
  const isStartAndEndDateSame = isSameDay(new Date(from), new Date(to ?? ''))
  const bothHalfDays = isHalfStartDate && isHalfEndDate

  if (isStartAndEndDateSame && bothHalfDays) {
    return true
  } else {
    return false
  }
}

function useAddTimeOffActionProps({
  employeeContract,
  contractList,
  contract,
}) {
  const { hasAccess } = usePermissions()

  const isClient = useSelector(
    (state) => state.Account?.user?.type === userTypes.COMPANY,
  )

  const employeeContractOngoing =
    employeeContract?.status?.id === CONTRACT_STATUS.ONGOING.value

  const contractOptions =
    contractList?.filter(
      (contract) =>
        contract?.status?.id === CONTRACT_STATUS.ONGOING.value &&
        ![MILESTONES, PAY_AS_YOU_GO].includes(contract?.type),
    ) ?? []

  const hasOneOngoingContract =
    contractOptions?.length >= 1 ||
    contract?.status?.id === CONTRACT_STATUS.ONGOING.value

  return {
    isShowing: employeeContractOngoing || hasOneOngoingContract || !isClient,
    permissionArea: PERMISSION_GROUP.TIME_OFF.name,
    hasPermission: !isClient || hasAccess(permissions.addTimeOff),
    label: isClient ? 'Submit time off' : 'Request time off',
  }
}

function useAddTimeOffData({ isOpen }) {
  const isEmployee = useSelector(
    (state) =>
      state?.userProfile?.userProfile?.contractor_type ===
      BE_CONTRACT_CATEGORY.EMPLOYEE,
  )

  const { data: employeeContract } = useFetch(
    {
      action: getFullTimeContractDetails,
      autoFetch: isEmployee,
    },
    [isEmployee],
  )

  const { data: contractList } = useFetch(
    {
      action: getContractList,
      autoFetch: isOpen && !isEmployee,
      initResult: [],
    },
    [isOpen, isEmployee],
  )

  return { employeeContract, contractList }
}

const UnSupportedHalfDayToolTip = ({ target }) => (
  <UncontrolledTooltip target={target}>
    Half day is not supported for this policy
  </UncontrolledTooltip>
)

export function AddTimeOff({ contract, onSubmit, timeOffs, btnProps }) {
  const [isOpen, setIsOpen] = useState(false)

  const { employeeContract, contractList } = useAddTimeOffData({ isOpen })

  const addTimeOffProps = useAddTimeOffActionProps({
    employeeContract,
    contractList,
    contract,
  })
  const { isShowing, permissionArea, hasPermission, label } = addTimeOffProps

  return (
    <>
      {!isShowing ? null : (
        <PermissionTooltip
          area={permissionArea}
          showing={!hasPermission}
          id='time-off-add-button-tooltip'
        >
          <Button
            {...btnProps}
            disabled={!hasPermission}
            onClick={() => setIsOpen(true)}
          >
            {label}
          </Button>
        </PermissionTooltip>
      )}

      {!isOpen ? null : (
        <AddTimeOffSideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          timeOffs={timeOffs}
          contract={contract}
          onSubmit={onSubmit}
          employeeContract={employeeContract}
          contractList={contractList}
        />
      )}
    </>
  )
}

export function AddTimeOffWithData({
  isOpen,
  setIsOpen,
  contract,
  onSubmit,
  timeOffs,
  policy,
}) {
  const { employeeContract, contractList } = useAddTimeOffData({ isOpen })

  if (!isOpen) return null

  return (
    <AddTimeOffSideMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      timeOffs={timeOffs}
      contract={contract}
      onSubmit={onSubmit}
      employeeContract={employeeContract}
      contractList={contractList}
      policy={policy}
    />
  )
}

function AddTimeOffSideMenu({
  isOpen,
  setIsOpen,
  onSubmit,
  contract,
  timeOffs,
  employeeContract,
  contractList,
  policy,
}) {
  const user = useSelector((state) => state.Account?.user)
  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const isClient = user?.type === userTypes.COMPANY

  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const isFteContract = contract?.type === CONTRACT_TYPES.FULL_TIME

  const isEmployeeType =
    isEmployee || isDeEmployee
      ? true
      : isClient
        ? isDeContract || isFteContract
        : false

  const shouldHideContractsSelect = isEmployeeType || contract?.id

  // #region START: Handle employee contract
  const userId = useSelector((state) => state?.userProfile?.userProfile?.id)

  const employeeContractId = employeeContract?.id
  const employeeContractOngoing =
    employeeContract?.status?.id === CONTRACT_STATUS.ONGOING.value
  const employeeContractEmployeeId = employeeContract?.employee?.id
  const employeeMatches = employeeContractEmployeeId === userId
  // #endregion END: Handle employee contract

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      contract_id: employeeContractId || contract?.id,
      policy_id: policy?.policy?.id ?? '',
      from: '',
      to: '',
      is_half_start_date: false,
      is_half_end_date: false,
      deduct: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        policy_id: yup
          .string()
          .typeError('Please select a type')
          .required('Please select a type'),
        from: yup
          .string()
          .typeError('The start date is required')
          .required('The start date is required'),
        to: yup
          .string()
          .typeError('The end date is required')
          .required('The end date is required'),
        contract_id: yup.number().when({
          is: () => !shouldHideContractsSelect,
          then: (schema) => schema.required('Please select a contract'),
        }),
        cycles: yup.array().when({
          is: () => isDeduct && cyclesData?.cycles?.length > 0,
          then: (schema) =>
            schema
              .of(
                yup
                  .number()
                  .typeError('Please enter a valid amount')
                  .required('Please enter a valid amount')
                  .min(1, 'Please enter a positive amount'),
              )
              .required('Please enter a valid amount'),
        }),
      }),
    ),
  })

  const contractOptions =
    contractList
      ?.filter(
        (contract) =>
          contract?.status?.id === CONTRACT_STATUS.ONGOING.value &&
          ![MILESTONES, PAY_AS_YOU_GO].includes(contract?.type),
      )
      ?.map((contract) => {
        return {
          value: contract?.id,
          label: `#${contract?.ref} (${contract?.name})`,
        }
      }) ?? []

  const {
    from,
    to,
    is_half_start_date: isHalfStartDate,
    is_half_end_date: isHalfEndDate,
    deduct: isDeduct,
    contract_id: selectedContractId,
    policy_id: selectedPolicyId,
  } = useWatch({ control })

  const contractId =
    contract?.id ??
    ((isEmployeeType || isDeEmployee) && contractList?.length > 0
      ? contractList[0].id
      : selectedContractId)

  const { data: policies, isLoading: gettingPolicies } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: contractId !== undefined,
    },
    [contractId],
  )

  const policyOptions = policies?.map((policy) => {
    return {
      value: policy?.policy?.id,
      label: policy?.policy?.name,
      description: `${policy.type.name} | ${
        policy.type.is_accrued ? 'Accrual' : 'Non-accrual'
      }`,
    }
  })

  function onSubmitRequest(data) {
    const body = { ...data }

    // #region START: Handle contract_id
    const isThisEmployee = employeeMatches && employeeContractOngoing
    if (isThisEmployee) {
      body.contract_id = employeeContractId
    }

    if (isDeEmployee) {
      body.contract_id = contractList?.[0]?.id
    }

    if (contract?.id) {
      body.contract_id = contract?.id
    }
    // #endregion END: Handle employee case

    if (data?.cycles?.length <= 0 || data?.deduct === false) {
      delete body.cycles
    } else if (
      data?.cycles &&
      data?.cycles?.length === cyclesData?.cycles.length
    ) {
      body.cycles = data?.cycles?.map((cycle, index) => {
        return {
          amount: cycle,
          id: cyclesData?.cycles[index]?.id,
        }
      })
    }

    submitTimeOffRequest(body)
  }

  function toggle() {
    setIsOpen((prev) => {
      if (prev) {
        reset?.()
      }

      return !prev
    })
  }

  const { startFetch: submitTimeOffRequest, isLoading } = useFetch({
    action: addTimeOff,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
        return
      }
      onSubmit?.()
      reset?.()
      toggle?.()
      track(TIMEOFF_EVENTS.REQUESTED, {
        type: policy.type,
        is_week_day: !isWeekend(new Date()),
      })
    },
    onError: (error) => {
      toastr.error(error || 'Something went wrong')
    },
  })

  const { data: timeOffDays } = useFetch(
    {
      action: getTimeOffDays,
      autoFetch: from && to && contractId,
      body: {
        contract_id: contractId,
        from,
        to,
        is_half_start_date: isHalfStartDate ? 1 : 0,
        is_half_end_date: isHalfEndDate ? 1 : 0,
        policy_id: selectedPolicyId,
      },
    },
    [contractId, from, to, isHalfStartDate, isHalfEndDate, selectedPolicyId],
  )

  const formattedDays = isNill(timeOffDays?.days)
    ? null
    : timeOffDays.days + ' day' + (timeOffDays.days === 1 ? '' : 's')

  const excludedDates = getExcludedDates(timeOffs)

  const { data: cyclesData, isLoading: gettingCyclesData } = useFetch(
    {
      action: getCycles,
      initResult: [],
      autoFetch: isClient && isDeduct && from && to,
      body: {
        contract_id: contractId,
        from,
        to,
        is_half_start_date: isHalfStartDate ? 1 : 0,
        is_half_end_date: isHalfEndDate ? 1 : 0,
        policy_id: selectedPolicyId,
      },
    },
    [
      contractId,
      from,
      to,
      isDeduct,
      isClient,
      isHalfStartDate,
      isHalfEndDate,
      selectedPolicyId,
    ],
  )

  const currencySymbol = contract
    ? contract?.currency?.symbol
    : selectedContractId
      ? contractList?.find((contract) => contract?.id === selectedContractId)
          ?.currency?.symbol
      : null

  const isStartAndEndDateSame = isSameDay(new Date(from), new Date(to))

  function handleImpossibleDateCase(newValue, { key } = {}) {
    const body = {
      from,
      to,
      isHalfStartDate,
      isHalfEndDate,
    }

    const prevValue = body[key]

    const isImpossible = isImpossibleCase({
      ...body,
      [key]: datePickerDateFormat(newValue),
    })

    if (isImpossible) {
      toastr.error(
        'When half day is enabled for start and end date, the dates should be different.<br/>Otherwise, please disable one half day option.',
      )
      return prevValue ?? datePickerDateFormat('')
    } else {
      return datePickerDateFormat(newValue)
    }
  }

  function handleImpossibleHalfCase(newVal, { key } = {}) {
    const body = {
      from,
      to,
      isHalfStartDate,
      isHalfEndDate,
    }

    const prevValue = body[key]

    const isImpossible = isImpossibleCase({ ...body, [key]: newVal })

    if (isImpossible) {
      toastr.error('Impossible')
      return prevValue ?? ''
    } else {
      return newVal
    }
  }

  const selectedPolicy = policies?.find(
    (policy) => policy?.policy?.id === selectedPolicyId,
  )

  const isHalfDaySupported = selectedPolicy?.policy?.can_request_half_day
  const isStartHalfDayDisabled =
    (isStartAndEndDateSame && isHalfEndDate) || !isHalfDaySupported
  const isEndHalfDayDisabled =
    (isStartAndEndDateSame && isHalfStartDate) || !isHalfDaySupported

  function isWorkDay(day) {
    const selectedPolicyWorkingDays = selectedPolicy?.policy?.working_days

    const theDay = getDay(day)

    if (theDay === 0 && selectedPolicyWorkingDays?.includes(7)) {
      return true
    }
    return selectedPolicyWorkingDays?.includes(theDay)
  }

  const isRetrospectiveEnabled =
    selectedPolicy?.policy?.is_retrospective_enabled === 1

  function getAllowedDays(day) {
    return !isPast(day)
      ? isWorkDay(day)
      : isWorkDay(day) && isRetrospectiveEnabled
  }

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      className='!tw-w-full tw-max-w-[480px] tw-text-black'
      itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr_auto]'
    >
      <SideMenuHeader toggle={toggle}>
        {isClient ? 'Submit time off' : 'Request time off'}
      </SideMenuHeader>

      <SideMenuBody>
        <form id='time-off-form' onSubmit={handleSubmit(onSubmitRequest)}>
          <div className='d-flex flex-column gap-16'>
            {shouldHideContractsSelect ? null : (
              <ControlledSelect
                control={control}
                name='contract_id'
                error={errors?.contract_id?.message}
                label='Contract'
                options={contractOptions}
              />
            )}

            <ControlledSelect
              control={control}
              name='policy_id'
              label='Select a policy'
              options={policyOptions}
              isDisabled={gettingPolicies}
              isLoading={gettingPolicies}
            />

            <div>
              <ControlledDatePicker
                control={control}
                name='from'
                error={errors?.from?.message}
                label='Start date'
                dateFormat={DATE_PICKER_FORMAT_2}
                excludeDates={excludedDates}
                minDate={isClient || isRetrospectiveEnabled ? null : new Date()}
                maxDate={parseDate(to)}
                placeholderText='Start date'
                wrapperClassName='flex-grow-1'
                className={!from ? null : '!tw-rounded-b-none'}
                clearable
                selectsStart
                startDate={parseDate(from)}
                endDate={parseDate(to)}
                showError={false}
                transform={{
                  output: (newVal) =>
                    handleImpossibleDateCase(newVal, { key: 'from' }),
                }}
                filterDate={getAllowedDays}
              />

              {!from ? null : (
                <div className='bg-surface-10 rounded-bottom border border-top-0 border-gray-b d-flex justify-content-between align-items-center p-2.5'>
                  {!isHalfDaySupported && (
                    <UnSupportedHalfDayToolTip target='halfStartDate' />
                  )}
                  <span id='halfStartDate'>
                    <ControlledCheckbox
                      control={control}
                      label='Make start day half day'
                      name='is_half_start_date'
                      id='is_half_start_date'
                      disabled={isStartHalfDayDisabled}
                      transform={{
                        output: (newVal) =>
                          handleImpossibleHalfCase(newVal, {
                            key: 'isHalfStartDate',
                          }),
                      }}
                    />
                  </span>
                </div>
              )}

              {!errors?.from?.message ? null : (
                <InputFeedback className='tw-mt-1'>
                  {getInputErrorMessage(errors?.from?.message)}
                </InputFeedback>
              )}
            </div>

            <div>
              <ControlledDatePicker
                control={control}
                name='to'
                error={errors?.to?.message}
                label='End date'
                dateFormat={DATE_PICKER_FORMAT_2}
                excludeDates={excludedDates}
                minDate={parseDate(from)}
                placeholderText='End date'
                wrapperClassName='flex-grow-1'
                className={!to ? null : '!tw-rounded-b-none'}
                clearable
                selectsEnd
                startDate={parseDate(from)}
                endDate={parseDate(to)}
                showError={false}
                transform={{
                  output: (newVal) =>
                    handleImpossibleDateCase(newVal, { key: 'to' }),
                }}
                filterDate={getAllowedDays}
              />

              {!to ? null : (
                <div className='bg-surface-10 rounded-bottom border border-top-0 border-gray-b d-flex justify-content-between align-items-center p-2.5'>
                  {!isHalfDaySupported && (
                    <UnSupportedHalfDayToolTip target='halfEndDate' />
                  )}
                  <span id='halfEndDate'>
                    <ControlledCheckbox
                      control={control}
                      label='Make end day half day'
                      name='is_half_end_date'
                      id='is_half_end_date'
                      disabled={isEndHalfDayDisabled}
                      transform={{
                        output: (newVal) =>
                          handleImpossibleHalfCase(newVal, {
                            key: 'isHalfEndDate',
                          }),
                      }}
                    />
                  </span>
                </div>
              )}

              {!errors?.to?.message ? null : (
                <InputFeedback className='tw-mt-1'>
                  {getInputErrorMessage(errors?.to?.message)}
                </InputFeedback>
              )}
            </div>

            {!formattedDays ? null : (
              <PrimaryAlert
                customIcon={
                  <CalendarCheck
                    size={24}
                    weight='duotone'
                    color='var(--primary)'
                  />
                }
                className='tw-items-center'
                innerTag='div'
                innerClassName='tw-w-full tw-flex tw-justify-between tw-gap-2 tw-text-sm'
              >
                <p className='tw-mb-0'>Selected duration</p>
                <span className='tw-text-primary-100'>{formattedDays}</span>
              </PrimaryAlert>
            )}

            {!isClient ? null : (
              <>
                <div
                  className={cn(
                    'tw-flex tw-items-start tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6',
                    { 'tw-rounded-bl tw-rounded-br': !isDeduct },
                  )}
                >
                  <div className='tw-flex tw-gap-4'>
                    <div>
                      <div className='font-size-14 tw-font-medium'>
                        Deduct time off amount
                      </div>
                      <div className='font-size-14 tw-text-text-60'>
                        The deducted time off amount will be deducted from the
                        relative cycle
                      </div>
                    </div>
                  </div>
                  <div>
                    <ControlledCheckbox
                      control={control}
                      name='deduct'
                      id='deduct'
                      className='tw-cursor-pointer'
                    />
                  </div>
                </div>

                {!isDeduct ? null : (
                  <CyclesFields
                    control={control}
                    setValue={setValue}
                    name='cycles'
                    errorsArray={errors?.cycles}
                    cycles={cyclesData?.cycles}
                    gettingCyclesData={gettingCyclesData}
                    contract={contract}
                    currencySymbol={currencySymbol}
                  />
                )}
              </>
            )}
          </div>
        </form>
      </SideMenuBody>

      <SideMenuFooter>
        <Button onClick={toggle} color='light' outline disabled={isLoading}>
          Cancel
        </Button>
        <Button disabled={isLoading} loading={isLoading} formId='time-off-form'>
          Submit
        </Button>
      </SideMenuFooter>
    </SideMenu>
  )
}

export function CyclesFields({
  control,
  name,
  cycles,
  gettingCyclesData,
  contract,
  currencySymbol,
  errorsArray,
  setValue,
}) {
  const classes =
    'tw-mb-0 tw-rounded tw-border tw-border-surface-30 tw-p-4 tw-text-text-100'

  if (gettingCyclesData) {
    return <p className={classes}>Loading cycles...</p>
  }

  const isEmpty = !cycles || cycles?.length <= 0
  if (isEmpty) {
    return <p className={classes}>No unpaid cycles found</p>
  }

  return cycles.map((cycle, index) => {
    return (
      <CycleItem
        key={cycle.id}
        cycle={cycle}
        errorsArray={errorsArray}
        index={index}
        control={control}
        setValue={setValue}
        name={name}
        contract={contract}
        currencySymbol={currencySymbol}
      />
    )
  })
}

function numberToOrdinal(number) {
  const suffix = ['th', 'st', 'nd', 'rd']
  const value = number % 100
  return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0])
}

const DAYS_TYPE = {
  WORKING: { value: 'WORKING' },
  CALENDAR: { value: 'CALENDAR' },
}
function CycleItem({
  cycle,
  errorsArray,
  index,
  control,
  setValue,
  name,
  contract,
  currencySymbol,
}) {
  const [daysType, setDaysType] = useState(DAYS_TYPE.WORKING.value)

  function formatDate(date) {
    const sameYear = isSameYear(new Date(date), new Date())

    const formatString = 'MMM dd' + (sameYear ? '' : ' yyyy')

    return rpFormatDate(date, 'yyyy-MM-dd', formatString)
  }

  const label = `${formatDate(cycle?.from)} - ${formatDate(cycle?.to)}`

  const labelPrefix = numberToOrdinal(index + 1)
  const amountName = `${name}.${index}`
  const error = errorsArray?.[index]?.message

  const daysTypeOptions = [
    {
      label: `${cycle?.working_days_number ?? '-'} working days`,
      value: DAYS_TYPE.WORKING.value,
    },
    {
      label: `${cycle?.calendar_days_number ?? '-'} calendar days`,
      value: DAYS_TYPE.CALENDAR.value,
    },
  ]

  const defaultAmount = {
    [DAYS_TYPE.WORKING.value]: cycle?.working_days_amount,
    [DAYS_TYPE.CALENDAR.value]: cycle?.calendar_days_amount,
  }

  useEffect(() => {
    setValue(amountName, Number(defaultAmount[daysType]).toFixed(2))
    // Whenever the amounts change we want to update the value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycle?.working_days_amount, cycle?.calendar_days_amount])

  return (
    <div className='border border-gray-200 rounded p-3'>
      <div className='d-flex justify-content-between gap-4 font-size-14'>
        <div>{labelPrefix} Payment cycle</div>
        <div className='rp-font-bold'>{label}</div>
      </div>

      <hr />

      <div className='pb-3'>
        <CardButtons
          value={daysType}
          onChange={(val) => {
            setValue(amountName, Number(defaultAmount[val?.value]).toFixed(2))
            setDaysType(val?.value)
          }}
          options={daysTypeOptions}
          className='d-flex gap-16'
          buttonNoPadding
          buttonClassName='p-3'
        />
      </div>

      <Label htmlFor={amountName}>
        <LabelContent
          required
          tooltipId={`${name}-${index}_tooltip`}
          tooltipText='The amount is automatically calculated based on the duration and the respective cycle for each amount of days'
        >
          Amount
        </LabelContent>
      </Label>
      <InputGroup>
        <ControlledCurrencyInput
          control={control}
          name={amountName}
          id={amountName}
          containerClassName='flex-grow-1'
          className='rounded-right-0 text-secondary-100'
          error={error}
          hideError
          defaultValue={defaultAmount[daysType]}
        />

        {!currencySymbol ? null : (
          <InputGroupAddon addonType='append'>
            <InputGroupText>{contract?.currency?.code}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}
