import React from 'react'

import { useFetch } from '../../helpers/hooks'
import { getBanners } from '../../services/api'
import GenericBanner from './generic-banner'

export function ClientBanners() {
  const { data: bannerData } = useFetch({
    action: getBanners,
    autoFetch: true,
  })

  return <GenericBanner bannerData={bannerData} />
}
