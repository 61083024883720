'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@headlessui/react';
import { DotsThreeOutlineVertical, Spinner } from '@phosphor-icons/react';
import { cn } from '../lib/utils/cn';
export function ActionsDropdown({ className, wrapperClassName, loading, data, position = 'right', }) {
    var _a;
    const purifiedData = (_a = data === null || data === void 0 ? void 0 : data.filter(Boolean)) !== null && _a !== void 0 ? _a : [];
    const positionMargin = {
        left: '',
        right: 'tw-translate-x-[calc(-100%+var(--trigger-button-width))]',
        top: 'tw-translate-y-[calc(-150%+var(--trigger-button-width))] tw-translate-x-[calc(-115%+var(--trigger-button-width))]',
    };
    return (_jsx(Menu, { children: _jsxs("div", { style: { '--trigger-button-width': '2rem' }, className: cn('tw-relative tw-flex tw-flex-col', wrapperClassName), children: [_jsx(Menu.Button, { className: cn(`tw-flex tw-h-8 tw-w-[var(--trigger-button-width)] tw-items-center tw-justify-center tw-rounded tw-bg-secondary-10 tw-p-1 tw-text-black tw-transition-colors tw-duration-75 hover:!tw-bg-surface-30`, className), type: 'button', children: loading ? (_jsx(Spinner, { className: 'tw-animate-spin tw-text-sm' })) : (_jsx(DotsThreeOutlineVertical, { size: 18, weight: 'fill' })) }), _jsx(Menu.Items, { children: _jsx("div", { className: cn('tw-absolute tw-z-10 tw-mt-1 tw-flex tw-min-w-[230px] tw-animate-dropdown tw-flex-col tw-whitespace-pre-wrap tw-rounded tw-border tw-border-solid tw-border-surface-130 tw-bg-white tw-py-2 tw-shadow-xl', positionMargin[position]), children: purifiedData.map((item) => {
                            return (_jsx(Menu.Item, { children: _jsxs("button", { className: cn('tw-flex tw-items-center tw-gap-x-2 tw-p-4 tw-text-start tw-text-sm tw-text-black [&:not(:disabled)]:hover:tw-bg-secondary-20', item.className), onClick: item.onClick, disabled: item.disabled, type: 'button', children: [!item.icon ? null : (_jsx("span", { className: 'tw-min-w-[0.5rem]', children: item.icon })), _jsx("span", { children: item.label })] }) }, item.label));
                        }) }) })] }) }));
}
