import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import { SubmittedByOn } from '.'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import Button from '../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveWork,
  deleteWork,
  downloadWork,
  getApprovalFlowTimeline,
} from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'
import DeclineModal from '../components/DeclineModal'
import { Timeline } from './payment-approvals'
import { WorkAttributes } from './submitted-work'

export function SubmittedWorkActions({
  work,
  contract,
  isAdmin,
  updateContract,
}) {
  const [actionOverridden, setActionOverridden] = useState(false)
  const [menuState, setMenuState] = useState({ show: false, data: null })
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null)

  function toggle({ data } = {}) {
    setMenuState((prevState) => {
      return { show: !prevState?.show, data: data ?? prevState?.data }
    })
  }

  const [declineModal, setDeclineModal] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isClient = user?.type === userTypes.COMPANY

  const { hasAccess } = usePermissions()

  const declineWork = () => {
    setDeclineModal(true)
  }

  const { startFetch: approveContractorWork, isLoading: approvingWork } =
    useFetch({
      action: approveWork,
      onComplete: () => {
        window.analytics.track('Approved work', {
          'contract-id': contract.ref,
          'contract-type': contract.type,
          'work-id': work?.id,
          'work-name': work?.name,
          'work-value': work?.qty,
          currency: contract.currency?.code,
        })
        updateContract?.()
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const handleApproveWork = () => {
    approveContractorWork({ work_id: work.id })
  }
  const handleDownloadWorkClick = (e) => {
    download.startFetch({ work_id: e?.id })
  }
  const download = useFetch({
    action: downloadWork,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `work-${work?.id}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
  })

  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      setDeleteConfirmModal(null)
      updateContract()

      window.analytics.track('Deleted work', {
        contract_id: contract?.id,
        contract_type: contract?.type,
        currency: contract?.currency?.code,
      })
    },
  })
  const handleDeleteWork = () => {
    deleteLine.startFetch({
      work_id: deleteConfirmModal?.id,
    })
  }

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: menuState?.data?.id, type: 'work' },
      autoFetch: !!menuState?.data?.id,
    },
    [menuState?.data?.id],
  )

  const firstPending = flowTimeline?.find((item) => item.status === 'Pending')
  const hasApprovalFlow = !!contract?.approval_flow?.steps?.length

  const actionable =
    work.status?.id === 2 &&
    user?.type === 'client' &&
    !isAdmin &&
    (firstPending?.user_id === user.id ||
      !!userProfile?.is_company_creator ||
      actionOverridden ||
      !hasApprovalFlow)

  return (
    <>
      <Button
        size='sm'
        color='link'
        className='!tw-px-0'
        onClick={() => toggle({ data: work })}
      >
        Details
      </Button>

      <SideMenu
        onClose={toggle}
        isOpen={menuState?.show}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName={cx(
          'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
          actionable
            ? 'tw-grid-rows-[auto_1fr_91px]'
            : 'tw-grid-rows-[auto_1fr]',
        )}
      >
        <SideMenuHeader toggle={toggle}>Work details</SideMenuHeader>
        <SideMenuBody>
          <div>
            <div>
              {work.name} - {work.details}
            </div>

            <SubmittedByOn
              by={getFullName(work.created_by)}
              on={work.submitted_at}
              className='tw-mt-4 tw-block !tw-text-sm'
            />

            <WorkAttributes
              attributes={work?.attributes}
              className='tw-mt-4 !tw-text-sm'
            />
          </div>

          <Timeline
            flowTimeline={flowTimeline}
            loading={loadingFlowTimeline}
            loadingLength={contract?.approval_flow?.steps?.length}
            isAdmin={isAdmin}
            actionOverridden={actionOverridden}
            setActionOverridden={setActionOverridden}
            contract={contract}
          />

          {!work?.file ? null : (
            <Button
              type='button'
              size='sm'
              className='tw-mt-6'
              outline
              onClick={() => handleDownloadWorkClick(work)}
              disabled={download.loading}
              loading={download.loading}
            >
              Download work attachment
            </Button>
          )}
        </SideMenuBody>
        {!actionable ? null : (
          <SideMenuFooter>
            {!work.can_delete ||
            (isClient && !hasAccess(permissions.DeleteWorks)) ? null : (
              <Button
                color='link'
                type='button'
                onClick={() => setDeleteConfirmModal(work)}
                className='tw-mr-auto !tw-text-text-100'
                disabled={approvingWork}
              >
                Delete
              </Button>
            )}
            {!isClient ? null : work.created_by?.type === userTypes.COMPANY ? (
              <Button
                type='button'
                onClick={handleApproveWork}
                disabled={
                  !(
                    hasAccess(permissions.ApproveWorks) || contract?.can_approve
                  ) || approvingWork
                }
              >
                Mark as approved
              </Button>
            ) : (
              <>
                <Button
                  color='danger'
                  type='button'
                  onClick={declineWork}
                  disabled={
                    !(
                      hasAccess(permissions.DeclineWorks) ||
                      contract?.can_approve
                    ) || approvingWork
                  }
                >
                  Decline
                </Button>
                <Button
                  color='success'
                  type='button'
                  onClick={handleApproveWork}
                  disabled={
                    !(
                      hasAccess(permissions.ApproveWorks) ||
                      contract?.can_approve
                    ) || approvingWork
                  }
                  loading={approvingWork}
                >
                  Approve
                </Button>
              </>
            )}
          </SideMenuFooter>
        )}
      </SideMenu>

      <DeclineModal
        data={contract}
        isOpen={declineModal}
        e={work}
        onDeclined={() => {
          updateContract()
        }}
        toggle={() => setDeclineModal(false)}
      />

      {deleteConfirmModal && (
        <ConfirmationModal
          confirmLoading={deleteLine.isLoading}
          content='Are you sure you want to delete this work?'
          title='Work Deletion'
          isOpen={deleteConfirmModal !== null}
          onConfirm={handleDeleteWork}
          withCancel
          toggle={() => setDeleteConfirmModal(null)}
          caption='Delete work'
          buttonColor='danger'
        />
      )}
    </>
  )
}
