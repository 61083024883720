import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Badge, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'

import { HeaderLogo } from '../../components/Common/header-logo'
import FormWizard from '../../components/Forms/FormWizard'
import { useContractorType } from '../../components/Forms/UserTypeSelect'
import Steps from '../../components/Steps'
import { StyledH5 } from '../../components/Typo'
import Loader from '../../components/ui/loader'
import { checkRegisterToken } from '../../services/api'
import { apiError } from '../../store/actions'

const Register = (props) => {
  const searchParams = new URLSearchParams(props.location.search)
  const { isDeEmployee } = useContractorType()
  const typeP = searchParams.get('type')
  const [activeTab, setActiveTab] = useState(1)
  const [steps, setSteps] = useState([])
  const [userType, setUserType] = useState(
    isDeEmployee
      ? 1
      : typeP
        ? typeP === 'employee'
          ? 2
          : typeP === 'client'
            ? 0
            : 1
        : 0,
  )
  const [loading, setLoading] = useState(false)
  const tokenP = new URLSearchParams(props.location.search).get('token')
  const history = useHistory()

  useEffect(() => {
    if (tokenP) {
      setLoading(true)
      checkRegisterToken(tokenP)
        .then((r) => {
          if (r.data?.success) {
            if (!r?.data?.data.is_valid) {
              history.push('/activity')
            }
            setLoading(false)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  useEffect(() => {
    if (tokenP) {
      setSteps([
        isDeEmployee ? 'Email' : 'Account type',
        '',
        'Your Information',
      ])
    } else if (userType === 0) {
      setSteps(['Account type', 'Your Information', 'Company Info'])
    } else {
      setSteps(['Account type', 'Email', 'Info'])
    }
  }, [userType, isDeEmployee, tokenP])

  return loading ? (
    <Loader minHeight='80vh' />
  ) : (
    <div
      className={`account-pages ${
        tokenP && !isDeEmployee ? 'blur-image' : 'blur-container'
      }`}
      style={{ minHeight: '100vh' }}
    >
      <StyledDiv className='d-flex justify-content-between align-items-center fixed-top'>
        <div className='p-3 flex-shrink-0'>
          <HeaderLogo />
        </div>
        <Col md={10} lg={8} className='d-none ml-md-4 d-md-block flex-shrink-1'>
          <Steps activeTab={activeTab - 1} data={steps} userType={userType} />
        </Col>
        <Col
          xs={2}
          className='d-block d-md-none d-lg-block p-0 m-0 justify-content-end d-flex px-3'
        >
          <Badge
            className='d-lg-none font-size-12 rounded bg-soft-primary'
            color='white'
            pill
          >
            <p
              style={{ padding: '3px 2px' }}
              className='font-size-12 mb-0 text-primary'
            >
              {activeTab} / {steps?.length}
            </p>
          </Badge>
        </Col>
      </StyledDiv>

      <StyledContainer fluid>
        {activeTab === 0 && (
          <Row className='justify-content-center mt-3 mt-md-4 mb-4 p-0 m-0'>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              Account type
            </StyledH5>
          </Row>
        )}
        {activeTab === 1 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5
              min='22px'
              max='32px'
              className='text-center text-text-black mb-3'
            >
              Tell us what describes you best
            </StyledH5>
          </Row>
        )}
        {activeTab === 2 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5
              min='22px'
              max='32px'
              className='text-center text-text-black mb-3'
            >
              {userType === 1 ? 'Verify your email' : 'General info'}
            </StyledH5>
          </Row>
        )}
        {activeTab === 3 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5
              min='22px'
              max='32px'
              className='text-center text-text-black mb-3'
            >
              {tokenP
                ? steps[activeTab - 1]
                : userType === 1
                  ? 'Enter your info'
                  : 'Company info'}
            </StyledH5>
          </Row>
        )}

        <Row className='justify-content-center p-0 m-0'>
          <Col className='p-0 m-0'>
            <FormWizard
              returnTabValue={(value) => {
                if (userType === 0) {
                  if (value > 2) {
                    setActiveTab(!tokenP ? value - 1 : value)
                  } else {
                    setActiveTab(1)
                  }
                } else {
                  setActiveTab(value)
                }
              }}
              userType={userType}
              setUserType={setUserType}
            />
          </Col>
        </Row>
      </StyledContainer>
    </div>
  )
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

const StyledContainer = styled(Container)`
  padding-top: 80px !important;

  @media (min-width: 992px) {
    padding-top: 100px !important;
  }
`

const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow:
    0px 0px 8px #eaeaea,
    inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`

export default connect(mapStatetoProps, { apiError })(Register)
