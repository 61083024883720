import { ArrowRight, Plus, PlusCircle, Spinner, X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import toastr from 'toastr'
import { cn } from 'ui'
import { deleteContractTag } from '../../../services/api'

const TYPE = { DEFAULT: 0, CREATE: 1, LIST: 2 }

const Wrapper = ({ children, className }) => (
  <div
    className={cn(
      'tw-left-auto tw-right-0 !tw-mt-2 tw-flex tw-gap-2 tw-rounded tw-bg-white tw-px-4 tw-pb-2',
      className,
    )}
  >
    {children}
  </div>
)

const TagsDropdown = ({
  isOpen,
  toggle,
  options = [],
  elementTags,
  contract,
  handleDelete,
  addTagData,
  creatingTag,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [tagValue, setTagValue] = useState(null)
  const [type, setType] = useState(TYPE.DEFAULT)

  const deleteTag = (data) => {
    deleteContractTag(user?.token, {
      contract_id: contract.id,
      tag_id: data.id,
    })
      .then((r) => {
        if (r.data.success) {
          handleDelete(data)
        }
      })
      .catch((e) => {
        toastr.error(e)
      })
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        tag='div'
        className='d-inline-flex cursor-pointer'
        onClick={(event) => {
          if (event.target.id === 'tag') {
            setType(TYPE.DEFAULT)
          }
        }}
      >
        {elementTags?.length === 0 ? (
          <button
            className='tw-flex tw-items-center tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold'
            type='button'
            onClick={() => setType(TYPE.LIST)}
          >
            ADD TAG
            <PlusCircle weight='fill' size='12px' className='tw-ml-0.5' />
          </button>
        ) : (
          <div className='tw-flex'>
            <span
              className='tw-flex tw-items-center tw-rounded-[32px] tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold tw-uppercase'
              style={{ backgroundColor: elementTags[0].color }}
              id='tag'
            >
              {elementTags[0].name}
              {isOpen && (
                <X
                  size={12}
                  className='tw-ml-1 tw-text-secondary'
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteTag(elementTags[0])
                  }}
                />
              )}
            </span>

            <button
              className='tw-ml-2 tw-flex tw-cursor-default tw-items-center tw-justify-center tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold'
              type='button'
              onClick={() => setType(TYPE.LIST)}
              id='plusWrapper'
            >
              {elementTags?.length === 1 ? (
                <Plus id='plus' />
              ) : (
                <span className='tw-text-black' id='plusOne'>
                  + {elementTags?.length - 1}
                </span>
              )}
            </button>
          </div>
        )}
      </DropdownToggle>

      {elementTags.length > 1 && type === TYPE.DEFAULT ? (
        <DropdownMenu flip={false}>
          <Wrapper>
            {[...elementTags.slice(1)].map((tag) => (
              <span
                key={tag.id}
                className='tw-flex tw-w-fit tw-items-center tw-rounded-[32px] tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold tw-uppercase'
                style={{ backgroundColor: tag.color }}
              >
                {tag.name}
                <X
                  size={12}
                  className='tw-ml-1 tw-text-secondary'
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteTag(tag)
                  }}
                />
              </span>
            ))}

            <button
              className='tw-ml-2 tw-flex tw-cursor-default tw-items-center tw-justify-center tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold'
              type='button'
              onClick={() => setType(TYPE.LIST)}
            >
              <Plus />
            </button>
          </Wrapper>
        </DropdownMenu>
      ) : type === TYPE.LIST || type === TYPE.CREATE ? (
        <DropdownMenu>
          <Wrapper className='tw-flex-col'>
            {options.map((e, i) => {
              const Component = e.component
              return (
                <DropdownItem
                  key={`drop-${i}`}
                  onClick={e.onClick}
                  className='!tw-px-0 !tw-py-2'
                >
                  <Component />
                </DropdownItem>
              )
            })}
            {type !== TYPE.CREATE ? (
              <button
                className='tw-mr-2 tw-flex tw-w-fit tw-items-center tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold tw-text-secondary-90'
                type='button'
                onClick={() => setType(TYPE.CREATE)}
              >
                CREATE NEW TAG <Plus size={12} />
              </button>
            ) : (
              <span className='tw-flex tw-rounded tw-border tw-border-secondary-30 tw-p-4'>
                <input
                  placeholder='Create new tag'
                  className='tw-text-sm tw-font-medium tw-outline-none placeholder:tw-text-text-60'
                  type='text'
                  id='tag_input'
                  maxLength='17'
                  onChange={(event) => {
                    setTagValue(event.target.value)
                  }}
                />
                <button
                  className={`${tagValue ? 'tw-opacity-100' : 'tw-opacity-0'}`}
                  disabled={!tagValue}
                  onClick={() => addTagData(tagValue)}
                >
                  {!creatingTag ? (
                    <ArrowRight size={24} />
                  ) : (
                    <Spinner
                      className='tw-animate-spin tw-transition-transform tw-duration-300'
                      size={24}
                    />
                  )}
                </button>
              </span>
            )}
          </Wrapper>
        </DropdownMenu>
      ) : null}
    </Dropdown>
  )
}

export default TagsDropdown
