import cx from 'classnames'
import React, { useState } from 'react'
import { Table } from 'reactstrap'

import isNill from '../../utils/is-nill'
import ExpandIcon from './expand-icon/ExpandIcon'

export function getData(row, key) {
  if (typeof key !== 'string') {
    return ''
  }
  const arr = key.split('.')

  const value = arr.reduce((acc, curr) => {
    if (!acc || isNill(acc?.[curr])) {
      return ''
    }
    return acc[curr]
  }, row)

  return value
}

export default function DataTable({
  data = [],
  columns = [],
  responsive = false,
  style,
  cssModule,
  className,
  fontClassName,
  headClassName,
  bodyCellClassName,
  rowClassName,
  expandable,
  hover = false,
  striped = false,
  bordered = false,
  borderless = false,
  centered = true,
  noWrap = true,
  rowIdKey,
  tableRef,
}) {
  const isRowExpandable =
    expandable &&
    expandable?.rowExpandable &&
    typeof expandable?.rowExpandable === 'function'

  return (
    <Table
      responsive={responsive}
      hover={hover}
      striped={striped}
      bordered={bordered}
      borderless={borderless}
      className={cx(
        '!tw-mb-0',
        {
          'table-centered': centered,
          'table-nowrap': noWrap,
          '!tw-text-secondary-80': !fontClassName,
        },
        className,
        fontClassName,
      )}
      style={style}
      cssModule={cssModule}
      innerRef={tableRef}
    >
      <thead>
        <tr>
          {!isRowExpandable ? null : (
            <th className={cx('!tw-border-t-0 tw-text-black', headClassName)} />
          )}

          {columns.map(({ Header, headerClassName, style }, index) => (
            <th
              className={cx(
                '!tw-border-t-0 tw-text-black',
                headClassName,
                headerClassName,
              )}
              style={style}
              key={index}
            >
              {Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          return (
            <RenderRow
              key={rowIdKey ? row[rowIdKey] : index}
              row={row}
              columns={columns}
              expandable={expandable}
              bodyCellClassName={bodyCellClassName}
              rowClassName={rowClassName}
            />
          )
        })}
      </tbody>
    </Table>
  )
}

function RenderRow({
  row,
  columns,
  expandable,
  bodyCellClassName,
  rowClassName,
}) {
  const [isExpanded, setIsExpanded] = useState(false)

  function toggleExpand() {
    setIsExpanded((isExpand) => !isExpand)
  }

  const isExpandable =
    expandable && expandable?.rowExpandable && expandable.rowExpandable(row)

  const expandedRowRender = expandable?.expandedRowRender

  return (
    <>
      <tr
        className={
          typeof rowClassName === 'function'
            ? rowClassName({ rowData: row })
            : rowClassName
        }
      >
        {!isExpandable ? null : (
          <td className={cx('text-center', bodyCellClassName)}>
            <ExpandIcon
              isExpanded={isExpanded}
              onClick={toggleExpand}
              className='rp-btn-nostyle p-1 d-inline-flex text-current'
            />
          </td>
        )}

        {columns.map((col, index) => {
          const data = getData(row, col?.accessor)
          const props = {
            className: cx(
              col?.truncated && 'tw-truncate',
              col?.className,
              bodyCellClassName,
            ),
            style: col?.style,
            title: col?.truncated && typeof data === 'string' ? data : null,
          }
          const child = col.Cell ? (
            <col.Cell
              cellData={data}
              index={index}
              rowData={row}
              accessor={col?.accessor}
            />
          ) : (
            data
          )

          return (
            <td key={index} {...props}>
              {child}
            </td>
          )
        })}
      </tr>

      {!isExpandable || !isExpanded ? null : expandedRowRender(row)}
    </>
  )
}
