import React from 'react'

import {
  CircleHalf,
  ClockCountdown,
  ClockCounterClockwise,
  Siren,
} from '@phosphor-icons/react'
import { DetailsList } from '../pages/CompanySetting/components/time-off-policies-tab/view-policy'
import { formatDays } from '../utils/formatters/format-days'
import { DetailsInfoList } from '../pages/review-center/review-layout-details-components'

export default function PolicyRequestSettings({
  canRequestHalfDay,
  maximumRequestDays,
  isRetrospective,
  waitTime,
  titleClassName,
  bodyClassName,
  title,
}) {
  const items = [
    {
      icon: <CircleHalf size={24} />,
      label: 'Half day request',
      value: canRequestHalfDay ? 'Yes' : 'No',
      tip: 'Can a worker request a half day off?',
    },
    {
      icon: <Siren size={24} />,
      label: 'Time off maximum',
      value: maximumRequestDays ? formatDays(maximumRequestDays) : 'No maximum',
      tip: 'Is there a maximum number of sequential days per time off request?',
    },
    {
      icon: <ClockCounterClockwise size={24} />,
      label: 'Retrospective request',
      value: isRetrospective ? 'Yes' : 'No',
      tip: 'Can a worker submit a request for a past period?',
    },
    {
      icon: <ClockCountdown size={24} />,
      label: 'Waiting time',
      value: waitTime ? formatDays(waitTime) : 'No waiting time',
      tip: 'Is there a waiting time after date of hire?',
    },
  ]

  if (title)
    return (
      <DetailsList
        title='Requests settings'
        titleClassName={titleClassName}
        bodyClassName={bodyClassName}
        items={items}
      />
    )
  return <DetailsInfoList items={items} className={bodyClassName} />
}
