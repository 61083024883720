import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import { addContractTag } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import TagsDropdown from './TagsDropdown'
import { TrashSimple } from '@phosphor-icons/react'

const MAX_TAG_ERROR = 'Max of 2 tags per contract reached.'

const Tags = ({
  tags,
  tagsList,
  contract,
  onUpdate,
  deleteTagTotally,
  updateTags,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [showActions, setShowActions] = useState(false)
  const [elementTags, setElementTags] = useState(tags)
  const [creatingTag, setCreatingTag] = useState(false)

  const handleDelete = (data) => {
    const newTags = [...elementTags]
    const index = newTags.findIndex((e) => e.id === data.id)
    newTags.splice(index, 1)
    setElementTags(newTags)
    setShowActions(false)
  }

  const addTagData = (value) => {
    if (elementTags?.length < 2 && value && value !== '') {
      setCreatingTag(true)
      addContractTag(user?.token, { contract_id: contract.id, tag: value })
        .then((r) => {
          if (r.data.success) {
            updateTags()
            setElementTags(r.data.data.contract_tags)
            setShowActions(false)
            toastr.success(`${value} tag created`)
          }
        })
        .catch((e) => {
          toastr.error(getErrorMessage(e))
        })
        .finally(() => setCreatingTag(false))
    } else {
      toastr.error(MAX_TAG_ERROR)
    }
  }
  useEffect(() => {
    setElementTags(tags)
  }, [tags])

  const actions = tagsList?.map((e) => {
    return {
      component: () => (
        <span className='tw-flex tw-items-center tw-justify-between'>
          <span
            className='tw-flex tw-items-center tw-rounded-[32px] tw-px-2 tw-py-1 tw-text-[10px] tw-font-bold tw-uppercase'
            style={{ backgroundColor: e.color }}
          >
            {e.name}
          </span>

          <TrashSimple
            size={20}
            className='tw-ml-4 tw-text-red'
            onClick={(event) => {
              event.stopPropagation()
              deleteTagTotally(e)
            }}
          />
        </span>
      ),
      onClick: () => {
        if (elementTags.filter((elm) => elm.name === e?.name).length === 0) {
          if (elementTags.length < 2) {
            addContractTag(user?.token, {
              contract_id: contract.id,
              tag: e.name,
            })
              .then((r) => {
                if (r.data.success) {
                  setElementTags(r.data.data.contract_tags)
                }
              })
              .catch((e) => {
                toastr.error(getErrorMessage(e))
              })
          } else {
            toastr.error(MAX_TAG_ERROR)
          }
        }
      },
    }
  })
  return (
    <TagsDropdown
      toggle={() => {
        setShowActions((t) => !t)
      }}
      isOpen={showActions}
      options={actions}
      elementTags={elementTags}
      contract={contract}
      onUpdate={onUpdate}
      handleDelete={handleDelete}
      addTagData={addTagData}
      creatingTag={creatingTag}
    />
  )
}

export default Tags
