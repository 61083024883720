import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import NoContent from '../../components/NoContent'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { userTypes } from '../../helpers/enum'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getUnpaidList, getUpcomingPaymentList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getPaymentIds } from '../payInvoices'
import PaymentList from './paymentList'

export const AED_CODE = 'AED'

export function AllDuePayments({ children }) {
  const history = useHistory()
  const params = useParams()

  const user = useSelector((state) => state.Account?.user)
  const profile = useSelector((state) => state.userProfile.userProfile)
  const currency = profile?.company?.currency || profile?.currency
  const { hasAccess } = usePermissions()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [trigger, setTrigger] = useState(false)
  const [totalToPay, setTotalToPay] = useState(0)

  const idParam = params?.id

  function updatePaymentList() {
    if (params?.id) {
      getUpcomingPaymentList(user?.token)
        .then((r) => {
          if (r.data.success) {
            setData(
              r.data.data.map((e) => ({
                ...e,
                selected: e.id === params?.id,
              })),
            )
          } else {
            toastr.error(r.data.message)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      getUnpaidList(user?.token)
        .then((r) => {
          if (r.data.success) {
            // @todo: selected should be true
            setData(r.data.data.map((e) => ({ ...e, selected: false })))
          } else {
            toastr.error(r.data.message)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    const selectedElements = data.filter((e) => e.selected)

    if (selectedElements?.length > 1) {
      setTotalToPay(
        selectedElements?.reduce((prev, current) =>
          prev?.total_trans_amount
            ? prev?.total_trans_amount + current?.total_trans_amount
            : prev + current?.total_trans_amount,
        ),
      )
    } else if (selectedElements?.length === 1) {
      setTotalToPay(selectedElements[0]?.total_trans_amount)
    } else {
      setTotalToPay(0)
    }

    const ids = getPaymentIds(selectedElements)
    dispatch(updateToPayList(ids))

    if (trigger) {
      history.push('/pay-invoices')
    }
    return () => {
      setTrigger(false)
    }
  }, [data])

  const formatter = getCurrencyFormatter(currency?.code)

  useEffect(() => {
    updatePaymentList()
  }, [])

  const AEDPayments = data.filter((e) => e?.currency?.code === AED_CODE)

  const isClient = user?.type === userTypes.COMPANY

  const showAction = isClient && hasAccess(permissions.PrepareTransactions)

  const showAedPayments =
    currency?.code === AED_CODE &&
    AEDPayments.length > 0 &&
    AEDPayments.length < data.length

  const isEmpty = data?.length === 0 || !data

  return (
    <div className={idParam && 'page-content'}>
      <PageHeading>
        <PageHeading.Title>Payments due</PageHeading.Title>

        {!showAction || loading || isEmpty ? null : (
          <PageHeading.Action>
            <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3'>
              <Button
                disabled={totalToPay === 0}
                onClick={() => {
                  history.push('/pay-invoices')
                }}
              >
                {`Pay ${totalToPay === 0 ? '' : formatter.format(totalToPay)}`}
              </Button>

              {!showAedPayments ? null : (
                <Button
                  type='button'
                  color='link'
                  className='!tw-px-0'
                  onClick={() => {
                    setTrigger(true)
                    setData(
                      data.map((inv) => ({
                        ...inv,
                        selected: inv?.currency?.code === AED_CODE,
                      })),
                    )
                  }}
                >
                  Process AED payments separately
                </Button>
              )}
            </div>
          </PageHeading.Action>
        )}
      </PageHeading>

      {children}

      <Card className='rp-shadow-2'>
        {loading ? (
          <Loader minHeight='30rem' />
        ) : isEmpty ? (
          <CardBody>
            <NoContent
              headline='Payments due'
              subtitle='Payments due will be shown here'
              image={noTransactions}
            />
          </CardBody>
        ) : (
          <div className='tw-py-2'>
            <PaymentList
              data={data}
              onInvoiceSelected={(i) => {
                setData(
                  data.map((inv, idx) => ({
                    ...inv,
                    selected: i === idx ? !inv.selected : inv.selected,
                  })),
                )
              }}
            />
          </div>
        )}
      </Card>
    </div>
  )
}
