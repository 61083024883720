import {
  DownloadSimple,
  Files,
  TrashSimple,
  UploadSimple,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import { StyledH3, StyledH4 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadContractorDoc,
  getDocsList,
} from '../../services/api'
import openFileV2 from '../../utils/file/open-v2'
import UploadModal from './components/UploadModal'

const ContractorDoc = () => {
  const history = useHistory()
  const country = useSelector(
    (state) => state.userProfile?.userProfile?.Country_of_Tax_Residence,
  )
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const docsList = useFetch({
    action: getDocsList,
    autoFetch: true,
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <div className='page-content'>
      <Row className='d-flex justify-content-between align-items-center p-0 m-0 mb-4'>
        <Col xs={12} md={12} className='p-0 m-0'>
          <StyledH3 min='22px' max='32px' className='text-dark mb-3'>
            Documents
          </StyledH3>
          <StyledH4
            style={{ fontWeight: '400' }}
            min='12px'
            max='16px'
            className='text-secondary mb-3'
          >{`Based on your tax country ${
            country?.name || ''
          }, below are the documents you are required to provide to your
                        client.`}</StyledH4>
        </Col>
        <Col
          xs={12}
          md={6}
          className='m-0 p-0 d-flex justify-content-end'
        ></Col>
      </Row>

      {docsList.isLoading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className='p-0 m-0'>
          {docsList.data?.map((e, i) => (
            <DocCard
              key={`doc${i}`}
              item={e}
              update={() => docsList.startFetch({}, false)}
              select={() => {
                if (e.is_form) {
                  history.push('/document/create')
                } else {
                  setSelectedDoc(e)
                  setIsOpen(true)
                }
              }}
            />
          ))}
          <DocCard
            item={{
              title: 'Any relevant documents (optional) ',
              subtitle: 'Upload a scanned copy of any relevant documents',
            }}
            update={() => {
              docsList.startFetch({}, false)
            }}
            select={() => {
              setSelectedDoc({
                title: 'Any relevant documents (optional) ',
                subtitle: 'Upload a scanned copy of any relevant documents',
              })
              setIsOpen(true)
            }}
          />

          <UploadModal
            update={() => docsList.startFetch({}, false)}
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            doc={selectedDoc}
          />
        </Container>
      )}
    </div>
  )
}

export const getComplianceDocType = (type) => {
  switch (type) {
    case 'application/pdf':
      return 'pdf'
    case 'image/jpeg':
    case 'image/jpg':
      return 'jpg'
    case 'image/png':
      return 'png'
    default:
      return ''
  }
}

const DocCard = ({ item, update = () => {}, select }) => {
  const download = useFetch({
    action: downloadContractorDoc,
    onComplete: (data) => {
      openFileV2(data, {
        name: `${item.title}.${getComplianceDocType(data?.type)}`,
        download: true,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({ token: item.token })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({ token: item.token })
  }

  return (
    <Container fluid>
      <StyledCard style={{ gap: '1rem 0' }}>
        <Files
          size={24}
          weight='fill'
          className='flex-shrink-0 text-primary-100 mr-md-3'
        />
        <Col xs={12} md={7} className='px-0'>
          <h6 className='text-dark font-size-14 rp-font-semibold mb-0'>
            {item.title} {item?.required && !item?.token ? '(required)' : ''}
          </h6>
          {item?.token ? null : (
            <p className='text-secondary font-size-14 mb-0 mt-2'>
              {item.is_form
                ? 'Fill form to generate the document'
                : 'Upload a scanned copy'}
            </p>
          )}
        </Col>
        <Col className='d-flex justify-content-md-end gap-8 flex-wrap px-0'>
          {item?.token ? (
            <>
              <Button
                onClick={handleDelete}
                color='danger'
                size='sm'
                outline
                icon={<TrashSimple size={14} />}
                disabled={download?.isLoading || deleteDoc.isLoading}
                loading={deleteDoc.isLoading}
              >
                Delete
              </Button>
              <Button
                onClick={handleDownload}
                size='sm'
                outline
                icon={<DownloadSimple size={14} />}
                disabled={download?.isLoading || deleteDoc.isLoading}
                loading={download?.isLoading}
              >
                Download
              </Button>
            </>
          ) : (
            <Button
              icon={<UploadSimple size={16} weight='fill' />}
              onClick={select}
            >
              {item?.is_form ? 'Fill form' : 'Upload Document'}
            </Button>
          )}
        </Col>
      </StyledCard>
    </Container>
  )
}

const StyledCard = styled(Row)`
  padding: 16px;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default ContractorDoc
