import { PaperPlaneTilt, Plus } from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { PERMISSION_GROUP } from '../../pages/CompanySetting/manage-role'
import { RequestDocumentModal } from '../../pages/Fulltime/employee-documents/employee-docs-list'
import { PermissionTooltip } from '../permission-tooltip'
import Button from '../ui/button'

export function getKycNotCompleted({ userProfile, user }) {
  const contractorType = userProfile?.contractor_type

  if (userProfile?.type === userTypes.COMPANY) {
    // We want to allow company to create contracts even if their KYC is not verified
    return false
  }

  return (
    (!user?.profile_completed ||
      userProfile?.kyc_verified !== 1 ||
      userProfile?.kyc_status !== 'verified') &&
    contractorType === BE_CONTRACT_CATEGORY.INDIVIDUAL &&
    userProfile?.type === userTypes.CONTRACTOR
  )
}

export function mainSidebarActionLabel({ isEmployee }) {
  return isEmployee ? 'Request Document' : 'New Contract'
}

export function MainSidebarActionIcon({ isEmployee, className, size = 16 }) {
  return isEmployee ? (
    <PaperPlaneTilt className={cn('tw-flex-shrink-0', className)} size={size} />
  ) : (
    <Plus className={cn('tw-flex-shrink-0', className)} size={size} />
  )
}

export default function CreateContractBtn({
  containerClassName,
  className,
  label,
  btnProps,
  tag: Tag = Button,
}) {
  const [showRequestDoc, setShowRequestDoc] = useState(false)

  function toggleRequestModal() {
    setShowRequestDoc(false)
  }

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const storeContract = useSelector((state) => state?.Contract?.details)
  const contractorType = userProfile?.contractor_type

  const isClient = user?.type === userTypes.COMPANY

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const { hasAccess } = usePermissions()

  const isContractorAndKycProfileNotCompleted = useMemo(
    () => getKycNotCompleted({ userProfile, user }),
    [userProfile, user],
  )

  const history = useHistory()
  const location = history.location
  const backRoute = encodeURI(location.pathname + location.search)

  const hasCreateContractPermission =
    !isClient || hasAccess(permissions.CreateContracts)

  const disableCreateContract = isEmployee
    ? false
    : isContractorAndKycProfileNotCompleted || !hasCreateContractPermission

  const disableRequestDoc = isEmployee && !storeContract?.onboarding_completed

  if (isDeEmployee) {
    return null
  }

  return (
    <>
      <div className={containerClassName} id='create-new-contract-div'>
        <PermissionTooltip
          showing={!hasCreateContractPermission}
          id='create-new-contract-btn'
          area={PERMISSION_GROUP.CREATE_CONTRACTS.name}
          action=''
        >
          <Tag
            className={cn(
              !label &&
                'tw-rounded-full tw-bg-primary-100 tw-p-2 tw-text-white tw-transition-colors hover:tw-bg-primary-120/85',
              isContractorAndKycProfileNotCompleted && 'tw-pointer-events-none',
              className,
            )}
            {...btnProps}
            onClick={() => {
              if (isEmployee) {
                setShowRequestDoc(true)
              } else {
                history.push('/contract/create', { backRoute })
                window.analytics.track('Clicked add new contract', {
                  email_id: user?.email,
                })
              }
            }}
            disabled={
              disableCreateContract ||
              disableRequestDoc ||
              isContractorAndKycProfileNotCompleted
            }
          >
            <div className='tw-hidden lg:tw-block'>
              <MainSidebarActionIcon isEmployee={isEmployee} />
            </div>
            {label}
          </Tag>
        </PermissionTooltip>
      </div>

      {isContractorAndKycProfileNotCompleted && (
        <UncontrolledTooltip
          placement='bottom'
          target='create-new-contract-div'
        >
          Complete your profile and KYC to create contracts
        </UncontrolledTooltip>
      )}

      {!!showRequestDoc && (
        <RequestDocumentModal
          isOpen={showRequestDoc}
          toggle={toggleRequestModal}
        />
      )}
    </>
  )
}
