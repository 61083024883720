import { NewspaperClipping } from '@phosphor-icons/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Card, Col, Container, NavItem, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'

import backgroundBlue from '../../assets/images/bkblue.svg'
import Toggle from '../../components/Forms/Toggle/Toggle'
import BadgeX from '../../components/Table/BadgeX'
import Head from '../../components/head'
import { PageNav } from '../../components/page-nav'
import { PermissionTooltip } from '../../components/permission-tooltip'
import Loader from '../../components/ui/loader'
import Shimmer from '../../components/ui/shimmer'
import TabContent from '../../components/ui/tabs'
import FEATURE_FLAGS from '../../config/feature-flags'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  getCompanyInfo,
  getPaymentMethods,
  updateCompanyInfo,
} from '../../services/api'
import { getEntities } from '../../services/api-direct-employee'
import { updateProfileCompany } from '../../store/profile/actions'
import { getItem, setItem } from '../../utils/ls'
import ContractorSettings from '../ContractorSettings'
import CompanyHistory from './CompanyHistory'
import CompanyInfo from './CompanyInfo'
import CustomFields from './CustomFields'
import Customization from './Customization'
import Integrations from './Integrations'
import PaymentInfo from './PaymentInfo'
import ApiKeys from './api-keys'
import { ApprovalsTab } from './approval-tab'
import Entity from './components/Entity'
import CompanyDetails from './components/company-details'
import { ContractLogoManagement } from './components/contract-logo-management'
import { TimeOffPoliciesTab } from './components/time-off-policies-tab/time-off-policies-tab'
import { CustomFieldsTab } from './custom-fields'
import { DepositsRefundsTab } from './deposits-refunds-tab'
import ManageAvatar from './manage-avatar'
import { PERMISSION_GROUP } from './manage-role'
import { UsersRoles } from './users-roles'

export default function CompanySetting() {
  const location = useLocation()
  const { fromContractCreation } = location.state ?? {}

  const user = useSelector((state) => state.Account?.user)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const userProfile = useSelector(
    (state) => state.userProfile?.userProfile ?? {},
  )

  const formRef = useRef(null)
  const [activeTab, setActiveTab] = useState(1)
  const [showForm, setShowForm] = useState({
    slide: fromContractCreation,
    wrapper: fromContractCreation,
  })
  const [selectedCompany, setSelectedCompany] = useState()
  const display = useSelector((state) => state.Account?.user?.type)
  const { hasAccess } = usePermissions()

  const paymentMethods = useFetch({
    action: getPaymentMethods,
    autoFetch: true,
  })

  const {
    startFetch: fetchCompanyInfo,
    data: companyInfoData,
    isLoading: companyInfoLoading,
    setData: setCompanyInfoData,
  } = useFetch({
    action: getCompanyInfo,
    autoFetch:
      userProfile.type === userTypes.COMPANY ||
      userProfile?.contractor_type === contractorTypes.ENTITY,
    onComplete: () => {
      window.analytics.track('Viewed company settings', {
        email_id: user?.email,
      })
    },
  })

  const { data: entities, startFetch: _getEntities } = useFetch({
    action: getEntities,
    autoFetch: true,
    onComplete: (data) => {
      const urlEntityID = new URLSearchParams(location.search).get('entityId')
      const entity = data.find((e) => e.id === Number(urlEntityID))
      if (entity) {
        setSelectedCompany(entity)
        setShowForm({ slide: true, wrapper: true })
      }
    },
  })

  const tabsData = useMemo(() => {
    return [
      {
        id: 1,
        key: 'info',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bxs-id-card',
        label: 'Company Info',
        route: '/settings/info',
      },
      {
        id: 2,
        key: 'payment',
        access:
          hasAccess(permissions.ViewCompaniesInfo) &&
          paymentMethods.data?.length > 0,
        icon: 'bx bx-money',
        label: 'Payment',
        route: '/settings/payment',
      },
      FEATURE_FLAGS.DEPOSITS_REFUNDS_CLIENT_TAB && {
        id: 3,
        key: 'deposits-refunds',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-money',
        label: 'Deposits & Refunds',
        route: '/settings/deposits-refunds',
      },
      {
        id: 4,
        key: 'users',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Users & Roles',
        route: '/settings/users',
      },
      FEATURE_FLAGS.TIME_OFF_POLICIES && {
        id: 5,
        key: 'timeOffPolicies',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Time off policies',
        route: '/settings/time-off-policies',
      },
      {
        id: 6,
        key: 'payrollApproval',
        access:
          FEATURE_FLAGS.REMOTEPASS_PAYROLL_APPROVAL_PROCESS &&
          hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Approvals',
        route: '/settings/approvals',
      },
      {
        id: 7,
        key: 'customFields',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'Custom Fields',
        route: '/settings/customFields',
      },
      {
        id: 8,
        key: 'integrations',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'Integrations',
        route: '/settings/integrations',
      },
      {
        id: 9,
        key: 'customization',
        access: false,
        icon: 'fas fa-puzzle-piece',
        label: 'Customization',
        route: '/settings/customization',
      },
      {
        id: 10,
        key: 'history',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'History',
        route: '/settings/history',
      },
      (companyInfoData?.api_keys_enabled ?? false) && {
        id: 11,
        key: 'apikeys',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'API keys',
        route: '/settings/apikeys',
      },
    ]?.filter((e) => Boolean(e) && e.access)
  }, [
    hasAccess,
    companyInfoData?.api_keys_enabled,
    paymentMethods.data?.length,
  ])

  useEffect(() => {
    const foundId = tabsData.find((tab) => {
      return location.pathname === tab.route
    })?.id

    if (!foundId) {
      setActiveTab(1)
    } else if (foundId !== activeTab) {
      setActiveTab(foundId)
    }
  }, [activeTab, location.pathname, tabsData])

  const tabName = tabsData.find((tab) => location.pathname === tab.route)?.label

  const handleCompanyDetailsClose = () => {
    // remove the entity id from the url
    window.history.replaceState({}, '', location.pathname)
    setShowForm((prev) => ({ ...prev, slide: false }))
    setTimeout(() => {
      setShowForm((prev) => ({ ...prev, wrapper: false }))
    }, 1000)
  }

  return display === userTypes.COMPANY ? (
    <div className='page-content position-relative'>
      <Head title={[tabName, 'Company settings'].filter(Boolean).join(' - ')} />

      <img
        className='position-absolute left-0 right-0 bg-primary w-100'
        style={{
          backgroundRepeat: 'repeat',
          backgroundImage: backgroundBlue ? `url(${backgroundBlue})` : null,
          top: 'var(--header-height)',
          height: 210,
        }}
      />

      <Card className='position-relative rp-shadow-2 mt-4.5 mb-0'>
        <PageNav>
          {tabsData?.map((nav) => {
            return (
              <NavItem key={`companySettingsNav:${nav.id}`}>
                <PageNav.Link
                  isActive={activeTab === nav.id}
                  tag={Link}
                  to={nav.route}
                >
                  {nav.label}
                </PageNav.Link>
              </NavItem>
            )
          })}
        </PageNav>

        <TabContent activeTab={activeTab}>
          {tabsData?.map((nav) => {
            if (activeTab !== nav.id) {
              return null
            }

            switch (nav.key) {
              case 'info': {
                return renderSettingsTab(
                  nav.id,
                  FEATURE_FLAGS.NEW_DIRECT_EMPLOYEE &&
                    companyInfoData?.is_direct_employee_enabled ? (
                    <div className='tw-flex tw-flex-col tw-p-6'>
                      <div className='tw-flex tw-items-center tw-justify-between'>
                        <div className='tw-flex tw-flex-col'>
                          <span className='tw-text-base tw-font-bold tw-text-black'>
                            Company information
                          </span>
                          <span>Manage your company and entities details</span>
                        </div>
                        <button
                          className='tw-text-sm tw-font-bold tw-text-primary'
                          onClick={() => {
                            setSelectedCompany(undefined)
                            setShowForm({ slide: true, wrapper: true })
                          }}
                        >
                          Add New
                        </button>
                      </div>

                      <div className='tw-mt-6 tw-flex tw-flex-wrap tw-gap-4 tw-overflow-auto'>
                        {!companyInfoData ? (
                          <Shimmer className='!tw-h-44 !tw-w-56' />
                        ) : (
                          <Entity
                            data={companyInfoData}
                            isMain
                            onViewDetailsClick={() => {
                              setSelectedCompany(companyInfoData)
                              setShowForm({ slide: true, wrapper: true })
                            }}
                          />
                        )}

                        {showForm.wrapper && (
                          <CompanyDetails
                            mainCompany={userProfile.company}
                            onClose={handleCompanyDetailsClose}
                            visible={showForm.slide}
                            isNew={!selectedCompany?.id}
                            data={selectedCompany}
                            onUpdate={() => {
                              setShowForm({
                                slide: false,
                                wrapper: false,
                              })
                              _getEntities()
                            }}
                          />
                        )}

                        {entities?.map((entity) => (
                          <Entity
                            data={entity}
                            isMain={false}
                            key={entity.id}
                            onViewDetailsClick={() => {
                              setSelectedCompany(entity)
                              setShowForm({ slide: true, wrapper: true })
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Container fluid>
                      <Row>
                        {!companyInfoLoading && (
                          <Col md={3} className='border-right'>
                            <ManageAvatarAndLogo
                              companyInfoData={companyInfoData}
                              setCompanyInfoData={setCompanyInfoData}
                            />
                          </Col>
                        )}
                        <Col className='px-0'>
                          {companyInfoLoading ? (
                            <Loader minHeight='40rem' />
                          ) : (
                            !!countries?.length &&
                            !!companyInfoData && (
                              <CompanyInfo
                                ref={formRef}
                                data={companyInfoData}
                                countries={countries}
                                onUpdate={() => fetchCompanyInfo(null, false)}
                              />
                            )
                          )}
                        </Col>
                      </Row>
                    </Container>
                  ),
                )
              }
              case 'payment': {
                return renderSettingsTab(nav.id, <PaymentInfo />)
              }
              case 'deposits-refunds': {
                return renderSettingsTab(nav.id, <DepositsRefundsTab />)
              }
              case 'users': {
                return renderSettingsTab(nav.id, <UsersRoles />)
              }
              case 'timeOffPolicies': {
                return renderSettingsTab(nav.id, <TimeOffPoliciesTab />)
              }
              case 'payrollApproval': {
                return renderSettingsTab(nav.id, <ApprovalsTab />)
              }
              case 'customFields': {
                return renderSettingsTab(
                  nav.id,
                  FEATURE_FLAGS.CUSTOM_FIELDS ? (
                    <CustomFieldsTab />
                  ) : (
                    <CustomFields />
                  ),
                )
              }
              case 'integrations': {
                return renderSettingsTab(nav.id, <Integrations />)
              }
              case 'apikeys': {
                return renderSettingsTab(nav.id, <ApiKeys />)
              }
              case 'customization': {
                return renderSettingsTab(
                  nav.id,
                  <Customization data={companyInfoData?.personalization} />,
                )
              }
              case 'history': {
                return renderSettingsTab(
                  nav.id,
                  <CompanyHistory
                    companyId={companyInfoData?.id}
                    companyInfoLoading={companyInfoLoading}
                  />,
                )
              }
              default: {
                return <></>
              }
            }
          })}
        </TabContent>
      </Card>
    </div>
  ) : (
    <ContractorSettings />
  )
}

function ManageAvatarAndLogo({ companyInfoData, setCompanyInfoData }) {
  const dispatch = useDispatch()
  const userProfile = useSelector(
    (state) => state.userProfile?.userProfile ?? {},
  )
  const user = useSelector((state) => state.Account?.user)
  const { company } = userProfile
  const [loading, setLoading] = useState(false)

  function uploadPhoto(acceptedFiles) {
    const file = acceptedFiles[0]
    if (
      file &&
      ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)
    ) {
      const fileSize = file.size / 1024 / 1024

      if (fileSize > 25) {
        toastr.error('File size exceeds 25 MB')
        return
      }

      const body = { logo: file }
      setLoading(true)
      updateCompanyInfo(user?.token, body)
        .then((r) => {
          if (r.data.success) {
            setCompanyInfoData(r.data.data)
            dispatch(updateProfileCompany(r?.data?.data))
          } else {
            toastr.error(r.data.data?.error || r.data?.message)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      toastr.error('File not supported')
    }
  }

  return (
    <div className='text-center py-4 d-flex flex-column gap-24'>
      <div className='px-3 py-4 border border-surface-30 rounded'>
        <ManageAvatar
          src={companyInfoData?.logo ?? company?.logo}
          name={company?.name}
          onUploadPhoto={uploadPhoto}
          isUploading={loading}
        />

        <h3 className='px-2 text-center text-gray-h mt-2' translate='no'>
          {company?.name}
        </h3>

        {!company?.type?.name ? null : (
          <p className='text-gray-600 font-size-14 px-2 text-center'>
            {company?.type?.name}
          </p>
        )}

        <BadgeX status='secondary'>Entity</BadgeX>

        <hr />

        <div
          className='font-size-14 text-gray-h'
          style={{ lineHeight: '24px' }}
        >
          Company information will be displayed as is in your contracts and
          invoices.
        </div>
      </div>

      <ContractLogoSetting />
    </div>
  )
}

export function usePayrollApprovalEnabled() {
  const payrollApprovalEnabled = useSelector(
    (state) =>
      state.userProfile?.userProfile?.company?.is_payroll_approval_enabled?.toString() ===
      '1',
  )

  return payrollApprovalEnabled
}

function ContractLogoSetting() {
  const dispatch = useDispatch()
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const { hasAccess } = usePermissions()

  const enabled = company?.official_logo_enabled?.toString() === '1'

  const { startFetch: updateCompany, isLoading: updatingContractLogo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
        }
      },
    })

  function handleToggleLogos(e) {
    const { checked } = e.target ?? {}

    updateCompany({ official_logo_enabled: checked ? 1 : 0 })
  }

  return (
    <div className='border border-surface-30 rounded p-3'>
      {enabled ? null : <ContractLogoNotice />}

      <div className='d-flex flex-column gap-12'>
        <PermissionTooltip
          showing={!hasAccess(permissions.manageCompanySettings)}
          id='add-logo-toggle'
          area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
        >
          <label
            className='d-inline-flex align-items-center flex-wrap gap-8 mb-0 cursor-pointer'
            style={{ alignSelf: 'flex-start' }}
          >
            <Toggle
              check={enabled}
              change={handleToggleLogos}
              disabled={
                updatingContractLogo ||
                !hasAccess(permissions.manageCompanySettings)
              }
              marginRight={null}
            />
            <span className='rp-font-medium'>Add logo to documents</span>
          </label>
        </PermissionTooltip>

        {!enabled ? null : <hr className='mx-n3 my-0' />}

        {/* //TODO: show but disable dropzone later */}
        {hasAccess(permissions.manageCompanySettings) && (
          <ContractLogoManagement enabled={enabled} />
        )}
      </div>
    </div>
  )
}

const CONTRACT_LOGO_NOTICE_KEY = 'contract-logo-notice-shown'
function ContractLogoNotice() {
  const shown = JSON.parse(getItem(CONTRACT_LOGO_NOTICE_KEY) || 'false')
  const [isShown, setIsShown] = useState(shown)

  function hideNotice() {
    setItem(CONTRACT_LOGO_NOTICE_KEY, true)
    setIsShown(true)
  }

  if (isShown) {
    return null
  }

  return (
    <>
      <div className='text-center d-flex flex-column gap-12 align-items-center'>
        <div className='d-flex flex-column align-items-center'>
          <BadgeX
            color='primary'
            className='rounded-pill mb-n2 px-2 py-1.5 z-1000 position-relative uppercase'
            style={{ boxShadow: '0 0 0 3px white' }}
            size='md'
          >
            New
          </BadgeX>
          <NewspaperClipping
            size={64}
            weight='duotone'
            color='var(--primary)'
            className='p-3 rounded-circle bg-primary-20'
          />
        </div>

        <p className='mb-0 mx-auto text-gray-600' style={{ maxWidth: 210 }}>
          You can now add your logo to your future documents in RemotePass
        </p>

        <button
          className='rp-btn-nostyle text-text-black rp-font-bold hover:bg-slate-50'
          onClick={hideNotice}
        >
          Dismiss
        </button>
      </div>

      <hr className='mx-n3' />
    </>
  )
}

function renderSettingsTab(navId, component) {
  return (
    <TabPane key={`tab:${navId}`} tabId={navId}>
      {component}
    </TabPane>
  )
}
