import { DownloadSimple, Files, NewspaperClipping } from '@phosphor-icons/react'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import trash from '../../../assets/images/Trash.svg'
import { ActionsDropdown } from 'ui'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../../helpers/enum'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadAdminPayslip,
  downloadDePayslip,
  downloadPayslip,
  getAdminPayslipsList,
  getContractList,
  getDePayslipsList,
  getFullTimeContractDetails,
  getPayslipsList,
} from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import CardsList from '../../Contract/components/tab/card-list'
import TabCardHeader from '../../Contract/components/tab/tab-card-header'
import TabEmpty from '../../Contract/components/tab/tab-empty'
import AddPayslipModal from './addPayslipModal'

const Payslips = ({ contractData, isAdmin }) => {
  const storeContract = useSelector((state) => state?.Contract?.details)
  const contract = contractData || storeContract

  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const isDeRelated = isDeEmployee || isDeContract

  const [show, setShow] = useState(false)
  const [page, setPage] = useState(1)
  const list = useFetch(
    {
      action: isDeRelated
        ? getDePayslipsList
        : isAdmin
          ? getAdminPayslipsList
          : getPayslipsList,
      body:
        isEmployee || !contract?.id
          ? { page, perPage: 40 }
          : { contract_id: contract?.id, page, perPage: 40 },
      autoFetch: !!contract?.id,
      withAdminAccess: isAdmin,
    },
    [contract?.id, page],
  )

  const dispatch = useDispatch()
  const { isLoading: gettingContractInfo } = useFetch({
    action: isDeEmployee ? getContractList : getFullTimeContractDetails,
    autoFetch: !contractData,
    body: isDeEmployee ? { status: [4] } : null,
    onComplete: (data) => {
      let contract = data

      if (isDeEmployee) {
        contract = data?.[0]
      }

      dispatch(updateContract(contract))
    },
  })

  const showAddPayslip =
    (user?.type === userTypes.CONTRACTOR || isAdmin) &&
    !isEmployee &&
    !isDeEmployee

  const isEmpty = !list?.data || list?.data?.length === 0

  const showPaginator =
    list.paginator?.first_page_url !== list.paginator?.last_page_url
  const loading = list.isLoading || gettingContractInfo

  return (
    <>
      <Card className='rp-shadow-2'>
        <TabCardHeader
          title='Payslips'
          extra={
            showAddPayslip && !isEmpty && !list.isLoading ? (
              <Button onClick={() => setShow(!show)}>Add a New Payslip</Button>
            ) : null
          }
        />

        <CardBody>
          {loading ? (
            <Loader minHeight='35rem' />
          ) : isEmpty ? (
            <TabEmpty
              minHeight='35rem'
              title='No payslips'
              subtitle={
                isDeEmployee
                  ? 'Past payslips will be shown here'
                  : 'Your payslips will be shown here when added by the employer'
              }
              icon={
                <NewspaperClipping
                  size={250}
                  color='var(--primary)'
                  weight='duotone'
                />
              }
            >
              {showAddPayslip ? (
                <Button onClick={() => setShow(!show)}>
                  Add a New Payslip
                </Button>
              ) : null}
            </TabEmpty>
          ) : isDeRelated ? (
            <DePayslipsList data={list.data} />
          ) : (
            <PayslipList isAdmin={isAdmin} data={list.data} />
          )}
          {!showPaginator || loading ? null : (
            <div className='ml-auto mx-3 mt-2 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={page}
                itemsCountPerPage={list.paginator?.per_page ?? 10}
                totalItemsCount={list.paginator?.total ?? 0}
                onChange={(newPage) => setPage(newPage)}
              />
            </div>
          )}
        </CardBody>
      </Card>

      {show && (
        <AddPayslipModal
          show={show}
          isAdmin={isAdmin}
          contractId={contract?.id}
          hide={() => setShow(false)}
          onSuccessAdd={() => {
            list.startFetch()
          }}
        />
      )}
    </>
  )
}

export const PayslipList = ({ data, isAdmin }) => {
  const listItems = data?.map((e, i) => (
    <PayslipCard isAdmin={isAdmin} key={`payslip-${i}`} item={e} />
  ))

  return (
    <>
      <div className='d-none d-md-block'>
        <TableComp>
          <thead className='thead-light'>
            <tr>
              <StyledTh>Name</StyledTh>
              <StyledTh className='text-right'>Actions</StyledTh>
            </tr>
          </thead>
          {listItems}
        </TableComp>
      </div>

      <div className='d-md-none'>{listItems}</div>
    </>
  )
}

const PayslipCard = ({ item, update = () => {}, isAdmin }) => {
  const date = moment(new Date(item?.year, item?.month - 1)) // second arg is month(Index)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const isMobile = useResize()

  const download = useFetch({
    action: isAdmin ? downloadAdminPayslip : downloadPayslip,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${date.format('MMM - YYYY')}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({
      payslip_id: item.id,
    })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({
      payslip_id: item.id,
    })
  }
  return !isMobile ? (
    <tr>
      <StyledTd>{date.format('MMM - YYYY')}</StyledTd>
      <StyledTd>
        <div className='d-none d-md-flex align-items-center justify-content-end'>
          {!isEmployee && false && (
            <>
              {deleteDoc.isLoading ? (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              ) : (
                <>
                  <a onClick={handleDelete}>
                    <img src={trash} style={{ height: 20 }} alt='' />
                  </a>
                </>
              )}
              <div className='mx-2' />
            </>
          )}

          <Button
            onClick={handleDownload}
            color='light'
            outline
            loading={download?.isLoading}
            disabled={download?.isLoading}
            style={{ minWidth: 100 }}
            icon={<DownloadSimple size={18} />}
            size='sm'
          >
            {download?.isLoading ? null : 'Download'}
          </Button>
        </div>
      </StyledTd>
    </tr>
  ) : (
    <div className='border border-light rounded mb-3 d-flex align-items-center p-4'>
      <div className='d-flex align-items-center' style={{ flex: 1 }}>
        <Files size={24} weight='duotone' color='var(--primary)' />
        <StyledP className='mb-0 ml-2'>{date.format('MMM - YYYY')}</StyledP>
      </div>

      <div className='d-flex justify-content-end' style={{ flex: 1 }}>
        <Button
          onClick={handleDownload}
          color='light'
          outline
          loading={download?.isLoading}
          disabled={download?.isLoading}
          style={{ minWidth: 100 }}
          icon={<DownloadSimple size={18} />}
          size='sm'
        >
          {download?.isLoading ? null : 'Download'}
        </Button>

        {!isEmployee && false && (
          <Button
            type='button'
            color='danger'
            outline
            onClick={handleDelete}
            loading={deleteDoc?.isLoading}
            text='Remove'
            icon={
              <img
                src={trash}
                style={{ width: '0.8em', marginRight: 8 }}
                alt='download icon'
              />
            }
            style={{ height: 40 }}
            className='font-size-14'
          />
        )}
      </div>
    </div>
  )
}

const StyledP = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130.02%;
  color: #4a4e5b;
`

function DePayslipsList({ data }) {
  const columns = useMemo(
    () => [
      { Header: 'Date', accessor: 'month' },
      {
        Header: 'Amount',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(rowData.currency?.code)
          return formatter.format(rowData.total)
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: PayslipActions,
      },
    ],
    [],
  )

  return (
    <>
      <div className='d-none d-md-block'>
        <DataTable columns={columns} data={data} centered />
      </div>

      <CardsList columns={columns} data={data} className='d-md-none' />
    </>
  )
}

function PayslipActions({ rowData }) {
  const { startFetch: downloadPayslip, isLoading } = useFetch({
    action: downloadDePayslip,
    body: { payslip_id: rowData?.id },
    onComplete: (data) => {
      openFileV2(data, {
        download: true,
        name: `payslip-${rowData?.ref}-${rowData?.month}.pdf`,
      })
    },
    onError: () => {
      toastr.error('Something went wrong while downloading payslip')
    },
  })

  const options = [{ label: 'Download', onClick: () => downloadPayslip() }]

  if (options.length === 0) {
    return null
  }

  return <ActionsDropdown data={options} loading={isLoading} />
}

export default Payslips
