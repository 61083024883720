import cx from 'classnames'
import React from 'react'
import { Nav, NavLink } from 'reactstrap'
import { cn } from 'ui'

export function PageNav({ children, className }) {
  return (
    <Nav
      className={cn(
        '!tw-flex-nowrap tw-overflow-x-auto tw-overflow-y-hidden tw-border-b tw-border-surface-30 tw-text-sm',
        className,
      )}
    >
      {children}
    </Nav>
  )
}

function PageNavLink({ children, isActive, className, ...props }) {
  return (
    <NavLink
      className={cx(
        'tw-mx-0 tw-border-b-2 tw-border-t-2 tw-border-transparent tw-border-t-transparent !tw-py-4 tw-px-3 first:tw-rounded-tl last:tw-rounded-tr md:!tw-py-6',
        isActive
          ? 'tw-border-b-current tw-bg-primary-10 !tw-text-primary-100'
          : '!tw-text-text-90 hover:!tw-text-text-120',
        className,
      )}
      style={{ whiteSpace: 'nowrap' }}
      {...props}
    >
      {children}
    </NavLink>
  )
}

PageNav.Link = PageNavLink
