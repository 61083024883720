import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledInput from '../../components/ControlledInput'
import ControlledPhoneInput from '../../components/ControlledPhoneInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import FEATURE_FLAGS from '../../config/feature-flags'
import { BE_CONTRACT_CATEGORY, contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { nameRegExp } from '../../helpers/info-latin-regex'
import {
  getContractorProfileRequiredFields,
  getNotificationList,
  updateContractorProfile,
  updateContractorType,
  uploadTempFile,
} from '../../services/api'
import {
  loginUserSuccessful,
  updateUserInfo,
} from '../../store/auth/register/actions'
import {
  updateContractRef,
  updateUserNotifications,
  updateUserProfileInfo,
} from '../../store/profile/actions'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import { FILE_SIZE_LIMITS_IN_BYTES } from '../Contract/utils/constants'
import { FilePlus, XCircle } from '@phosphor-icons/react'
import DropzoneInput from '../../components/Common/dropzone-input'
import Loader from '../../components/ui/loader'

const types = [
  { value: contractorTypes.ENTITY, label: 'Entity' },
  {
    value: contractorTypes.INDIVIDUAL,
    label: 'Individual',
  },
]

export const switchingContractorToEntity = 'switching-contractor-to-entity'

const ProfileInfoNew = React.forwardRef(function ProfileInfoNew(
  { onStart = () => {}, isCompletion, onCompleted, countries = [], data },
  ref,
) {
  const history = useHistory()
  const staticData = useSelector((state) => state?.Layout?.staticData)
  const contractRef = useSelector((state) => state?.userProfile?.contractRef)

  const {
    contractor_type: contractorType,
    kyc_status: kycStatus,
    kyc_verified: kycVerified,
  } = useSelector((state) => state?.userProfile?.userProfile)
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const isEmployeeOrDeEmployee = isEmployee || isDeEmployee
  const idDocLabel = isEmployeeOrDeEmployee ? 'Passport' : 'ID / Passport'

  const user = useSelector((state) => state?.Account?.user)

  const isVerified =
    FEATURE_FLAGS.LOCK_PERSONAL_INFO &&
    kycStatus === 'verified' &&
    kycVerified === 1

  const deEmployeeHasEmployeeId = isDeEmployee && data?.employee_identifier

  const passwordDocTypeId = 2

  function isRequired(field) {
    return (
      data?.contribution_required_fields?.includes(field) &&
      ![18, 117, 166, 179, 194, 231].includes(data?.Country_of_Citizenship?.id) // GCC nations only
    )
  }

  const schema = (countries) =>
    yup.object().shape({
      contractor_type: yup.string().when({
        is: () => isEmployeeOrDeEmployee,
        then: (schema) => schema.optional(),
        otherwise: (schema) =>
          schema
            .required('Contractor type is required')
            .oneOf(types.map((t) => t.value)),
      }),
      country_id: yup.string().required('Country is required'),
      state_id: yup.string().when('country_id', ([value]) => {
        if (countries?.find((c) => '' + c.id === value)?.states?.length) {
          return yup.string().required('State is required')
        }
      }),
      city: yup.string().required('City is required'),
      currency_id: yup.string().required('Currency is required'),
      address: yup
        .string()
        .required('Address is required')
        .matches(
          /(^[-0-9A-Za-z.,/ ]+$)/,
          'Your address must contain only letters and numbers',
        ),
      phone: yup.string().required('Phone number is required'),
      birth_date: yup.string().required('Date of birth is required'),
      document_type: yup.string().required('Document type is required'),
      document_number: yup.string().required('Document number is required'),
      Country_of_Citizenship: yup
        .string()
        .required('Citizenship Country is required'),
      Country_of_Tax_Residence: yup
        .string()
        .required('Tax Residence Country is required'),
      first_name: yup
        .string()
        .required('Fist Name is required')
        .matches(nameRegExp, 'Your first name must contain only letters'),
      last_name: yup
        .string()
        .required('Last Name is required')
        .matches(nameRegExp, 'Your last name must contain only letters'),
      middle_name: yup
        .string()
        .nullable()
        .matches(nameRegExp, 'Your middle name must contain only letters'),
      Tax_ID: yup.string().when([], {
        is: () => isRequired('Tax_ID'),
        then: (_schema) => _schema.required('Tax ID is required'),
        otherwise: (_schema) => _schema.nullable(),
      }),
      title: yup.string().notRequired(),
      gender: yup.string().notRequired(),
      zip_code: yup.string().when([], {
        is: () => isRequired('zip_code'),
        then: (_schema) => _schema.required('Zip Code is required'),
        otherwise: (_schema) => _schema.nullable(),
      }),
      mol_id: yup.string().notRequired(),
      mol_id_card: yup.mixed().notRequired(),
    })

  const defaultValues = {
    contractor_type: data?.contractor_type,
    country_id: data?.country?.id,
    state_id: data?.state?.id,
    city: data?.city,
    currency_id:
      data?.currency?.id ??
      (isDeEmployee
        ? staticData?.currencies?.find((currency) => currency.code === 'AED')
            ?.id
        : undefined),
    address: data?.address,
    phone: data?.phone,
    birth_date: data?.birth_date,
    document_type: isEmployeeOrDeEmployee
      ? passwordDocTypeId
      : data?.document?.type?.id,
    document_number: data?.document?.number,
    Country_of_Citizenship: data?.Country_of_Citizenship?.id,
    Country_of_Tax_Residence: data?.Country_of_Tax_Residence?.id,
    first_name: data?.first_name,
    last_name: data?.last_name,
    title: data?.title,
    gender: data?.gender,
    middle_name: data?.middle_name,
    Tax_ID: data?.Tax_ID,
    zip_code: data?.zip_code,
    mol_id: data?.wps_details?.mol_id,
    mol_id_card: data?.wps_details?.mol_id_card,
  }

  const {
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema(countries)),
    defaultValues,
  })

  const {
    mol_id_card: molIdCard,
    Country_of_Citizenship: countryOfCitizenship,
  } = useWatch({ control })
  const [molIdCardToUploadName, setMolIdCardToUploadName] = useState(null)

  const dispatch = useDispatch()
  const { data: dynamicRequiredFields } = useFetch(
    {
      action: getContractorProfileRequiredFields,
      initResult: [],
      autoFetch: isDeEmployee && !!countryOfCitizenship,
      body: { nationality_id: countryOfCitizenship },
    },
    [countryOfCitizenship],
  )
  const notifications = useFetch({
    autoFetch: false,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })
  const updateContractor = useFetch({
    autoFetch: false,
    initResult: null,
    action: updateContractorProfile,
    onComplete: (data) => {
      window.analytics.track('Updated profile settings', {
        'email-id': user?.email,
      })
      if (data?.success === false) {
        toastr.error(data?.message, 'Error')
        return
      }
      toastr.success('Profile updated successfully')
      dispatch(updateUserInfo(data || {}))
      dispatch(updateUserProfileInfo(data || {}))
      notifications.startFetch()
      if (isCompletion) {
        onCompleted()
      }
      if (contractRef) {
        if (isVerified) {
          history.push(`/contract/detail?id=${contractRef?.ref}`)
          dispatch(updateContractRef(null))
        } else {
          history.push('/settings#kyc')
        }
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const update = useFetch({
    action: updateContractorType,
    onComplete: (data, body) => {
      dispatch(loginUserSuccessful(data))
      dispatch(updateUserProfileInfo({ contractor_type: body?.type }))
    },
    onError: toastr.error,
  })

  const { startFetch: uploadMolIdCardFile, isLoading: isUploadingMolIdCard } =
    useFetch({
      action: uploadTempFile,
      onComplete: (data) => {
        setValue('mol_id_card', data?.path)
      },
      onError: (error) => toastr.error(error),
    })

  function onSubmit(v) {
    // @todo: update to use dynamic schema validation.
    const isRequiredMolIdMissing =
      isDeEmployee && dynamicRequiredFields?.includes('mol_id') && !v.mol_id
    const isRequiredMolIdCardMissing =
      isDeEmployee &&
      dynamicRequiredFields?.includes('mol_id_card') &&
      !v.mol_id_card
    const isRequiredGenderMissing =
      isDeEmployee && dynamicRequiredFields?.includes('gender') && !v.gender
    const isRequiredTitleMissing =
      isDeEmployee && dynamicRequiredFields?.includes('title') && !v.title
    if (isRequiredMolIdMissing) {
      setError('mol_id', {
        type: 'required',
        message: 'MOL ID is required',
      })
    }
    if (isRequiredMolIdCardMissing) {
      setError('mol_id_card', {
        type: 'required',
        message: 'MOL ID Card is required',
      })
    }
    if (isRequiredTitleMissing) {
      setError('title', {
        type: 'required',
        message: 'Title is required',
      })
    }
    if (isRequiredGenderMissing) {
      setError('gender', {
        type: 'required',
        message: 'Gender is required',
      })
    }

    const isRequiredFieldMissing =
      isRequiredMolIdMissing ||
      isRequiredMolIdCardMissing ||
      isRequiredTitleMissing ||
      isRequiredGenderMissing
    if (isRequiredFieldMissing) {
      return
    }

    onStart()
    updateContractor.startFetch(v)
  }
  const otherCountries = staticData?.other_countries
    ? [...(staticData?.other_countries ?? [])]
    : []

  const mapCountry = (e) => {
    return { value: e.id, label: e.name, flag: `/${e.svg}` }
  }

  const nationalities = [
    ...(staticData?.countries ?? []),
    ...otherCountries,
  ].map(mapCountry)
  const allCountries = staticData?.countries?.map(mapCountry)

  return (
    <CardBody className='p-0 pt-3 pt-md-4'>
      <Form autoComplete='off' ref={ref} onSubmit={handleSubmit(onSubmit)}>
        {isEmployeeOrDeEmployee ? null : (
          <>
            <Col md={6} sm={12} className='mb-3'>
              <FormGroup className='mb-4' disabled={isVerified}>
                <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                  Contractor Type
                </Label>
                <ControlledSelect
                  control={control}
                  name='contractor_type'
                  error={errors?.contractor_type}
                  options={types}
                  isDisabled={isVerified}
                  controlOnChange={(v) => {
                    if (v?.value === contractorTypes.ENTITY) {
                      history.push('/complete-company', {
                        action: switchingContractorToEntity,
                      })
                    } else {
                      update.startFetch({ type: v?.value })
                    }
                  }}
                />
              </FormGroup>
            </Col>

            <Col sm={12} className='mb-3'>
              <Alert color='info'>
                Spell your name exactly as it’s shown on your passport or ID
                card. After you’ve been verified, you can’t edit some of the
                fields
              </Alert>
            </Col>
          </>
        )}
        <Row className='p-0 m-0'>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
              disabled={isVerified}
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                First Name
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.first_name}
                name='first_name'
                disabled={isVerified}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              className='d-inline p-0 m-0'
              row={!isCompletion}
              disabled={isVerified}
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Middle name (Optional)
              </Label>
              <ControlledInput
                control={control}
                name='middle_name'
                error={errors.middle_name}
                disabled={isVerified}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
              disabled={isVerified}
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Last Name
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.last_name}
                name='last_name'
                disabled={isVerified}
              />
            </FormGroup>
          </Col>

          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Title
                {dynamicRequiredFields?.includes('title') && (
                  <span className='text-danger font-size-16 mx-1'>*</span>
                )}
              </Label>
              <ControlledSelect
                options={[
                  {
                    label: 'Mr',
                    value: 'Mr',
                  },
                  {
                    label: 'Ms',
                    value: 'Ms',
                  },
                  {
                    label: 'Mrs',
                    value: 'Mrs',
                  },
                ]}
                control={control}
                id='title'
                name='title'
              />
            </FormGroup>
          </Col>

          {dynamicRequiredFields?.includes('gender') ? (
            <Col md={6} sm={12} className='mb-3'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                  Gender
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  options={[
                    {
                      label: 'Male',
                      value: 'Male',
                    },
                    {
                      label: 'Female',
                      value: 'Female',
                    },
                  ]}
                  control={control}
                  id='gender'
                  name='gender'
                />
              </FormGroup>
            </Col>
          ) : null}
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Nationality
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledSelect
                options={nationalities}
                control={control}
                name='Country_of_Citizenship'
                error={errors.Country_of_Citizenship}
              />
            </FormGroup>
          </Col>

          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
            >
              <ControlledPhoneInput
                control={control}
                error={errors.phone}
                name='phone'
                label={
                  <>
                    Phone Number{' '}
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </>
                }
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Tax ID
                {isRequired('Tax_ID') ? (
                  <span className='text-danger font-size-16 mx-1'>*</span>
                ) : (
                  ' (optional)'
                )}
              </Label>
              <ControlledInput
                control={control}
                name='Tax_ID'
                error={errors.Tax_ID}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Tax Residence
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledSelect
                options={allCountries}
                control={control}
                error={errors.Country_of_Tax_Residence}
                name='Country_of_Tax_Residence'
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              className='d-inline p-0 m-0'
              style={{ zIndex: 5, position: 'relative' }}
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Currency
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledSelect
                options={staticData?.currencies?.map((e) =>
                  mapCurrencyToOption(e, 'id'),
                )}
                control={control}
                name='currency_id'
                error={errors.currency_id}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
            >
              <Label
                className='col-form-label p-0 m-0 mb-2 font-size-14'
                htmlFor='address'
              >
                Address
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.address}
                name='address'
                id='address'
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                {idDocLabel}
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <InputGroup className='d-flex flex-nowrap'>
                <ControlledInput
                  placeholder={idDocLabel}
                  name='document_number'
                  control={control}
                  error={errors.document_number}
                  showError={false}
                  className={
                    isEmployeeOrDeEmployee ? undefined : 'rounded-right-0'
                  }
                  wrapperClassName='flex-grow-1'
                />
                {isEmployeeOrDeEmployee ? null : (
                  <InputGroupAddon style={{ zIndex: 1 }} addonType='append'>
                    <InputGroupText
                      className={classNames({
                        'p-0': true,
                        'border-danger': !!errors.document_type,
                      })}
                      style={{ width: '130px', border: 0 }}
                    >
                      <ControlledSelect
                        isSearchable={false}
                        options={staticData?.document_types?.map((e) => ({
                          value: e.id,
                          label: e.name,
                        }))}
                        classNamePrefix='RS-Addon'
                        control={control}
                        name='document_type'
                        error={errors.document_type}
                        wrapperClassName='w-100'
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>

              {errors?.document_number && (
                <span className='text-danger font-size-12'>
                  {errors?.document_number.message}
                </span>
              )}
            </FormGroup>
          </Col>

          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Country
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledSelect
                error={errors.country_id}
                control={control}
                name='country_id'
                options={
                  countries
                    ? countries.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.id,
                        flag: `/${c.svg}`,
                      }))
                    : []
                }
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='mb-0'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Birth Date
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              {isVerified ? (
                <div
                  title={data?.birth_date}
                  className='form-control bg-light tw-truncate'
                >
                  {data?.birth_date}
                </div>
              ) : (
                <ControlledDatePicker
                  control={control}
                  name='birth_date'
                  error={errors.birth_date}
                  maxDate={
                    new Date(moment().subtract(18, 'years').toISOString())
                  }
                  disabled={isVerified}
                  placeholder='Birth date'
                />
              )}
            </FormGroup>
          </Col>
          {!!countries?.find((e) => e.id === watch('country_id'))?.states
            ?.length && (
            <Col md={6} sm={12} className='mb-3'>
              <FormGroup className='d-inline p-0 m-0' row={!isCompletion}>
                <Label
                  htmlFor='billing-name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  State
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.state_id}
                  control={control}
                  name='state_id'
                  options={
                    watch('country_id')
                      ? countries
                          ?.find((e) => e.id === watch('country_id'))
                          ?.states?.map((s) => ({
                            label: s.name,
                            value: s.id,
                          }))
                      : []
                  }
                />
              </FormGroup>
            </Col>
          )}
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup className='d-inline p-0 m-0' row={!isCompletion}>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                City
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='city'
                error={errors.city}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0'
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Zip Code{' '}
                {isRequired('zip_code') ? (
                  <span className='text-danger font-size-16 mx-1'>*</span>
                ) : (
                  '(optional)'
                )}
              </Label>
              <ControlledInput
                control={control}
                error={errors.zip_code}
                name='zip_code'
              />
            </FormGroup>
          </Col>
          {deEmployeeHasEmployeeId && (
            <Col md={6} sm={12} className='mb-3'>
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                Employee ID
              </Label>
              <div
                title={data?.employee_identifier}
                className='form-control bg-light tw-truncate'
              >
                {data?.employee_identifier}
              </div>
            </Col>
          )}

          {dynamicRequiredFields?.includes('mol_id') && (
            <Col md={6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0'
              >
                <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                  MOL ID{' '}
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput control={control} id='mol_id' name='mol_id' />
              </FormGroup>
            </Col>
          )}
          {dynamicRequiredFields?.includes('mol_id_card') && (
            <Col md={6} sm={12} className='mb-3'>
              <FormGroup>
                <Label>
                  MOL ID Card{' '}
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <DropzoneInput
                  maxSize={FILE_SIZE_LIMITS_IN_BYTES.TWO_MB}
                  name='mol_id_card'
                  className={classNames(
                    'gap-12 align-items-center flex-wrap font-size-14 cursor-pointer px-3 py-2 bg-primary-20 tw-flex tw-justify-center',
                    errors?.mol_id_card
                      ? 'text-danger bg-soft-danger'
                      : 'text-primary-100 bg-primary-20 border-primary-100',
                    {
                      'justify-content-between': !!molIdCard,
                    },
                  )}
                  error={errors.mol_id_card}
                  accept={{
                    'image/*': ['.jpeg', '.png', '.jpg'],
                    'application/pdf': ['.pdf'],
                  }}
                  style={{ minHeight: 180 }}
                  onDropAccepted={(files) => {
                    setMolIdCardToUploadName(files?.[0]?.name)
                    uploadMolIdCardFile({
                      file: files[0],
                      type: 'mol_id_cards',
                    })
                  }}
                >
                  {isUploadingMolIdCard ? (
                    <Loader />
                  ) : molIdCardToUploadName || molIdCard ? (
                    <>
                      {molIdCardToUploadName || molIdCard}

                      <button
                        className='p-0.5 rp-btn-nostyle text-red-90 d-flex'
                        type='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setValue('mol_id_card', null)
                          setMolIdCardToUploadName(null)
                        }}
                      >
                        <XCircle size={20} weight='fill' />
                      </button>
                    </>
                  ) : (
                    <>
                      <FilePlus size={20} weight='duotone' />
                      <div className='d-none d-md-block'>
                        Drop files here or click to upload
                      </div>
                      <div className='d-md-none'>Click to upload</div>

                      <div className='text-secondary-80 rp-font-normal'>
                        Max file size 2MB
                      </div>
                    </>
                  )}
                </DropzoneInput>
              </FormGroup>
            </Col>
          )}
        </Row>
        {isCompletion ? (
          <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100'>
            <button className='btn btn-primary btn-block' type='submit'>
              {updateContractor.isLoading && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              )}
              Next
            </button>
          </Row>
        ) : (
          <>
            <div className='w-100 divider border-top my-md-4 my-3' />
            <Row className='p-0 m-0'>
              <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100'>
                <Button
                  type='submit'
                  loading={updateContractor.isLoading}
                  disabled={updateContractor.isLoading}
                  style={{ width: 146 }}
                >
                  Save
                </Button>
              </Row>
            </Row>
          </>
        )}
      </Form>
    </CardBody>
  )
})

export default ProfileInfoNew
