import { ShieldCheck, ShieldWarning } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'
import { Avatar, cn } from 'ui'

import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { contractorTypes, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { resendContractInvite } from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'
import SelectSignatoryDropdown from './SelectSignatoryDropdown'
import InvitationHistoryModal from './components/InvitationHistoryModal'
import { WorkerDetailsSideMenu } from './worker-details-side-menu'

const rpCircleBadge = (
  <div className='tw-absolute -tw-bottom-1.5 -tw-right-2 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-white tw-p-1 tw-ring-1 tw-ring-surface-30'>
    <img
      src='/logo/rp-icon.png'
      className='tw-size-5 tw-object-cover'
      alt='RemotePass logo icon'
    />
  </div>
)

export default function CardContact({
  label,
  isKyc,
  isContractor,
  isRp,
  isFteProvider,
  user,
  contract,
  showRpBadge = false,
}) {
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const fullName = getFullName(user)

  return (
    <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-4 tw-p-4 md:tw-gap-6 md:tw-p-6'>
      <Avatar
        size='special'
        name={fullName || user?.email}
        photo={
          isContractor
            ? user?.photo
            : isRp || isFteProvider
              ? user?.photo
              : contract?.company_logo
        }
        label={label}
        customFlag={
          showRpBadge ? (
            rpCircleBadge
          ) : isContractor ? (
            <KycVerificationBadge
              kycVerified={isKyc}
              className='tw-absolute -tw-bottom-0.5 -tw-right-0.5'
            />
          ) : null
        }
      />

      <div>
        <h5 className='tw-mb-0 tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-bold tw-text-secondary-100'>
          {isContractor ? (
            <>
              <span>{fullName || 'Invitation pending'}</span>
              {isFteProvider ? null : (
                <WorkerDetailsSideMenu
                  user={user}
                  label={label}
                  contract={contract}
                />
              )}
            </>
          ) : (
            <>
              {isRp
                ? user?.name
                : contract?.company_name
                  ? contract?.company_name
                  : fullName}
            </>
          )}
        </h5>

        {!user?.email ? null : (
          <h6 className='tw-mb-0 tw-mt-1 tw-text-sm tw-font-medium tw-text-text-80'>
            {user?.email}
          </h6>
        )}

        <div className='tw-mt-2 tw-flex tw-gap-2'>
          <BadgeV2 status='secondary'>{label}</BadgeV2>

          {!isDeContract && isContractor && user?.type && (
            <BadgeV2 status='secondary'>
              {user?.type === contractorTypes.ENTITY ? 'Entity' : 'Individual'}
            </BadgeV2>
          )}
        </div>
      </div>
    </div>
  )
}

export const ActionButton = ({
  isAdmin,
  onInvite,
  onSign,
  onCancel,
  isLoading,
  type: blockType,
  data,
  clientSigned,
  contractorSigned,
  employeeInvitation,
  onUpdateContract,
  onEmployeeInvite,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const resend = useFetch({
    action: resendContractInvite,
    onComplete: () => {
      toastr.success('Invitation send successfully')
    },
    withAdminAccess: true,
  })

  const [showInvitationHistory, setShowInvitationHistory] = useState(false)

  const isEmployeeOnboarded = (timeline) => {
    return (
      timeline.find((item) => item.name.toLowerCase() === 'employee onboarded')
        ?.completed === 1
    )
  }

  const getOptions = () => {
    if (blockType === 'client') {
      if (!data?.contractor) {
        return {
          label: `Invite ${data?.contractor_name || 'Contractor'}`,
          color: 'primary',
          action: onInvite,
          disabled: !hasAccess(permissions.InviteToContracts),
        }
      } else if (
        !clientSigned &&
        user?.type === 'client' &&
        data?.signatory?.id === user?.id
      ) {
        return {
          label: 'Sign',
          color: 'primary',
          action: onSign,
          disabled: !hasAccess(permissions.SignContracts) || !data.can_sign,
        }
      } else if (
        user?.type === 'client' &&
        data.type === CONTRACT_TYPES.DIRECT_EMPLOYEE &&
        data.file
      ) {
        return {
          label: 'Signed',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      } else if (!clientSigned && user?.type === 'client') {
        return {
          label: 'Sign',
          color: 'primary',
          type: 'dropdown',
          disabled: !data.can_sign,
        }
      } else if (!clientSigned && user?.type !== 'client') {
        return data?.can_cancel_invitation && data?.type !== 'Full Time'
          ? {
              label: 'Cancel Invitation',
              color: 'danger-cancel',
              action: onCancel,
              disabled: !hasAccess(permissions.CancelInvitations),
            }
          : {
              label:
                data.created_by === 'client'
                  ? data.type === CONTRACT_TYPES.DIRECT_EMPLOYEE && data.file
                    ? 'Signed'
                    : 'Created'
                  : 'Invited',
              color: 'success-disabled',
              action: () => {},
              disabled: true,
            }
      } else if (clientSigned) {
        return {
          label: 'Signed',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
    if (blockType === 'contractor') {
      if (!data?.client) {
        return {
          label: 'Invite Client',
          color: 'primary',
          action: onInvite,
          disabled: false,
        }
      } else if (!contractorSigned && user?.type === 'contractor') {
        return {
          label:
            !isEmployeeOnboarded(data?.timeline) &&
            data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
              ? 'Pending Onboarding'
              : 'Sign',
          color: 'primary',
          action: onSign,
          disabled: !data.can_sign,
        }
      } else if (!contractorSigned && user?.type !== 'contractor') {
        return data?.can_cancel_invitation && data?.type !== 'Full Time'
          ? {
              label: 'Cancel Invitation',
              color: 'danger-cancel',
              action: onCancel,
              disabled: !hasAccess(permissions.CancelInvitations),
            }
          : {
              label:
                data.created_by === 'contractor'
                  ? 'Created'
                  : data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE && data?.file
                    ? 'Signed'
                    : 'Invited',
              invited: true,
              color: 'success-disabled',
              action: () => {},
              disabled: true,
            }
      } else if (
        data?.type === 'Full Time' &&
        data?.status?.id === 4 &&
        !data?.employee &&
        user?.type === userTypes.CONTRACTOR
      ) {
        return {
          label: 'Invite Employee',
          color: 'primary',
          action: onEmployeeInvite,
          disabled: !hasAccess(permissions.InviteToContracts),
        }
      } else if (contractorSigned) {
        return {
          label: 'Signed',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
    if (blockType === 'employee') {
      if (!data?.employee_invited) {
        return {
          label: 'Invite',
          color: 'primary',
          action: onInvite,
          disabled: !hasAccess(permissions.InviteToContracts),
        }
      } else {
        return {
          label: 'Invited',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
  }
  const buttonOptions = getOptions()

  const isButtonDisabled =
    buttonOptions.disabled || isAdmin || [5, 6, 7].includes(data?.status?.id)

  return (
    <div className='d-flex flex-column m-0 w-100'>
      {buttonOptions.type === 'dropdown' ? (
        <SelectSignatoryDropdown
          onSign={onSign}
          contract={data}
          onCancel={onUpdateContract}
          onAssigned={onUpdateContract}
          isAdmin={isAdmin}
          isDisabled={!data.can_sign}
        />
      ) : (
        <Button
          block
          onClick={buttonOptions.action}
          disabled={isButtonDisabled}
          loading={!!isLoading}
          color={buttonOptions.color}
          tag={isButtonDisabled ? 'div' : undefined}
        >
          {buttonOptions.label}
        </Button>
      )}
      {(!!data.invitation_url || employeeInvitation) &&
        ['contractor', 'employee'].includes(blockType) &&
        (user?.type === userTypes.COMPANY || isAdmin) &&
        (data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
          ? !isEmployeeOnboarded(data?.timeline)
          : true) && (
          <CopyToClipboard
            text={data.invitation_url || data.employee_invitation_url}
            onCopy={() => toastr.info('Link copied')}
          >
            <div className='text-primary cursor-pointer font-size-12 mt-1'>
              Copy Invitation Link
            </div>
          </CopyToClipboard>
        )}
      {isAdmin &&
        ((!contractorSigned && blockType === 'contractor' && !!data?.client) ||
          (!clientSigned && blockType === 'client' && !!data?.contractor) ||
          employeeInvitation) && (
          <button
            className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
            onClick={() => setShowInvitationHistory(true)}
          >
            Email history
          </button>
        )}

      {isAdmin &&
        ((!contractorSigned && blockType === 'contractor' && !!data?.client) ||
          (!clientSigned && blockType === 'client' && !!data?.contractor)) && (
          <button
            className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
            onClick={() => {
              resend.startFetch(
                blockType === 'client'
                  ? { contract_id: data?.id, destination: 'client' }
                  : { contract_id: data?.id },
              )
            }}
          >
            Resend Invitation
          </button>
        )}
      {showInvitationHistory && (
        <InvitationHistoryModal
          user={blockType === 'client' ? data?.client : data?.contractor}
          show={showInvitationHistory}
          hide={() => setShowInvitationHistory(false)}
        />
      )}
    </div>
  )
}

const kycBadgeTargetId = 'kyc_verification_badge'
function KycVerificationBadge({ kycVerified = false, className }) {
  const Icon = kycVerified ? ShieldCheck : ShieldWarning

  return (
    <>
      <Icon
        className={cn(
          'tw-size-6 tw-rounded-full tw-p-1 tw-text-white tw-ring-2 tw-ring-white',
          kycVerified ? 'tw-bg-primary-100' : 'tw-bg-secondary-80',
          className,
        )}
        weight='fill'
        id={kycBadgeTargetId}
      />

      <UncontrolledTooltip placement='top' target={kycBadgeTargetId}>
        {kycVerified
          ? 'This contractor has completed the KYC verification process'
          : 'This contractor has not completed the KYC verification process'}
      </UncontrolledTooltip>
    </>
  )
}
