import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import ConfirmationModal from '../../components/Common/ConfirmationModal'
import Button from '../../components/ui/button'
import { BANK_TRANSFER } from '../../core/config/payment-methods'
import { useFetch } from '../../helpers/hooks'
import {
  checkTransferStatus,
  createTransactionTransfer,
  getScheduledTaskStatus,
  getTransactionRef,
} from '../../services/api'
import { scheduledTaskStatus } from '../payInvoices'

let timeout

export function AedPayrollActions({
  rawTotalToPay,
  onCompleted,
  buttonText,
  buttonIcon,
  fromDetails,
}) {
  const [isProcessing, setIsProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const preparationTimeout = useRef()

  const { startFetch: createTransfer } = useFetch({
    action: createTransactionTransfer,
    onComplete: (data) => {
      if (
        data.scheduled_task_processes[0].status ===
        scheduledTaskStatus.processing
      ) {
        setIsProcessing(true)
      } else if (
        data.scheduled_task_processes[0].status === scheduledTaskStatus.success
      ) {
        _checkTransferStatus()
      }
    },
  })

  const {
    startFetch: _getTransactionPreparationStatus,
    data: transactionDetails,
  } = useFetch({
    action: getScheduledTaskStatus,
    onComplete: (data) => {
      if (data.processes[0].status === scheduledTaskStatus.processing) {
        setIsPreparing(true)
      } else if (data.processes[0].status === scheduledTaskStatus.success) {
        setIsPreparing(false)
        createTransfer({ quote_id: data.processes[0].payload.quote_id })
      }
    },
  })

  const { startFetch: beginProcessing } = useFetch({
    body: {
      grouped: 1,
      payment_method_id: BANK_TRANSFER.id,
      payment_item_ids: useSelector((state) => state?.Payment?.toPay),
    },
    action: getTransactionRef,
    onComplete: (data) => {
      _getTransactionPreparationStatus({ id: data.id })
    },
  })

  const { startFetch: _checkTransferStatus } = useFetch({
    action: checkTransferStatus,
    onComplete: (data) => {
      if (
        data.scheduled_task_processes[0].status === scheduledTaskStatus.success
      ) {
        setIsProcessing(false)
        setShowModal(false)
        toastr.success('Transaction completed successfully.')
        onCompleted?.()
      }
    },
    body: { quoteId: transactionDetails?.processes[0]?.payload?.quote_id },
  })

  useEffect(() => {
    if (isPreparing && !preparationTimeout.current) {
      preparationTimeout.current = setInterval(() => {
        _getTransactionPreparationStatus({ id: transactionDetails?.id })
      }, 5 * 1000)
    } else if (!isPreparing) {
      clearInterval(preparationTimeout.current)
      preparationTimeout.current = null
    }

    return () => clearInterval(timeout)
  }, [isPreparing])

  useEffect(() => {
    if (isProcessing && !timeout) {
      timeout = setInterval(() => {
        _checkTransferStatus()
      }, 5 * 1000)
    } else if (!isProcessing) {
      clearInterval(timeout)
      timeout = null
    }

    return () => clearInterval(timeout)
  }, [isProcessing])

  const handleClick = () => {
    if (fromDetails) {
      setShowConfirm(true)
    } else {
      setShowModal(true)
      beginProcessing()
    }
  }

  return (
    <>
      <Button
        type='button'
        disabled={!rawTotalToPay}
        onClick={handleClick}
        icon={buttonIcon}
      >
        {buttonText}
      </Button>

      <Modal isOpen={showModal}>
        <ModalHeader>Processing payroll</ModalHeader>
        <ModalBody>
          <div className='tw-text-sm tw-text-disabled'>
            Hold tight! This may take a few minutes.
          </div>
          <div className='tw-my-3 tw-h-3 tw-animate-pulse tw-rounded tw-bg-primary-70' />
        </ModalBody>
      </Modal>

      <ConfirmationModal
        content='Are you sure you want to make this payment?'
        title='Pay'
        isOpen={showConfirm}
        onConfirm={() => {
          setShowConfirm(false)
          setShowModal(true)
          beginProcessing()
        }}
        toggle={() => setShowConfirm(false)}
        caption='Yes'
      />
    </>
  )
}
