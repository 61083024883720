import { ArrowRight } from '@phosphor-icons/react'
import { AvForm } from 'availity-reactstrap-validation'
import { format } from 'date-fns'
import React, { useState } from 'react'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  adminFullTimeAddition,
  adminFullTimeDeduction,
  fullTimeAddition,
  fullTimeDeduction,
} from '../../../services/api'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'

const adjustmentsTypes = [
  {
    id: 1,
    name: 'Addition',
    reasons: [
      {
        id: 1,
        name: 'bonus',
        label: 'Bonus',
        sub_types: [
          {
            id: 1,
            name: 'commission',
            label: 'Commission',
          },
          {
            id: 2,
            name: 'performance',
            label: 'Performance',
          },
          {
            id: 3,
            name: 'sign-on',
            label: 'Sign-on',
          },
          {
            id: 4,
            name: 'holiday',
            label: 'Holiday',
          },
          {
            id: 5,
            name: 'retention',
            label: 'Retention',
          },
        ],
      },

      {
        id: 2,
        name: 'overtime',
        label: 'Overtime',
        sub_types: [
          {
            id: 5,
            name: 'weekend',
            label: 'Weekend',
          },
          {
            id: 5,
            name: 'public holiday',
            label: 'Public holiday',
          },
          {
            id: 5,
            name: 'working days',
            label: 'Working days',
          },
        ],
      },
      {
        id: 3,
        name: 'allowance',
        label: 'Allowance',
        sub_types: [
          {
            id: 5,
            name: 'transportation',
            label: 'Transportation',
          },
          {
            id: 5,
            name: 'housing',
            label: 'Housing',
          },
          {
            id: 5,
            name: 'work from home',
            label: 'Work from home',
          },
          {
            id: 5,
            name: 'pension',
            label: 'Pension',
          },
          {
            id: 5,
            name: 'insurance',
            label: 'Insurance',
          },
          {
            id: 5,
            name: 'meal',
            label: 'Meal',
          },
          {
            id: 5,
            name: 'education',
            label: 'Education',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Deduction',
    reasons: [
      {
        id: 6,
        name: 'unauthorized absence',
        label: 'Unauthorized absence',
      },
      {
        id: 6,
        name: 'advance recovery',
        label: 'Advance recovery',
      },
      {
        id: 6,
        name: 'loss/damage',
        label: 'Loss/Damage',
      },
      {
        id: 6,
        name: 'overpayment',
        label: 'Overpayment',
      },
      {
        id: 6,
        name: 'other',
        label: 'Other',
      },
    ],
  },
]
const AdjustmentsModal = ({ show, hide, contract, updated, isAdmin }) => {
  const [actionType, setActionType] = useState('')
  const [amount, setAmount] = useState('')
  const [reason, setReason] = useState(null)
  const [subType, setSubType] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(null)
  const [details, setDetails] = useState(null)

  const showSubType = actionType?.value === 1

  const adjust = useFetch({
    action:
      actionType?.value === 1
        ? isAdmin
          ? adminFullTimeAddition
          : fullTimeAddition
        : isAdmin
          ? adminFullTimeDeduction
          : fullTimeDeduction,
    withAdminAccess: isAdmin,
    onComplete: (data, body) => {
      updated()
      window.analytics.track('Added adjustment', {
        contract_id: contract?.id,
        contract_type: contract?.type?.name,
        contract_status: contract?.status?.name,
        adjust_id: data?.id,
        adjust_type: data?.name,
        adjust_reason: body?.reason_id,
        sub_type: body?.sub_type,
        amount: body?.amount,
        currency: contract?.salary_currency?.code,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const currentDate = new Date()
  const nextMonth = currentDate.setMonth(currentDate.getMonth() + 1, 1)
  const minDate = contract.is_input_allowed ? new Date() : nextMonth
  const isAmountMandatory = reason?.value !== 'overtime'
  return (
    <SideMenu
      isOpen={show}
      onClose={hide}
      className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
    >
      <SideMenuHeader toggle={hide}>Adjust Payment</SideMenuHeader>
      <AvForm
        onValidSubmit={(e, v) => {
          if (
            (amount || !isAmountMandatory) &&
            actionType &&
            reason &&
            details &&
            effectiveDate &&
            (subType || !showSubType)
          ) {
            adjust.startFetch({
              contract_id: contract?.id,
              type: reason?.value,
              sub_type: subType?.value,
              note: details,
              effective_date: format(effectiveDate, 'yyyy-MM-dd'),
              amount,
            })
          } else {
            toastr.error('You need to fill all required fields')
          }
        }}
      >
        <SideMenuBody>
          <PrimaryAlert className='!tw-mb-3 !tw-p-3 !tw-text-systemBlue-130'>
            Adjustments must be entered as gross amounts. Note that
            contributions and tax burdens will be levied on the month’s total
            compensation.{' '}
            <a
              href='https://help.remotepass.com/en/articles/8531916-how-to-adjust-payroll-addition-or-deduction-for-an-eor-contract'
              target='_blank'
              rel='noreferrer'
            >
              Learn more <ArrowRight className='tw-inline-block' />
            </a>
          </PrimaryAlert>

          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>
              Action type
              <span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <CustomSelect
              className='border-0'
              name='Action_id'
              onChange={(value) => {
                setActionType(value)
                setReason(null)
              }}
              isSearchable={false}
              value={actionType}
              options={adjustmentsTypes?.map((e) => ({
                label: e?.name,
                value: e?.id,
                reasons: e?.reasons,
              }))}
              isOptionDisabled={(option) => option.disabled}
            />
          </FormGroup>
          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>
              Reason<span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <CustomSelect
              className='border-0'
              name='reason_id'
              onChange={(val) => {
                setReason(val)
                setSubType(null)
              }}
              isSearchable={false}
              value={reason}
              options={actionType?.reasons?.map((e) => ({
                label: e?.label,
                value: e?.name,
                sub_types: e?.sub_types,
              }))}
            />
          </FormGroup>
          {showSubType ? (
            <FormGroup className='justify-content-center'>
              <label className='col-form-label pt-0'>
                Sub Type<span className='text-danger font-size-16 ml-1'>*</span>
              </label>
              <CustomSelect
                className='border-0'
                name='reason_id'
                onChange={(val) => {
                  setSubType(val)
                }}
                isSearchable={false}
                value={subType}
                options={reason?.sub_types?.map((e) => ({
                  label: e?.label,
                  value: e?.name,
                }))}
              />
            </FormGroup>
          ) : (
            <></>
          )}
          <FormGroup>
            <label className='col-form-label pt-0'>
              Effective Date
              <span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <CustomDatePicker
              name='effective_date'
              value={effectiveDate}
              handleOnChange={(val) => setEffectiveDate(val)}
              dateFormat='MM-yyyy'
              showMonthYearPicker
              minDate={minDate}
              renderMonthContent={(month, shortMonth, longMonth) => {
                const currentMonth =
                  !contract.is_input_allowed && new Date().getMonth()
                const closedMonth = currentMonth === month
                const tooltip = (
                  <UncontrolledTooltip
                    placement='right'
                    target='cut-off-month-tooltip'
                  >
                    Cut-off: 5th of the month
                  </UncontrolledTooltip>
                )
                return (
                  <div id={closedMonth ? 'cut-off-month-tooltip' : ''}>
                    {closedMonth && tooltip}
                    <span>{shortMonth}</span>
                  </div>
                )
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label className='col-form-label pt-0'>
              Gross Amount
              {isAmountMandatory && (
                <span className='text-danger font-size-16 ml-1'>*</span>
              )}
            </Label>
            <InputGroup>
              <Input
                placeholder='Amount'
                name='amount'
                type='text'
                onKeyPress={(e) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                autoComplete='off'
                value={amount}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setAmount(e.target.value)
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                {contract?.salary_currency?.code}
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>
              Note<span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <Input
              placeholder='Insert note for this adjustment'
              className='form-control'
              type='textarea'
              name='details'
              onChange={(s) => {
                setDetails(s.target.value)
              }}
              value={details}
            />
          </FormGroup>
        </SideMenuBody>

        <SideMenuFooter>
          <Button
            color='light'
            outline
            onClick={hide}
            disabled={adjust?.isLoading}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            loading={adjust?.isLoading}
            disabled={adjust?.isLoading}
          >
            Adjust
          </Button>
        </SideMenuFooter>
      </AvForm>
    </SideMenu>
  )
}

export default AdjustmentsModal
