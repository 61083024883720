import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import NoContent from '../../components/NoContent'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { getUpcomingPaymentList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import PaymentList from '../due-payments-page/paymentList'
import { getPaymentIds } from '../payInvoices'

const UpcomingPayment = ({ history, match }) => {
  const user = useSelector((state) => state.Account?.user)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const profile = useSelector((state) => state.userProfile.userProfile)
  const currency = profile?.company?.currency || profile?.currency
  const [totalToPay, setTotalToPay] = useState(0)

  const updateInvoiceList = () => {
    getUpcomingPaymentList(user?.token)
      .then((r) => {
        if (r.data.success) {
          if (match.params?.id) {
            setData(
              r.data.data.map((e) => ({
                ...e,
                selected: e.id === match.params?.id,
              })),
            )
          } else {
            setData(r.data.data.map((e) => ({ ...e, selected: false })))
          }
        } else {
          toastr.error(r.data.message)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    updateInvoiceList()
  }, [])

  useEffect(() => {
    const selectedElements = data.filter((e) => e.selected)
    if (selectedElements?.length > 1) {
      setTotalToPay(
        selectedElements?.reduce((prev, current) =>
          prev?.total_trans_amount
            ? prev?.total_trans_amount + current?.total_trans_amount
            : prev + current?.total_trans_amount,
        ),
      )
    } else if (selectedElements?.length === 1) {
      setTotalToPay(selectedElements[0]?.total_trans_amount)
    } else {
      setTotalToPay(0)
    }

    const ids = getPaymentIds(selectedElements)
    dispatch(updateToPayList(ids))
  }, [data])

  const formatter = getCurrencyFormatter(currency?.code)

  const isTotalZero = totalToPay === 0

  return (
    <div className='page-content'>
      {loading ? (
        <Loader minHeight='30rem' />
      ) : (
        <>
          <PageHeading>
            <PageHeading.Title>Upcoming Payments</PageHeading.Title>

            <Button
              tag={Link}
              to='/pay-invoices'
              disabled={isTotalZero}
              color={isTotalZero ? 'secondary' : 'primary'}
            >
              Pay {isTotalZero ? '' : formatter.format(totalToPay)}
            </Button>
          </PageHeading>

          <Card>
            <CardBody className='p-0'>
              {data?.length === 0 ? (
                <NoContent
                  headline=''
                  subtitle="You don't have any upcoming payments"
                  image={noTransactions}
                />
              ) : (
                <PaymentList
                  data={data}
                  currency={currency}
                  onInvoiceSelected={(i) => {
                    setData(
                      data.map((inv, idx) => ({
                        ...inv,
                        selected: i === idx ? !inv.selected : inv.selected,
                      })),
                    )
                  }}
                  toggleSelectAll={() => {
                    const isAllSelected = data.every((item) => item?.selected)

                    setData((data) => {
                      return data.map((item) => ({
                        ...item,
                        selected: !isAllSelected,
                      }))
                    })
                  }}
                  onAdvanceSet={(e) => {
                    const ids = getPaymentIds([e])
                    dispatch(updateToPayList(ids))
                    history.push('/pay-invoices/' + e.advance)
                  }}
                />
              )}
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default UpcomingPayment
