import React from 'react'
import { cn } from 'ui'

export function FormSectionTitle({
  title,
  className,
  noBorder = true,
  showRequired,
  subTitle,
}) {
  return (
    <div
      className={cn(
        'tw-mb-6',
        { 'tw-border-t tw-border-surface-30': !noBorder },
        className,
      )}
    >
      {!title ? null : (
        <h5 className='tw-mb-0 tw-text-base tw-font-bold'>
          {title}
          {!showRequired ? null : (
            <span className='tw-ml-1 tw-text-base tw-text-systemRed-100'>
              *
            </span>
          )}
        </h5>
      )}

      {!subTitle ? null : (
        <div className='tw-mt-1 tw-text-sm tw-text-text-80'>{subTitle}</div>
      )}
    </div>
  )
}

export function FormSectionHr() {
  return <hr className='-tw-mx-6 tw-my-8' />
}
