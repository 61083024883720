import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Container, Input, ModalFooter, TabPane } from 'reactstrap'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import TabContent from '../../../components/ui/tabs'
import { useFetch } from '../../../helpers/hooks'
import {
  generateClientDocumentRequest,
  getClientRequestTemplate,
} from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'
import Editor from '../TemplateEditor/Editor'
import { DocumentMessage } from './de-documents-tab'
import { Plus } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

const steps = [
  { id: 0, label: 'Edit', title: '' },
  { id: 1, label: 'Sign', title: '' },
]

function getIndex(arr, id) {
  return arr.findIndex((x) => x.id === id)
}

export default function DeDocumentGenerate() {
  const history = useHistory()
  const params = useParams()
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const fullName = getFullName(user)

  const [activeTab, setActiveTab] = useState(steps[0].id)
  const [sectionsData, setSectionsData] = useState([])

  const showLogo = company?.official_logo_enabled
  let logo = (
    <img
      src={company?.official_logo}
      height={38}
      className='d-block'
      style={{ objectFit: 'contain', maxWidth: 250 }}
    />
  )
  if (!showLogo || !company?.official_logo) {
    logo = <LogoPlaceHolder />
  }

  const { startFetch: generateDocument, isLoading: generatingDocument } =
    useFetch({
      action: generateClientDocumentRequest,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message || 'Something went wrong while generating document',
          )
        } else {
          toastr.success('Document generated successfully')
          goBack()
        }
      },
      onError: (err) => {
        toastr.error(err || 'Something went wrong while generating document')
      },
    })

  const { data: defaultTemplate, isLoading: gettingDefaultTemplate } = useFetch(
    {
      action: getClientRequestTemplate,
      body: { request_id: params?.id },
      autoFetch: !!params?.id,
      onComplete: (data) => {
        setSectionsData([
          { details: data?.content, name: '', is_editable: '1' },
        ])
      },
    },
    [params?.id],
  )

  function handleNext() {
    if (activeTab === steps[0].id) {
      setActiveTab(steps[1].id)
    } else {
      generateDocument({
        request_id: Number(params?.id),
        content: sectionsData[0]?.details,
      })
    }
  }

  const sq = new URLSearchParams(history.location.search)
  const backUrl = sq.get('back')
  const from = sq.get('from')
  const message = sq.get('message')

  function goBack() {
    if (backUrl) {
      history.push(backUrl)
    } else {
      history.push('/contracts')
    }
  }

  return (
    <div>
      <Container fluid className='px-0'>
        <ModalHeader action={goBack}>
          <Steps
            activeTab={getIndex(steps, activeTab)}
            data={steps.map((s) => s.label)}
            className='d-none d-md-flex'
          />
        </ModalHeader>
      </Container>

      {gettingDefaultTemplate ? (
        <Loader minHeight='70vh' />
      ) : (
        <Container>
          <TabContent activeTab={activeTab}>
            {steps.map(({ id, title }) => {
              return (
                <TabPane tabId={id} key={id}>
                  <StepContainer
                    index={id}
                    total={steps.length}
                    title={title}
                    isFlat
                    noFooter
                    cardStyles={{ maxWidth: null }}
                  >
                    <Editor
                      onChange={setSectionsData}
                      logo={logo}
                      hideToolbar
                      sections={sectionsData}
                      placeholders={defaultTemplate?.placeholders || []}
                      readOnly={activeTab === steps[1].id}
                      onlyOneSection
                      noCounter
                      stepsLayout
                      sideBarTopElement={
                        !message ? null : (
                          <div className='p-3'>
                            <DocumentMessage from={from} message={message} />
                          </div>
                        )
                      }
                    />

                    <ModalFooter className='justify-content-between'>
                      {activeTab === steps[0].id ? (
                        <div />
                      ) : (
                        <Button
                          color='light'
                          outline
                          onClick={() => setActiveTab(steps[0].id)}
                          disabled={generatingDocument}
                        >
                          Back
                        </Button>
                      )}

                      <div className='d-flex gap-8'>
                        {activeTab === steps[0].id ? null : (
                          <Input
                            defaultValue={fullName}
                            style={{ width: '100%', maxWidth: 320 }}
                            className='bg-transparent'
                            readOnly
                            disabled
                          />
                        )}

                        <Button
                          onClick={handleNext}
                          disabled={generatingDocument}
                          loading={generatingDocument}
                        >
                          {activeTab === steps[0].id ? 'Continue' : 'sign'}
                        </Button>
                      </div>
                    </ModalFooter>
                  </StepContainer>
                </TabPane>
              )
            })}
          </TabContent>
        </Container>
      )}
    </div>
  )
}

const LogoPlaceHolder = () => {
  return (
    <div className='w-100 d-flex align-items-center justify-content-center'>
      <Link
        to='/settings'
        style={{
          border: '2px dashed var(--surface-30)',
          display: 'flex',
          transition: '.4s',
        }}
        className='p-3 add-logo-link bg-primary-10 hover:bg-primary-20 rounded d-flex align-items-center justify-content-center flex-column'
      >
        <div
          style={{ height: 54, width: 54 }}
          className='rounded-circle bg-primary-20 mb-3 d-flex align-items-center justify-content-center'
        >
          <Plus color='var(--primary)' size={24} />
        </div>
        <div className='font-size-14 font-weight-bold'>Add your logo </div>
        <div className='text-secondary-70 font-size-12 text-center'>
          Include your company logo on your documents
        </div>
      </Link>
    </div>
  )
}
