import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import BadgeX from '../../components/Table/BadgeX'
import { PermissionTooltip } from '../../components/permission-tooltip'
import Button from '../../components/ui/button'
import Bar from '../../components/ui/charts/bar'
import MapChart from '../../components/ui/charts/map'
import Flag, { getFlagUrlFromIso2 } from '../../components/ui/flag'
import PageHeading from '../../components/ui/page-heading'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  getAverageContractCostPerMonthChart,
  getHeadcountOnMap,
  getHeadcountPerCountry,
  getHeadcountPerMonthChart,
  getRetentionChart,
  getSendingPerMonth,
  getTimeOffPerMonthReport,
} from '../../services/api'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import kFormatter from '../../utils/formatters/k-formatter'
import isNill from '../../utils/is-nill'
import { PERMISSION_GROUP } from '../CompanySetting/manage-role'

function zero(element) {
  return element === 0
}
function isTrue(element) {
  return element === true
}

export default function Reports() {
  const history = useHistory()
  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const hasGenerateReportsRole = hasAccess(permissions.ViewDownloadReports)

  if (user?.type === userTypes.CONTRACTOR) {
    history.push('/')
  }

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title subtitle='Here are insights about your team'>
          Reports
        </PageHeading.Title>

        <PageHeading.Action>
          <PermissionTooltip
            id='generate-reports'
            area={PERMISSION_GROUP.REPORTING.name}
            showing={!hasGenerateReportsRole}
          >
            <Button
              tag={Link}
              to='/reports/custom'
              disabled={!hasGenerateReportsRole}
              className='tw-h-fit'
            >
              Generate reports
            </Button>
          </PermissionTooltip>
        </PageHeading.Action>
      </PageHeading>

      <Container fluid className='px-0'>
        <Row className='mx-n3' style={{ gap: 'var(--size-32) 0' }}>
          <Col xs={12} className='px-3'>
            <SpendingPerMonth />
          </Col>

          <Col md={6} className='px-3'>
            <HeadCountMap />
          </Col>

          <Col md={6} className='px-3'>
            <HeadCountPerMonth />
          </Col>

          <Col md={6} className='px-3'>
            <AverageCostChart />
          </Col>

          <Col md={6} className='px-3'>
            <RetentionSection />
          </Col>

          <Col md={6} className='px-3'>
            <HeadCountPerCountry />
          </Col>

          <Col md={6} className='px-3'>
            <TimeoffSection />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export function ChartCard({ children, className, style }) {
  return (
    <div
      className={cx('bg-white rounded border position-relative', className)}
      style={style}
    >
      {children}
    </div>
  )
}

export function ChartHeading({ title, subtitle }) {
  return (
    <div className='px-4 py-4.5'>
      {!title ? null : (
        <h4 className='font-size-24 tw-mb-1 tw-font-bold tw-text-secondary'>
          {title}
        </h4>
      )}

      {!subtitle ? null : (
        <span className='text-secondary tw-mb-0'>{subtitle}</span>
      )}
    </div>
  )
}

function formatPayments(data) {
  const xAxis = []
  const xAxisLong = []
  const values = []

  data.months.forEach((element, i) => {
    xAxis[i] = element.month.short
    xAxisLong[i] = element.month.long
    values[i] = element.total
  })

  return {
    currency: data.currency,
    months: xAxis,
    labels: xAxisLong,
    values,
    current: data.current,
    last: data.last,
    isEmpty: data.is_empty,
  }
}

export function SpendingPerMonth({ hasCompanyChanged }) {
  const { data, isLoading, error } = useFetch(
    {
      action: getSendingPerMonth,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const paymentsData = data ? formatPayments(data) : {}
  return (
    <ChartCard className={{ 'd-none d-block': paymentsData.isEmpty }}>
      {!paymentsData.isEmpty ? null : (
        <Dimmer>
          <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
            Your spending per month data will show here
          </p>
        </Dimmer>
      )}
      <ChartHeading
        title='Spending Per Month'
        subtitle='Your payroll spend per month'
      />

      <Container
        fluid
        className='position-relative rounded-bottom'
        style={{ minHeight: 400 }}
      >
        <SpendingPerMonthChart
          loading={isLoading || (!data && !error)}
          error={error}
          paymentsData={paymentsData}
        />
      </Container>
    </ChartCard>
  )
}

function getCurrencyTooltipFormatter(format) {
  return (args) => {
    const { series, seriesIndex, dataPointIndex } = args

    return format(series[seriesIndex][dataPointIndex])
  }
}

function SpendingPerMonthChart({ loading, paymentsData, error }) {
  if (loading || !paymentsData) {
    return (
      <Row>
        <Col md={4}>
          <div className='py-4'>
            <Shimmer width={200} height={53} />
          </div>
          <div className='py-4'>
            <Shimmer width={300} height={53} />
          </div>
        </Col>
        <Col md={8}>
          <Shimmer width='100%' height={365} />
        </Col>
      </Row>
    )
  }

  const currency = paymentsData?.currency
  const formatter = getCurrencyFormatter(currency?.code)

  if (error) {
    return (
      <Dimmer className='bg-gray-100 flex-column rounded-bottom tw-text-center'>
        <p className='mb-0 text-slate-500 tw-text-xl'>
          Your spending per month data will show here
        </p>
        <p className='mb-0 mt-1 text-danger text-size-10'>There was an error</p>
      </Dimmer>
    )
  }

  return (
    <Row>
      <Col md={4}>
        <InfoBlock
          label='This Month'
          amount={formatter.format(paymentsData.current.total)}
          growth={paymentsData.current.growth}
          className='py-4 border-top'
        />
        <InfoBlock
          label='Last month'
          amount={formatter.format(paymentsData.last.total)}
          growth={paymentsData.last.growth}
          className='py-4 border-top'
        />
      </Col>

      <Col
        md={8}
        className={cx({ 'blur-sm pointer-events-none': paymentsData.isEmpty })}
      >
        <Bar
          className='apexcharts-with-hover'
          series={[{ name: 'Profit', data: paymentsData.values }]}
          xAxisCategories={paymentsData.months}
          labels={paymentsData.labels}
          yAxisFormatter={(val) => (currency?.symbol ?? '') + kFormatter(val)}
          formatTooltipValue={(args) => {
            const { series, dataPointIndex, seriesIndex } = args

            const tooltipValue =
              'Spend: ' + formatter.format(series[seriesIndex][dataPointIndex])

            return tooltipValue
          }}
          fillColors={[chartsColors.gray, chartsColors.blue]}
        />
      </Col>
    </Row>
  )
}

function renderMarkerTooltip(data) {
  const info = [
    {
      label: `Contractors: ${data?.contractors_count}`,
      color: chartsColors.blue,
    },
    { label: `EOR: ${data?.eor_count}`, color: chartsColors.orange },
  ]?.filter(Boolean)

  return (
    <div className='bg-white border h-100 rounded'>
      <div className='p-2 border-bottom tw-font-bold tw-text-secondary'>
        {data?.name}
      </div>
      <div className='px-2'>
        {info.map(({ label, color }, index) => {
          return (
            <div key={index} className='pt-1 d-flex gap-12 align-items-center'>
              <div
                style={{ width: 10, height: 10, backgroundColor: color }}
                className='rounded-circle'
              />

              <div>{label}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function HeadCountMap() {
  const { data: countries, isLoading } = useFetch({
    action: getHeadcountOnMap,
    autoFetch: true,
  })

  const markers = !countries
    ? []
    : Object.values(countries?.countries)?.map((country) => {
        return {
          coordinates: [Number(country?.longitude), Number(country?.latitude)],
          markerData: country,
        }
      })

  const loading = !countries || isLoading
  const isEmpty = markers.length === 0
  return (
    <ChartCard className='tw-h-[480px]'>
      <ChartHeading
        title='Headcount Map'
        subtitle={
          loading ? (
            <Shimmer width='100%' height={19} />
          ) : (
            `You have ${countries?.teammates_count} teammates across ${countries?.countries_count} countries`
          )
        }
      />

      <div
        style={{ height: 'calc(365px - 1rem)' }}
        className='d-flex justify-content-center align-items-center p-4 overflow-hidden'
      >
        <>
          {!isEmpty ? null : (
            <Dimmer>
              <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
                Your head count map data will show here
              </p>
            </Dimmer>
          )}
          <MapChart
            markers={markers}
            options={{ style: { width: '100%' } }}
            geoOptions={{ tabIndex: -1 }}
            renderMarkerTooltip={renderMarkerTooltip}
          />
        </>
      </div>
    </ChartCard>
  )
}

function formatDataToSeries(data, keys) {
  const series = keys.map((key) => {
    const { key: currentKey, label, format } = key

    const seriesData = data.map((item) => {
      if (format) return format(item[currentKey], item)
      return item[currentKey] ?? 0
    })

    return { name: label, data: seriesData }
  })

  return series
}

const headcountKeys = {
  contractors: 'contractors_count',
  eor: 'eor_count',
}

function HeadCountPerMonth() {
  const dataBars = [
    {
      label: 'Contractors',
      key: headcountKeys.contractors,
      fill: chartsColors.gray,
    },
    { label: 'EOR', key: headcountKeys.eor, fill: chartsColors.orange },
  ]

  const { data: headcountResp, isLoading } = useFetch({
    action: getHeadcountPerMonthChart,
    autoFetch: true,
  })

  const headcountData = headcountResp?.months ?? {}
  const headcountDataValues = Object.values(headcountData)

  const series = formatDataToSeries(
    !headcountResp ? [] : headcountDataValues,
    dataBars,
  )
  const xAxisCategories = Object.keys(headcountData)
  const labels = headcountDataValues.map((item) => item.label)

  const loading = isLoading || !headcountResp
  const isEmpty =
    headcountResp?.contractors_count === 0 && headcountResp?.eor_count === 0
  return (
    <ChartCard className='tw-h-[480px]'>
      <ChartHeading
        title='Headcount Per Month'
        subtitle={
          loading ? (
            <Shimmer width='100%' height={19} />
          ) : (
            `You have ${headcountResp?.contractors_count} contractors and ${headcountResp?.eor_count} FTEs`
          )
        }
      />

      <div className='px-3'>
        {loading ? (
          <Shimmer width='100%' height={355} />
        ) : (
          <div>
            {!isEmpty ? null : (
              <Dimmer>
                <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
                  Your head count per month data will show here
                </p>
              </Dimmer>
            )}

            <Bar
              className='apexcharts-with-hover'
              series={series}
              xAxisCategories={xAxisCategories}
              labels={labels}
              yAxisFormatter={(val) => val}
              formatTooltipValue={(args) => {
                const { series, seriesIndex, dataPointIndex } = args
                const val =
                  series[seriesIndex][dataPointIndex] +
                  ' ' +
                  dataBars[seriesIndex].label

                return val
              }}
              fillColors={dataBars.map((item) => item.fill)}
            />
          </div>
        )}
      </div>
    </ChartCard>
  )
}

export function HeadCountPerCountry() {
  const {
    data: countriesResp,
    isLoading,
    error,
  } = useFetch({
    action: getHeadcountPerCountry,
    autoFetch: true,
  })

  const loading = isLoading || (!countriesResp && !error)
  const countriesList = Object.values(countriesResp?.countries ?? {})

  const teammatesCount = countriesList
    ? countriesList?.reduce((prev, curr) => prev + curr.workers_count, 0)
    : 0
  const countriesCount = countriesList?.length ?? 0

  return (
    <ChartCard className='tw-h-[480px] tw-overflow-y-auto'>
      <ChartHeading
        title='Headcount Per Country'
        subtitle={
          loading ? (
            <Shimmer width='100%' height={19} />
          ) : (
            `You have ${teammatesCount} teammates across ${countriesCount} countries`
          )
        }
      />

      <div className='px-4 pb-3'>
        {loading ? (
          <div style={{ '--s-height': '44px' }}>
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          </div>
        ) : (
          <div>
            {countriesList.length > 0 ? null : (
              <Dimmer>
                <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
                  Your head count per country data will show here
                </p>
              </Dimmer>
            )}
            {countriesList?.map((country, index) => {
              return (
                <CountryItem
                  key={country?.iso3}
                  isLast={index === countriesList.length - 1}
                  country={country}
                />
              )
            })}
          </div>
        )}
      </div>
    </ChartCard>
  )
}

function CountryItem({ isLast, country }) {
  return (
    <div
      className={cx(
        'd-flex justify-content-between align-items-center border-bottom py-2.5',
        { 'border-transparent': isLast, 'border-light': !isLast },
      )}
    >
      <div className='d-flex align-items-center gap-12'>
        <Flag url={getFlagUrlFromIso2(country.iso2)} size='30px' />
        <span>{country.name}</span>
      </div>

      <div className='d-flex justify-content-end align-items-center gap-12'>
        <div className='d-flex gap-6 tw-font-bold'>
          <span>{country.workers_count}</span>
          <span className='text-gray-600'>({country.percentage}%)</span>
        </div>
        <div className='progress' style={{ height: 5, width: 50 }}>
          <div
            className='progress-bar bg-primary'
            role='progressbar'
            style={{ width: `${country.percentage}%` }}
          />
        </div>
      </div>
    </div>
  )
}

function RetentionSection() {
  const { data, isLoading } = useFetch({
    action: getRetentionChart,
    autoFetch: true,
  })

  return (
    <ChartCard className='tw-h-[480px]'>
      <ChartHeading title='Retention' subtitle='New joiners & leavers' />

      <div
        className={cx('px-3', {
          'blur-sm pointer-events-none': data?.is_empty,
        })}
      >
        {isLoading || !data ? (
          <Shimmer width='100%' height={355} />
        ) : (
          <RetentionChart data={data} />
        )}
      </div>
    </ChartCard>
  )
}

const dataKeys = {
  joiners: 'joiners_count',
  leavers: 'leavers_count',
  total: 'total_count',
}

function RetentionChart({ data }) {
  const dataBars = [
    { label: 'joiner(s)', key: dataKeys.joiners, fill: chartsColors.blue },
    {
      label: 'total team member(s)',
      key: dataKeys.total,
      format: (v, rowData) => v - rowData.joiners_count,
      fill: chartsColors.gray,
    },
    {
      label: 'leaver(s)',
      key: dataKeys.leavers,
      format: (v) => v * -1,
      fill: chartsColors.orange,
    },
  ]

  const retentionMonthsData = Object.values(data?.months)

  const series = formatDataToSeries(!data ? [] : retentionMonthsData, dataBars)
  const labels = retentionMonthsData.map((item) => item.label)

  const isEmpty = series.map((p) => p.data.every(zero)).every(isTrue)
  return (
    <>
      {!isEmpty ? null : (
        <Dimmer>
          <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
            Your retention data will show here
          </p>
        </Dimmer>
      )}
      <Bar
        series={series}
        multipleTooltip={true}
        xAxisCategories={Object.keys(data?.months)}
        labels={labels}
        fillColors={dataBars.map((item) => item.fill)}
        options={{ stacked: true }}
        formatTooltipValue={(args, index) => {
          const { series, dataPointIndex } = args

          const vKey = dataBars[index]?.key
          const joinersCountIndex = dataBars.findIndex(
            (item) => item.key === dataKeys.joiners,
          )
          const totalCountReminder =
            vKey === dataKeys.total
              ? series[joinersCountIndex][dataPointIndex]
              : 0

          const vLabel = dataBars[index]?.label
          const vValue = series[index][dataPointIndex] + totalCountReminder
          const tooltipValue = Math.abs(vValue) + ' ' + vLabel

          return tooltipValue
        }}
      />
    </>
  )
}

const costDataKeys = {
  contractors: 'contractors_average',
  contractors_count: 'contractors_count',
  eor: 'eor_average',
  eor_count: 'eor_count',
  total: 'total_average',
}

function AverageCostChart() {
  const dataBars = [
    {
      label: 'Contractors',
      key: costDataKeys.total,
      fill: chartsColors.gray,
    },
  ]
  const dataBars2 = [
    {
      label: 'Contractors Average',
      key: costDataKeys.contractors,
      fill: chartsColors.blue,
    },
    {
      label: 'Contractors count',
      key: costDataKeys.contractors_count,
      fill: chartsColors.orange,
    },
    { label: 'EOR Average', key: costDataKeys.eor, fill: chartsColors.gray },
    {
      label: 'EOR Count',
      key: costDataKeys.eor_count,
      fill: chartsColors.orange,
    },
  ]
  const { data: costResp, isLoading } = useFetch({
    action: getAverageContractCostPerMonthChart,
    autoFetch: true,
  })

  const costData = costResp?.months ?? {}
  const costDataValues = Object.values(costData)

  const formatter = getCurrencyFormatter()

  const series = formatDataToSeries(!costResp ? [] : costDataValues, dataBars)
  const series2 = formatDataToSeries(!costResp ? [] : costDataValues, dataBars2)
  const xAxisCategories = Object.keys(costData)
  const labels = costDataValues.map((item) => item.label)
  const isEmpty = series.map((p) => p.data.every(zero)).every(isTrue)

  return (
    <ChartCard className='tw-h-[480px]'>
      <ChartHeading
        title='Average Cost Per Contract'
        subtitle='Average cost per contract type'
      />

      <div className='px-3'>
        {isLoading || !costResp ? (
          <Shimmer width='100%' height={355} />
        ) : (
          <>
            {!isEmpty ? null : (
              <Dimmer>
                <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
                  Your average cost data will show here
                </p>
              </Dimmer>
            )}
            <Bar
              className='apexcharts-with-hover'
              series={series}
              xAxisCategories={xAxisCategories}
              labels={labels}
              yAxisFormatter={(val) => '$' + kFormatter(val)}
              formatTooltipValue={getCurrencyTooltipFormatter(formatter.format)}
              fillColors={dataBars.map((item) => item.fill)}
              customTooltip={(args) => {
                const { dataPointIndex } = args

                const cAverage = series2[0]?.data[dataPointIndex]
                const cCount = series2[1]?.data[dataPointIndex]
                const eorAverage = series2[2]?.data[dataPointIndex]
                const eorCount = series2[3]?.data[dataPointIndex]

                const info = [
                  `Contractors: ${cCount} (avg. ${formatter.format(
                    Math.round(cAverage),
                  )})`,
                ]

                if (parseInt(eorCount)) {
                  info.push(
                    `EOR: ${eorCount} (avg. ${formatter.format(
                      Math.round(eorAverage),
                    )})`,
                  )
                }

                return (
                  '<div>' +
                  '<span style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px; font-weight:700; border-bottom: 1px solid #E7E8F2;">' +
                  labels[dataPointIndex] +
                  '</span>' +
                  info
                    .map((elm, index) => {
                      return (
                        '<div style="font-size: 12px; color: #0F1035; padding: 4px 16px;"><span style="width: 10px; height: 10px; border-radius: 50%; background-color:' +
                        dataBars2.map((item) => item.fill)[index] +
                        '; display: inline-block; margin-right: 8px;" class="hover:bg-light"></span>' +
                        elm +
                        '</div>'
                      )
                    })
                    .join('') +
                  '</div>'
                )
              }}
            />
          </>
        )}
      </div>
    </ChartCard>
  )
}

function TimeoffSection() {
  const { data: timeOffData, isLoading } = useFetch({
    action: getTimeOffPerMonthReport,
    autoFetch: true,
    body: {
      limit: 12,
    },
  })

  const data = timeOffData?.list?.reduce((acc, curr) => {
    const obj = { month: curr.month, [curr.status]: parseFloat(curr.total) }
    return { ...acc, [curr.month]: { ...acc?.[curr.month], ...obj } }
  }, {})

  return (
    <ChartCard className='tw-h-[480px]'>
      <ChartHeading
        title='Time off per month'
        subtitle='Your total requested and approved time off'
      />

      <div
        className={cx('px-3', {
          'tw-pointer-events-none tw-flex tw-h-[355px] tw-items-center tw-justify-center':
            timeOffData?.list?.length === 0,
        })}
      >
        {isLoading || !timeOffData ? (
          <Shimmer width='100%' height={355} />
        ) : (
          <TimeoffChart data={Object.values(data)} />
        )}
      </div>
    </ChartCard>
  )
}

const timeoffKeys = {
  month: 'month',
  Approved: 'Approved',
  'Pending approval': 'Pending approval',
}

function TimeoffChart({ data }) {
  const dataBars = [
    {
      label: 'Approved days',
      key: timeoffKeys.Approved,
      fill: chartsColors.orange,
    },
    {
      label: 'Requested days',
      key: timeoffKeys['Pending approval'],
      fill: chartsColors.blue,
    },
  ]

  const series = formatDataToSeries(!data ? [] : data, dataBars)
  const monthLabels = data?.map((item) => item.month)
  const isEmpty = data?.length === 0

  return (
    <>
      {isEmpty ? (
        <p className='mb-0 text-slate-500 tw-text-center tw-text-xl'>
          Your time off per month data will show here
        </p>
      ) : (
        <Bar
          series={series}
          multipleTooltip={true}
          xAxisCategories={monthLabels}
          labels={monthLabels}
          fillColors={dataBars.map((item) => item.fill)}
          options={{ stacked: true }}
          formatTooltipValue={(args, index) => {
            const { series, dataPointIndex } = args

            const vLabel = dataBars[index]?.label
            const vValue = series[index][dataPointIndex]
            const tooltipValue = vValue + ' ' + vLabel

            return tooltipValue
          }}
        />
      )}
    </>
  )
}

function Price({ children, className = 'mb-0' }) {
  return (
    <h3
      className={cx('font-size-24 text-current tw-font-bold', className)}
      style={{ letterSpacing: 1 }}
    >
      {children}
    </h3>
  )
}

function InfoBlock({ amount, label, growth, link, className }) {
  const badgeStatus = growth >= 0 ? 'success' : 'danger'

  return (
    <div className={cx('tw-text-secondary', className)}>
      <div className='mb-1'>{label}</div>

      <div className='d-flex gap-12 align-items-baseline'>
        <Price>{amount}</Price>
        {isNill(growth) ? null : (
          <BadgeX status={badgeStatus}>{growth}%</BadgeX>
        )}
      </div>

      {!link ? null : link}
    </div>
  )
}

export function Dimmer({ children, className }) {
  return (
    <div
      className={cx(
        'w-100 h-100 position-absolute d-flex align-items-center justify-content-center left-0 top-0',
        className,
      )}
      style={{
        zIndex: 999,
        backgroundColor: 'rgba(255,255,255,0.43)',
        backdropFilter: 'blur(4px)',
      }}
    >
      {children}
    </div>
  )
}

const chartsColors = { blue: '#114EF7', gray: '#E8E9EB', orange: '#F79B11' }
